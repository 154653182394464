import { Expose, Transform, TransformFnParams } from 'class-transformer';

const transformCurrency = (model: TransformFnParams) => {
  const numberValue = parseInt(model.value);

  return isNaN(numberValue) ? undefined : numberValue / 100;
};

export class CurrencyV2Model {
  @Expose()
  @Transform(transformCurrency)
  grossAmount: number;
  @Expose()
  @Transform(transformCurrency)
  netAmount: number;
  @Expose()
  iso4217: string;
}
