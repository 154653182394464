import { Expose } from 'class-transformer';

import { IHasId } from '../../tools/reducer-helper/model/i-has-id';
import { Identifier } from '../../tools/reducer-helper/model/identifier';

export class IdNameModel implements IHasId {
  @Expose()
  id: Identifier;

  @Expose()
  name: string;
}
