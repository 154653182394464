import { makeEnvironmentProviders } from '@angular/core';

import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { SongService } from '../services/song.service';
import { SongServiceAbstract } from '../services/song.service.abstract';
import { SongLiveChangeEffects } from '../store/effect/song-livechange.effects';
import { SongEffects } from '../store/effect/song.effects';
import { songReducer } from '../store/reducer/song.reducer';

export const provideSongState = () =>
  makeEnvironmentProviders([
    provideState('song', songReducer),
    provideEffects([SongEffects, SongLiveChangeEffects]),
    { provide: SongServiceAbstract, useClass: SongService },
  ]);
