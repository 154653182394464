import {
  EnvironmentProviders,
  makeEnvironmentProviders,
  NgModule,
} from '@angular/core';

import { EffectsModule, provideEffects } from '@ngrx/effects';
import { provideState, StoreModule } from '@ngrx/store';

import { TeamService } from '../providers/team.service';
import { TeamServiceAbstract } from '../providers/team.service.abstract';
import { TeamEffects } from '../store/team.effects';
import { teamReducer } from '../store/team.reducer';

@NgModule({
  imports: [
    EffectsModule.forFeature([TeamEffects]),
    StoreModule.forFeature('team', teamReducer),
  ],
  providers: [{ provide: TeamServiceAbstract, useClass: TeamService }],
})
export class TeamStateModule {}

export const provideTeamState = (): EnvironmentProviders =>
  makeEnvironmentProviders([
    provideState('team', teamReducer),
    provideEffects([TeamEffects]),
    { provide: TeamServiceAbstract, useClass: TeamService },
  ]);
