import { AbstractControl, FormArray, FormGroup } from '@angular/forms';

import { from, of } from 'rxjs';
import { concatMap, delay, mergeMap } from 'rxjs/operators';

import { ResponseContainer } from '../../../common/models/ResponseContainer';
import { ResponseErrorInput } from '../../../common/models/ResponseErrorInput';
import { ResponseStatus } from '../../../common/models/ResponseStatus';

export abstract class FormValidatorServiceAbstract {
  abstract showToast(message: string): void;
  abstract translate(key: string, opts?: unknown): string;

  private get baseError(): ResponseContainer<null> {
    const resp = new ResponseContainer(null);
    resp.status = new ResponseStatus();
    resp.status.success = false;
    resp.status.inputs = [];
    return resp;
  }

  async validate<T, U>(
    form?: FormGroup | FormArray,
    call?: (model: U, id?: number) => Promise<ResponseContainer<T>>,
    model?: U,
    id?: number
  ): Promise<ResponseContainer<T>> {
    this.recursiveError(form?.value, form);

    if ((form && !form?.valid) || !call) {
      this.showToast(this.translate('GLOBAL_ERROR_FORM_ERROR'));
      return this.baseError;
    }

    const response = await call(model, id);

    if (!response) {
      return response;
    } else if (response?.status?.success) {
      return response;
    } else {
      const globalErrors: ResponseErrorInput[] = [];
      const inputErrors = response?.status?.inputs ?? [];

      for (const item of inputErrors) {
        const control = this.findControl(form, item?.name);
        if (!control) {
          globalErrors.push(item);
        } else {
          control?.setErrors({ invalid: item?.message }, { emitEvent: false });
          control?.markAsDirty();
          control?.markAsTouched();
        }
      }
      if (response?.status?.globalMessage) {
        globalErrors.push({
          name: '',
          message: response.status.globalMessage,
        });
      } else {
        globalErrors.push({
          name: '',
          message: this.translate('GLOBAL_ERROR_FORM_ERROR'),
        });
      }
      if (globalErrors.length > 0) {
        this.showToast(globalErrors.shift().message);
        if (globalErrors.length > 0) {
          of(globalErrors)
            .pipe(
              mergeMap((x) => from(x)),
              concatMap((x) => of(x).pipe(delay(3500)))
            )
            .subscribe((x) => this.showToast(x.message));
        }
      }
      return response;
    }
  }

  private findControl(fg: FormGroup | FormArray, name: string): AbstractControl {
    const s = name.split('.');
    if (s.length > 1) {
      return this.findControl(fg.controls[s.shift()], s.join('.'));
    } else {
      return fg?.controls?.[s[0]] as AbstractControl;
    }
  }

  private recursiveError(obj: AbstractControl, control: AbstractControl): void {
    if (control) {
      if (typeof obj === 'object' && obj !== null && !Array.isArray(obj)) {
        Object.keys(obj).forEach((val) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          this.recursiveError(obj[val], (control as FormGroup).controls[val]);
        });
      } else if (control instanceof FormArray) {
        control.controls.forEach((_, index) => this.recursiveError(obj[index], control.controls[index]));
      }
      control?.setErrors(null, { emitEvent: false });
      control?.updateValueAndValidity({ emitEvent: false });
      control?.markAsDirty();
      control?.markAsTouched();
    }
  }
}
/* eslint-enable @typescript-eslint/member-ordering */
