import { CampaignV2DetailsModel } from '../../campaign-v2/models/campaign-v2-details.model';
import { SongDetailsModel } from '../../song/models/song-details.model';
import { AnalyticsCampaignInfo } from '../model/analytics-campaign-info';

export type CreateAnalyticsCampaignInfoParams = {
  campaign: CampaignV2DetailsModel;
  song: SongDetailsModel;
  maxCount: number;
  position: number;
};

export const createAnalyticsCampaignInfo = (
  model: CreateAnalyticsCampaignInfoParams
): AnalyticsCampaignInfo => {
  return {
    campaignBriefingMandatory: model.campaign.briefing.mandatory,
    campaignGrossAmount: model.campaign.price.grossAmount,
    campaignId: model.campaign.id,
    campaignName: model.campaign.name,
    campaignTargetMinViewers: model.campaign.targetMinViewers,
    songArtistId: model.song.userId,
    songId: model.song.id,
    songArtistName: model.song.artists.join(' '),
    songTitle: model.song.title,
    songName: model.song.name,
    songDuration: 0, /// TODO
    songExplicitLyrics: model.song.explicitLyrics,
    maxCount: model.maxCount,
    position: model.position,
    platform: model.campaign.platform,
  };
};
