import { PagerEntity } from '../../../common/models/PagerEntity';
import { createPagerReducer } from '../../../tools/reducer-helper/create-pager-reducer.factory';
import { IHasPagerEntities } from '../../../tools/reducer-helper/model/has-pager-entities';
import { SocialBlockDetailsModel } from '../../models/social-block-details.model';
import { SocialBlockSearchModel } from '../../models/social-block-search.model';
import { socialBlockAction } from '../action/social-block.actions';

export type SocialBlockState = IHasPagerEntities<
  SocialBlockDetailsModel,
  SocialBlockSearchModel
>;
const initialPager = new PagerEntity<
  SocialBlockDetailsModel,
  SocialBlockSearchModel
>({
  id: 'default',
  pages: {},
  selectedPageNum: 1,
  maxCount: null,
  limit: 50,
  asc: false,
  orderBy: 'id',
  search: new SocialBlockSearchModel({}),
});

const socialBlockInitialState: SocialBlockState = {
  entities: {},
  ids: [],
  initialPager: initialPager,
  pagers: {},
  pagerIds: [],
  selectedId: null,
};

export const socialBlockReducer = createPagerReducer({
  details: SocialBlockDetailsModel,
  filter: SocialBlockSearchModel,
  initialState: socialBlockInitialState,
  actions: socialBlockAction,
  source: 'SocialBlock',
});
