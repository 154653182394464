import { makeEnvironmentProviders } from '@angular/core';

import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { ManagerDealService } from '../providers/manager-deal.service';
import { ManagerDealServiceAbstract } from '../providers/manager-deal.service.abstract';
import { ManagerDealLiveChangeEffects } from '../store/manager-deal-livechange.effects';
import { ManagerDealEffects } from '../store/manager-deal.effects';
import { managerDealReducer } from '../store/manager-deal.reducer';

export const provideManagerDealState = () =>
  makeEnvironmentProviders([
    provideState('managerDeal', managerDealReducer),
    provideEffects([ManagerDealEffects, ManagerDealLiveChangeEffects]),
    { provide: ManagerDealServiceAbstract, useClass: ManagerDealService },
  ]);
