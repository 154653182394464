import { Injectable, inject } from '@angular/core';

import { delayWhen, filter, switchMap, take, tap } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';

import { Storage } from '@ionic/storage';
import { createEffect, ofType } from '@ngrx/effects';
import { instanceToPlain, plainToInstance } from 'class-transformer';

import { PagerEntity } from '../../../common/models/PagerEntity';
import { SettingsActions } from '../../../settings/store/actions';
import { SettingsSelector } from '../../../settings/store/selectors';
import { storageAction } from '../../../storage/state';
import { filterEmpty } from '../../../tools/map/filter-empty';
import { PagerEffects } from '../../../tools/reducer-helper/pager-effects';
import { MoodModel } from '../../models/mood.model';
import { MoodServiceAbstract } from '../../services/mood.service.abstract';
import { moodAction } from '../action/mood.actions';
import { moodSelector } from '../selector/mood.selector';

@Injectable()
export class MoodEffects extends PagerEffects<
  'Mood',
  MoodModel,
  null,
  null,
  null
> {
  private readonly MOOD_KEY = 'moods';
  storage = inject(Storage);

  loadFromDb$ = createEffect(() =>
    this.actions$.pipe(
      ofType(storageAction.ready),
      switchMap(() => this.storage.get(this.MOOD_KEY)),
      filterEmpty(),
      map((plainState) =>
        plainState ? plainToInstance(MoodModel, plainState as any[]) : null
      ),
      map((moods) =>
        moods
          ? moodAction.loadAllCompleted({
              payload: moods,
              pagerId: 'default',
              pager: new PagerEntity({ maxCount: moods.length }),
            })
          : moodAction.loadAll({ pagerId: 'default' })
      )
    )
  );

  languageChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.languageChanged),
      delayWhen(() =>
        this.store.select(SettingsSelector.getLoaded).pipe(
          filter((loaded) => !!loaded),
          take(1)
        )
      ),
      map(() =>
        moodAction.loadAll({
          pagerId: 'default',
        })
      )
    )
  );

  saveToDb$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(moodAction.loadAllCompleted),
        tap(({ payload }) =>
          this.storage.set(this.MOOD_KEY, instanceToPlain(payload))
        )
      ),
    { dispatch: false }
  );

  constructor() {
    super(moodAction, moodSelector, inject(MoodServiceAbstract));
  }
}
