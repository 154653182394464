import { createPagerActionGroup } from '../../../tools/reducer-helper/create-pager-action-group.factory';
import { SpotifyTrackDetailsModel } from '../../models/spotify-track-details.model';
import { SpotifyTrackSearchModel } from '../../models/spotify-track-search.model';

export const spotifyTrackAction = createPagerActionGroup<
  'SpotifyTrack',
  SpotifyTrackDetailsModel,
  SpotifyTrackSearchModel
>({
  source: 'SpotifyTrack',
});
