import { Directive, Injectable, TemplateRef } from '@angular/core';

import { ReplaySubject, defer, merge, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class PortalService {
  change = new ReplaySubject<{ key: string; template: TemplateRef<any> }>();

  portalContent: {
    [key: string]: Directive[];
  } = {};

  add(key: string, dir: Directive) {
    const oldItems = this.portalContent[key] ?? [];
    if (!oldItems.includes(dir)) {
      this.portalContent = {
        ...this.portalContent,
        [key]: [...oldItems, dir],
      };
    }
    if ('templateRef' in dir) {
      this.change.next({ key, template: dir.templateRef as TemplateRef<any> });
    }
  }

  remove(key: string, dir: Directive) {
    const oldItems = this.portalContent[key] || [];
    const newItems = oldItems.filter((item) => item !== dir);
    this.portalContent = {
      ...this.portalContent,
      [key]: newItems,
    };

    const lastItem = newItems[newItems.length - 1] as { templateRef?: TemplateRef<any> };

    this.change.next({ key, template: lastItem?.templateRef as TemplateRef<any> });
  }

  getTemplate(key: string) {
    return merge(
      defer(() => {
        const directives = this.portalContent[key];
        const dir = directives?.[directives.length - 1];
        if (dir && 'templateRef' in dir) {
          return of(dir.templateRef);
        }
        return of(undefined);
      }),
      this.change.pipe(
        filter((change) => change.key === key),
        map((change) => change.template)
      )
    );
  }
}
