import { makeEnvironmentProviders } from '@angular/core';

import { provideEffects } from '@ngrx/effects';

import { provideAnalyticsState } from '@songpush/core/analytics/module';
import { provideBankAccountState } from '@songpush/core/bank-account/module';
import { provideCampaignV2State } from '@songpush/core/campaign-v2/module';
import { provideCityState } from '@songpush/core/city/module';
import { provideCompanyState } from '@songpush/core/company/module';
import { provideContentCategoryState } from '@songpush/core/content-category/module';
import { provideCountryState } from '@songpush/core/country/module';
import { provideDealLimitState } from '@songpush/core/deal-limit/module';
import { provideDealState } from '@songpush/core/deal/module';
import { FormValidatorServiceAbstract } from '@songpush/core/form/services/form-validator';
import { provideGenreState } from '@songpush/core/genre/module';
import { provideInstagramState } from '@songpush/core/instagram/module';
import { provideInviteState } from '@songpush/core/invite/module';
import { provideInvoiceState } from '@songpush/core/invoice/module';
import { provideLanguageState } from '@songpush/core/language/module';
import { provideManagerDealState } from '@songpush/core/manager-deal/module';
import { provideManagerInvitationState } from '@songpush/core/manager-invitation/module';
import {
  MangopayService,
  MangopayServiceAbstract,
} from '@songpush/core/mangopay/services';
import { provideMoodState } from '@songpush/core/mood/module';
import { provideSessionState } from '@songpush/core/session/module';
import { provideSettingsNotificationGroupState } from '@songpush/core/settings-notification-group/module';
import {
  SettingsService,
  SettingsServiceAbstract,
} from '@songpush/core/settings/services';
import { SettingsEffects } from '@songpush/core/settings/store';
import { provideSocialBlockState } from '@songpush/core/social-block/module';
import { provideSongState } from '@songpush/core/song/module';
import { provideSpotifyArtistState } from '@songpush/core/spotify-artist/module';
import { provideSpotifyTrackState } from '@songpush/core/spotify-track/module';
import { provideStatState } from '@songpush/core/stat/module';
import { provideStorageState } from '@songpush/core/storage/module';
import { provideSubGenreState } from '@songpush/core/sub-genre/module';
import { provideSystemMessageState } from '@songpush/core/system-message/module';
import { provideTeamState } from '@songpush/core/team/module';
import { provideTicketState } from '@songpush/core/ticket/module';

import {
  TiktokService,
  TiktokServiceAbstract,
} from '@songpush/core/tiktok/services';

import { provideTransactionState } from '@songpush/core/transaction/module';
import { provideUserState } from '@songpush/core/user/module';
import { provideWalletState } from '@songpush/core/wallet/module';

import { FormValidatorService } from '@songpush/shared/common/services/form-validator';
import { provideEmailState } from '@songpush/shared/email/module';
import { provideFlowState } from '@songpush/shared/flow/module';

import { provideCommonState } from './common/common.state.module';
import { provideWebsocketState } from './websocket/websocket.state.module';

export const provideAppState = () =>
  makeEnvironmentProviders([
    provideSessionState(),
    provideCommonState(),
    provideEmailState(),
    provideLanguageState(),
    provideWebsocketState(),
    provideInstagramState(),
    provideAnalyticsState(),
    provideInviteState(),
    provideCountryState(),
    provideBankAccountState(),
    provideCampaignV2State(),
    provideDealLimitState(),
    provideTeamState(),
    provideFlowState(),
    provideWalletState(),
    provideTransactionState(),
    provideSystemMessageState(),
    provideManagerInvitationState(),
    provideSettingsNotificationGroupState(),
    provideManagerDealState(),
    provideStatState(),
    provideSubGenreState(),
    provideMoodState(),
    provideContentCategoryState(),
    provideInvoiceState(),
    provideStorageState(),
    provideDealState(),
    provideTicketState(),
    provideUserState(),
    provideSpotifyArtistState(),
    provideSpotifyTrackState(),
    provideSongState(),
    provideCompanyState(),
    provideSocialBlockState(),
    provideCityState(),
    provideGenreState(),
    provideEffects([SettingsEffects]),
    { provide: FormValidatorServiceAbstract, useClass: FormValidatorService },
    { provide: MangopayServiceAbstract, useClass: MangopayService },
    { provide: TiktokServiceAbstract, useClass: TiktokService },
    { provide: SettingsServiceAbstract, useClass: SettingsService },
  ]);
