import { inject } from '@angular/core';
import { Router } from '@angular/router';

import { map } from 'rxjs';

import { createEffect, ofType } from '@ngrx/effects';

import { CountrySearchModel } from '../../country/models';
import { hasCountryInStore } from '../../country/services/guards/country-exist.guard';
import { countryAction } from '../../country/store/action';
import { SessionActions } from '../../session/store/actions/session.actions';
import { PagerEffects } from '../../tools/reducer-helper/pager-effects';
import { BankAccountCreateModel } from '../models/bank-account-create.model';
import { BankAccountDetailsModel } from '../models/bank-account-details.model';
import { BankAccountEditModel } from '../models/bank-account-edit.model';
import { BankAccountSearchModel } from '../models/bank-account-search.model';
import { BankAccountServiceAbstract } from '../providers/bank-account.service.abstract';
import { bankAccountAction } from './bank-account.action';
import { bankAccountSelector } from './bank-account.selector';

export class BankAccountEffects extends PagerEffects<
  'BankAccount',
  BankAccountDetailsModel,
  BankAccountSearchModel,
  BankAccountEditModel,
  BankAccountCreateModel
> {
  router = inject(Router);

  resetLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.logoutCompleted),
      map(() => bankAccountAction.resetState())
    )
  );

  resolveCountry$ = createEffect(
    () =>
      this.resolveEntities(
        ['countryId'],
        countryAction,
        CountrySearchModel,
        (id) =>
          hasCountryInStore(id, this.store).pipe(
            map((exist) => ({ exist, id }))
          )
      ),
    { dispatch: false }
  );

  constructor() {
    super(
      bankAccountAction,
      bankAccountSelector,
      inject(BankAccountServiceAbstract)
    );
  }
}
