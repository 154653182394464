import { makeEnvironmentProviders } from '@angular/core';

import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { WalletService } from '../providers/wallet.service';
import { WalletServiceAbstract } from '../providers/wallet.service.abstract';
import { WalletLiveChangeEffects } from '../store/wallet-livechange.effects';
import { WalletEffects } from '../store/wallet.effects';
import { walletReducer } from '../store/wallet.reducer';

export const provideWalletState = () =>
  makeEnvironmentProviders([
    provideState('wallet', walletReducer),
    provideEffects([WalletEffects, WalletLiveChangeEffects]),
    { provide: WalletServiceAbstract, useClass: WalletService },
  ]);
