import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { InstagramUserModel } from '../model/instagram-user.model';
import { InstagramUserSearchModel } from '../model/instagram-user-search.model';
import { PagerResponseContainer } from '../../common/models/PagerResponseContainer';
import { ResponseContainer } from '../../common/models/ResponseContainer';
import { InstagramSongMetadataModel } from '../model/instagram-song-metadata.model';

@Injectable()
export abstract class InstagramServiceAbstract {
  abstract getPage(page: InstagramUserSearchModel): Observable<PagerResponseContainer<InstagramUserModel>>;
  abstract getSongMetadata(songUrl: string): Observable<ResponseContainer<InstagramSongMetadataModel>>;
}
