import { PagerServiceAbstract } from '../../tools/reducer-helper/pager-service';
import { WalletCreateModel } from '../models/wallet-create.model';
import { WalletDetailsModel } from '../models/wallet-details.model';
import { WalletEditModel } from '../models/wallet-edit.model';
import { WalletSearchModel } from '../models/wallet-search.model';

export abstract class WalletServiceAbstract extends PagerServiceAbstract<
  WalletDetailsModel,
  WalletSearchModel,
  WalletCreateModel,
  WalletEditModel
> {}
