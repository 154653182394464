import { createSelector } from '@ngrx/store';

import { createPagerSelectors } from '../../../tools/reducer-helper/create-pager-selector.factory';
import { CompanyDetailsModel } from '../../models/company-details.model';
import { CompanySearchModel } from '../../models/company-search.model';
import { CompanyState } from '../reducer/company.reducer';

const createcompanySelector = () => {
  const pagerSelector = createPagerSelectors<
    CompanyDetailsModel,
    CompanySearchModel,
    CompanyState
  >('company');

  const select = createSelector(pagerSelector.selectAll, (list) =>
    list.find((item) => item?.own)
  );

  const selectType = createSelector(select, (company) => company?.type);

  const selectPayInProcess = createSelector(
    select,
    (company) => company?.campaignPayinProcess
  );

  const selectSepaBudget = createSelector(
    select,
    (company) => company?.campaignSepaAmount
  );

  return {
    ...pagerSelector,
    select,
    selectType,
    selectPayInProcess,
    selectSepaBudget,
  };
};

export const companySelector = createcompanySelector();
