import { Exclude, Expose, Transform } from 'class-transformer';


import { UserRole } from '../../user/models/user-role';
import { checkRoles } from '../../user/tools/role-tools/check-roles';
import { generateRole } from '../../user/tools/role-tools/generate-role';
import { Identifier } from '../../tools/reducer-helper/model/identifier';

const alterRoles = {
  [UserRole.teamAllRead]: UserRole.teamRead,
  [UserRole.teamAllWrite]: UserRole.teamWrite,
};

export class TeamDetailsModel {
  @Expose()
  id: Identifier;
  @Expose()
  name: string;
  @Expose()
  @Transform(({ value }) => BigInt((value || '0') + ''), { toClassOnly: true })
  @Transform((v) => v.value + '', { toPlainOnly: true })
  roles: bigint;

  @Exclude()
  get alterRoles() {
    return Object.keys(alterRoles)
      .map((val) => parseInt(val, 10))
      .reduce(
        (allRole, role) => (checkRoles(this.roles, [role]) ? allRole | generateRole(alterRoles[role]) : allRole),
        this.roles
      );
  }
}
