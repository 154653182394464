import { createActionGroup, props } from '@ngrx/store';

import { FlowProgressItemModel } from '@songpush/core/flow/model';

import { FlowOpts } from '../../models/flow-opts';

export const flowProgressAction = createActionGroup({
  source: 'FlowProgress',
  events: {
    init: props<{
      payload: FlowProgressItemModel[];
      opts: FlowOpts;
    }>(),
    edit: props<{ payload: Partial<FlowProgressItemModel> & { id: number } }>(),
    changeOpts: props<{ payload: Partial<FlowOpts> }>(),
    cleanUp: props<{ ids: number[] }>(),
  },
});
