import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';

import { Observable, defer, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { TransactionServiceAbstract } from '../providers/transaction.service.abstract';
import { transactionAction } from '../store/transaction.action';
import { transactionSelector } from '../store/transaction.selector';
import { interpolateString } from '../../tools/interpolate-string/interpolate-string';


export const transactionExistGuard: CanActivateFn = (route: ActivatedRouteSnapshot): Observable<boolean> => {
  const redirectUrl: string = route.data.redirectUrl || '404';
  const router = inject(Router);

  return hasTransaction(route.params.transactionId, inject(TransactionServiceAbstract), inject(Store)).pipe(
    // navigate if !exist navigate to 404
    tap((exist) => (exist || null) ?? router.navigate(interpolateString(redirectUrl, route.params)))
  );
};

export const hasTransactionInStore = (id: number | string, store = inject(Store)): Observable<boolean> =>
  store.select(transactionSelector.selectItem(id)).pipe(
    map((ent) => !!ent),
    take(1)
  );

export const hasTransactionInApi = (
  id: number,
  transactionService = inject(TransactionServiceAbstract),
  store = inject(Store)
): Observable<boolean> =>
  defer(() => transactionService.get(id)).pipe(
    tap((resp) => {
      if (resp.values) {
        store.dispatch(transactionAction.singleLoadCompleted({ payload: resp.values }));
      }
    }),
    map((resp) => !!resp?.values),
    catchError(() => of(false))
  );

export const hasTransaction = (
  id: number,
  transactionService = inject(TransactionServiceAbstract),
  store = inject(Store)
): Observable<boolean> =>
  hasTransactionInStore(id, store).pipe(
    switchMap((inStore) => {
      if (inStore) {
        return of(inStore);
      }
      return hasTransactionInApi(id, transactionService, store);
    })
  );
