import { makeEnvironmentProviders } from '@angular/core';

import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { TicketService } from '../services/providers/ticket.service';
import { TicketServiceAbstract } from '../services/providers/ticket.service.abstract';
import { TicketEffects } from '../store/effect';
import { TicketLiveChangeEffects } from '../store/effect/ticket-livechange.effects';
import { ticketReducer } from '../store/reducer';

export const provideTicketState = () =>
  makeEnvironmentProviders([
    provideState('ticket', ticketReducer),
    provideEffects([TicketEffects, TicketLiveChangeEffects]),
    { provide: TicketServiceAbstract, useClass: TicketService },
  ]);
