import { Expose } from 'class-transformer';

export class CompanySearchModel {
  @Expose()
  search: string;

  @Expose()
  campaignId?: number | '';

  @Expose()
  slaveScheduleId?: number | '';

  @Expose()
  deleted?: boolean;

  constructor(ent: Partial<CompanySearchModel>) {
    Object.assign(this, ent);
  }
}
