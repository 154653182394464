import { createSelector } from '@ngrx/store';

import { createPagerSelectors } from '../../tools/reducer-helper/create-pager-selector.factory';
import { SettingsNotificationGroupDetailsModel } from '../models/settings-notification-group-details.model';
import { SettingsNotificationGroupSearchModel } from '../models/settings-notification-group-search.model';
import { SettingsNotificationGroupState } from './settings-notification-group.reducer';

const createSettingsNotificationGroupSelector = () => {
  const pagerSelectors = createPagerSelectors<
    SettingsNotificationGroupDetailsModel,
    SettingsNotificationGroupSearchModel,
    SettingsNotificationGroupState
  >('settingsNotificationGroup');

  const selectGroups = createSelector(pagerSelectors.selectAll, (groups) =>
    groups.map((group) => createGroupViewModel(group))
  );

  return {
    ...pagerSelectors,
    selectGroups,
  };
};

const createGroupViewModel = (group: SettingsNotificationGroupDetailsModel) => {
  const isMobileEnabled = group.pushSubscribed;
  const isEmailEnabled = group.mailSubscribed;
  const isAllEnabled = isMobileEnabled && isEmailEnabled;

  return {
    ...group,
    isMobileEnabled,
    isEmailEnabled,
    isAllEnabled,
    enabledOn: isAllEnabled
      ? ('all' as const)
      : isMobileEnabled
      ? ('mobile' as const)
      : isEmailEnabled
      ? ('email' as const)
      : ('none' as const),
  };
};

export const settingsNotificationGroupSelector =
  createSettingsNotificationGroupSelector();
