import { makeEnvironmentProviders } from '@angular/core';

import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { CityService } from '../services/city.service';
import { CityServiceAbstract } from '../services/city.service.abstract';
import { CityEffects } from '../store/effect';
import { cityReducer } from '../store/reducer';

export const provideCityState = () =>
  makeEnvironmentProviders([
    provideState('city', cityReducer),
    provideEffects([CityEffects]),
    { provide: CityServiceAbstract, useClass: CityService },
  ]);
