import { PagerEntity } from '../../../common/models/PagerEntity';
import { Mappings } from '../../mapping/mappings';
import { IHasPagerEntities } from '../model/has-pager-entities';
import { IHasId } from '../model/i-has-id';

export const resetReduce = <T extends IHasId, Z, U extends IHasPagerEntities<T, Z>>(
  state: U,
  initialState: U,
  initialPager: PagerEntity<T, Z>
): U => {
  const pagerIds = Object.keys(state.pagers);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { asc, orderBy, ...paramsToReset } = initialPager;

  if ('loaded' in initialState) {
    return Mappings.assign(initialState, { loaded: true } as unknown as Partial<U>);
  }

  return initialState;
};
