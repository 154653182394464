export const parseBoolean = (val: string | number | boolean) => {
  if (val === true || val === false) {
    return val;
  }
  if (val === 'true' || val === 1) {
    return true;
  }
  if (val === 'false' || val === 0) {
    return false;
  }

  throw new Error(`${val} cannot be parsed to boolean`);
};
