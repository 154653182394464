import { Injectable } from '@angular/core';

import { debounceTime, shareReplay } from 'rxjs';

import { Store } from '@ngrx/store';

import { PagerEntity } from '../../common/models/PagerEntity';
import { keepScopeLoaded } from '../../tools/operators/keep-scope-loaded';
import { DealDetailsModel } from '../models/deal-details.model';
import { DealSearchModel } from '../models/deal-search.model';
import { DealStatus } from '../models/deal-status';
import { dealAction } from '../store/action';
import { dealSelector } from '../store/selector';

@Injectable()
export class DealFacade {
  private statusCreatedTarget: Partial<
    PagerEntity<DealDetailsModel, DealSearchModel>
  > = {
    search: new DealSearchModel({
      status: DealStatus.created,
    }),
  };

  private statusCreatedScopeId = `status-${this.statusCreatedTarget.search.status}`;

  statusCreatedScope$ = this.store
    .select(dealSelector.selectScope(`${this.statusCreatedScopeId}`))
    .pipe(
      debounceTime(0),
      keepScopeLoaded({
        store: this.store,
        changePagerAction: dealAction.changePager({
          payload: {
            ...this.statusCreatedTarget,
            maxCount: null,
          },
          pagerId: this.statusCreatedScopeId,
        }),
        target: this.statusCreatedTarget,
        updateAction: dealAction.loadAll({
          pagerId: this.statusCreatedScopeId,
        }),
      }),
      shareReplay({
        refCount: true,
        bufferSize: 1,
      })
    );

  constructor(private store: Store) {}
}
