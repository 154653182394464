import { Expose } from 'class-transformer';

export class TeamSearchModel {
  @Expose()
  search?: string;

  constructor(ent: Partial<TeamSearchModel>) {
    Object.assign(this, ent);
  }
}
