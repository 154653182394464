import { makeEnvironmentProviders } from '@angular/core';

import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { StatService } from '../providers/stat.service';
import { StatServiceAbstract } from '../providers/stat.service.abstract';
import { StatEffects } from '../store/stat.effects';
import { statReducer } from '../store/stat.reducer';

export const provideStatState = () =>
  makeEnvironmentProviders([
    provideState('stat', statReducer),
    provideEffects([StatEffects]),
    { provide: StatServiceAbstract, useClass: StatService },
  ]);
