import { createPagerActionGroup } from '../../../tools/reducer-helper/create-pager-action-group.factory';
import { InvoiceDetailsModel } from '../../models/invoice-details.model';
import { InvoiceSearchModel } from '../../models/invoice-search.model';

export const invoiceAction = createPagerActionGroup<
  'Invoice',
  InvoiceDetailsModel,
  InvoiceSearchModel
>({
  source: 'Invoice',
});
