import { PagerServiceAbstract } from '../../tools/reducer-helper/pager-service';
import { InvoiceDetailsModel } from '../models/invoice-details.model';
import { InvoiceSearchModel } from '../models/invoice-search.model';

export abstract class InvoiceServiceAbstract extends PagerServiceAbstract<
  InvoiceDetailsModel,
  InvoiceSearchModel,
  null,
  null
> {}
