import { createPagerSelectors } from '../../../tools/reducer-helper/create-pager-selector.factory';
import { CampaignV2DetailsModel } from '../../models/campaign-v2-details.model';
import { CampaignV2SearchModel } from '../../models/campaign-v2-search.model';
import { CampaignV2State } from '../reducer/campaign-v2.reducer';

export const campaignV2Selector = createPagerSelectors<
  CampaignV2DetailsModel,
  CampaignV2SearchModel,
  CampaignV2State
>('campaignV2');
