import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';

import { defer, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { Identifier } from '../../tools/reducer-helper/model/identifier';
import { DealServiceAbstract } from '../services/deal.service.abstract';
import { dealAction } from '../store/action';
import { dealSelector } from '../store/selector/deal.selector';

export const dealExistGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot
): Observable<boolean> => {
  const router = inject(Router);
  return hasDeal(
    route.params.dealId,
    inject(Store),
    inject(DealServiceAbstract)
  ).pipe(
    // navigate if !exist navigate to 404
    tap((exist) => (exist || null) ?? router.navigate(['404']))
  );
};

export const hasDealInStore = (
  id: Identifier,
  store: Store
): Observable<boolean> => {
  return store.select(dealSelector.selectItem(id)).pipe(
    map((ent) => !!ent),
    take(1)
  );
};

export const hasDealInApi = (
  id: Identifier,
  store: Store,
  dealService: DealServiceAbstract
): Observable<boolean> => {
  return defer(() => dealService.get(id)).pipe(
    map((deal) => dealAction.singleLoadCompleted({ payload: deal.values })),
    tap((action) => store.dispatch(action)),
    map((action) => !!action?.payload),
    catchError(() => of(false))
  );
};

export const hasDeal = (
  id: Identifier,
  store: Store,
  dealService: DealServiceAbstract
): Observable<boolean> => {
  return hasDealInStore(id, store).pipe(
    switchMap((inStore) => {
      if (inStore) {
        return of(inStore);
      }
      return hasDealInApi(id, store, dealService);
    })
  );
};
