import { HttpProgressEvent } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ResponseContainer } from '../../common/models/ResponseContainer';
import { ExistModel } from '../../common/models/exist.model';
import { IdNameModel } from '../../common/models/id-name.model';
import { Identifier } from '../../tools/reducer-helper/model/identifier';
import { PagerServiceAbstract } from '../../tools/reducer-helper/pager-service';
import { ManagerEditModel } from '../models/manager-edit.model';
import { UserCreateWithSocialModel } from '../models/user-create-with-social.model';
import { UserDeleteModel } from '../models/user-delete.model';
import { UserDetailsModel } from '../models/user-details.model';
import { UserEditModel } from '../models/user-edit.model';
import { UserForgotPasswordRequestModel } from '../models/user-forgot-password-request.model';
import { UserInitStatusResponse } from '../models/user-init-status-response.model';
import { UserLoginModel } from '../models/user-login-model';
import { UserLoginResponse } from '../models/user-login-response.model';
import { UserPasswordChangeModel } from '../models/user-password-change.model';
import { UserPhotoUploadModel } from '../models/user-photo-upload.model';
import { UserRegistrationModel } from '../models/user-registration.model';
import { UserRelationType } from '../models/user-relation-type';
import { UserSearchModel } from '../models/user-search.model';
import { UserType } from '../models/user-type';
import { UserTypeSetModel } from '../models/user-type-set.model';

export abstract class UserServiceAbstract extends PagerServiceAbstract<
  UserDetailsModel,
  UserSearchModel,
  unknown,
  UserEditModel
> {
  abstract login(
    model: UserLoginModel
  ): Promise<ResponseContainer<UserLoginResponse>>;
  abstract logout(token?: string): Promise<ResponseContainer<unknown>>;
  abstract registration(
    model: UserRegistrationModel
  ): Promise<ResponseContainer<UserLoginResponse>>;
  abstract editUser(
    model: UserEditModel | ManagerEditModel,
    relationType?: UserRelationType
  ): Promise<ResponseContainer<UserDetailsModel>>;
  abstract get(
    id?: Identifier,
    relation?: UserRelationType
  ): Observable<ResponseContainer<UserDetailsModel>>;
  abstract loginInstagram(
    type: UserType
  ): Promise<ResponseContainer<UserDetailsModel>>;
  abstract loginFbInstagram(
    id: Identifier
  ): Observable<ResponseContainer<UserDetailsModel>>;
  abstract loginGoogle(
    type: UserType
  ): Promise<ResponseContainer<UserDetailsModel>>;
  abstract loginTwitch(
    type: UserType
  ): Promise<ResponseContainer<UserDetailsModel>>;
  abstract loginSpotify(
    type: UserType
  ): Promise<ResponseContainer<UserDetailsModel>>;
  abstract loginApple(
    type: UserType
  ): Promise<ResponseContainer<UserDetailsModel>>;
  abstract loginTikTok(
    type: UserType
  ): Promise<ResponseContainer<UserDetailsModel>>;
  abstract getInitStatus(): Promise<ResponseContainer<UserInitStatusResponse>>;
  abstract deleteSocials(): Promise<ResponseContainer<unknown>>;
  abstract deleteUser(id?: Identifier): Promise<ResponseContainer<unknown>>;
  abstract setUserType(
    model: UserTypeSetModel
  ): Promise<ResponseContainer<unknown>>;
  abstract uploadPhoto(
    model: UserPhotoUploadModel,
    relationType?: UserRelationType
  ): Observable<{
    progress: HttpProgressEvent;
    response: ResponseContainer<IdNameModel>;
  }>;
  abstract loginSpotifyArtist(): Promise<ResponseContainer<UserDetailsModel>>;
  abstract changePassword(
    model: UserPasswordChangeModel
  ): Promise<ResponseContainer<unknown>>;
  abstract forgotPasswordRequest(
    model: UserForgotPasswordRequestModel
  ): Promise<ResponseContainer<unknown>>;
  abstract forgotPasswordHashExist(
    hash: string
  ): Promise<ResponseContainer<ExistModel>>;
  abstract forgotPassword(
    token: string,
    model: UserForgotPasswordRequestModel
  ): Promise<ResponseContainer<unknown>>;
  abstract verifyEmail(
    token: string
  ): Promise<ResponseContainer<UserLoginResponse>>;
  abstract resendVerification(): Promise<ResponseContainer<unknown>>;
  abstract requestPending(): Promise<ResponseContainer<UserDetailsModel>>;
  abstract deleteWithPassword(
    model: UserDeleteModel
  ): Promise<ResponseContainer<null>>;
  abstract loginManager(
    userId: Identifier
  ): Observable<ResponseContainer<UserLoginResponse>>;
  abstract createWithSocial(
    model: UserCreateWithSocialModel
  ): Observable<ResponseContainer<UserDetailsModel>>;
}
