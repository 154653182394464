/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Expose } from 'class-transformer';

export class SocialBlockDetailsModel {
  @Expose()
  id: number;
  @Expose()
  name: string;
  @Expose()
  inserted: string;
}
