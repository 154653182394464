import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { UserServiceAbstract } from '../services/user.service.abstract';
import { userAction } from '../store/action';
import { userSelector } from '../store/selector';

export const userExistGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot
) => {
  const router = inject(Router);
  const userId = route.params.userId ?? route.params.id;
  return hasUser(parseInt(userId, 10)).pipe(
    // navigate if !exist navigate to 404
    tap((exist) => {
      return (exist || null) ?? router.navigate(['404']);
    })
  );
};

export const hasUserInStore = (id: number, store = inject(Store)) =>
  store.select(userSelector.selectItem(id)).pipe(
    map((ent) => !!ent),
    take(1)
  );

export const hasUserInApi = (
  id: number,
  userService = inject(UserServiceAbstract),
  store = inject(Store)
) =>
  userService.get(id).pipe(
    map((user) => userAction.singleLoadCompleted({ payload: user.values })),
    tap((action) => store.dispatch(action)),
    map((action) => !!action?.payload),
    catchError(() => of(false))
  );

export const hasUser = (
  id: number,
  userService = inject(UserServiceAbstract),
  store = inject(Store)
): Observable<boolean> =>
  hasUserInStore(id, store).pipe(
    switchMap((inStore) => {
      if (inStore) {
        return of(inStore);
      }
      return hasUserInApi(id, userService, store);
    })
  );
