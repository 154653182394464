import { createSelector } from '@ngrx/store';

import { createPagerSelectors } from '../../tools/reducer-helper/create-pager-selector.factory';
import { BankAccountDetailsModel } from '../models/bank-account-details.model';
import { BankAccountMandateStatus } from '../models/bank-account-mandate.status';
import { BankAccountSearchModel } from '../models/bank-account-search.model';
import { BankAccountState } from './bank-account.reducer';

export const createBankAccountSelector = () => {
  const pagerSelector = createPagerSelectors<
    BankAccountDetailsModel,
    BankAccountSearchModel,
    BankAccountState
  >('bankAccount');

  const select = createSelector(
    pagerSelector.selectEntities,
    pagerSelector.selectIds,
    (ents, ids) => ents[ids?.[0]]
  );

  const selectMandate = createSelector(
    select,
    (bankAccount) => bankAccount?.mandate
  );
  const selectCanCreateMandate = createSelector(
    select,
    (bankAccount) =>
      !!bankAccount &&
      (!bankAccount?.mandate ||
        [
          BankAccountMandateStatus.expired,
          BankAccountMandateStatus.failed,
        ].includes(bankAccount.mandate.status))
  );

  return { ...pagerSelector, select, selectMandate, selectCanCreateMandate };
};

export const bankAccountSelector = createBankAccountSelector();
