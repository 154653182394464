import { Expose } from 'class-transformer';

export class TeamCreateModel {
  @Expose()
  name: string;

  constructor(init?: Partial<TeamCreateModel>) {
    if (init) {
      Object.assign(this, init);
    }
  }
}
