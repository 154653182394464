import { PagerEntity } from '../../../common/models/PagerEntity';
import { createPagerReducer } from '../../../tools/reducer-helper/create-pager-reducer.factory';
import { IHasPagerEntities } from '../../../tools/reducer-helper/model/has-pager-entities';
import { GenreModel } from '../../models/genre.model';
import { genreAction } from '../action/genre.actions';

export type GenreState = IHasPagerEntities<GenreModel, null>;
const initialPager = new PagerEntity<GenreModel, null>({
  id: 'default',
  pages: {},
  selectedPageNum: 1,
  maxCount: null,
  limit: 50,
  asc: false,
  orderBy: 'id',
  search: null,
});

const genreInitialState: GenreState = {
  entities: {},
  ids: [],
  initialPager: initialPager,
  pagers: {},
  pagerIds: [],
  selectedId: null,
};

export const genreReducer = createPagerReducer({
  details: GenreModel,
  filter: null,
  initialState: genreInitialState,
  actions: genreAction,
  source: 'Genre',
});
