import { Observable } from 'rxjs';

import { ResponseContainer } from '../../common/models/ResponseContainer';
import { MangopayRequestUrlModel } from '../../mangopay/models/mangopay-request-url.model';
import { MangopayUrlModel } from '../../mangopay/models/mangopay-url.model';
import { PagerServiceAbstract } from '../../tools/reducer-helper/pager-service';
import { CampaignV2CreateModel } from '../models/campaign-v2-create.model';
import { CampaignV2DetailsModel } from '../models/campaign-v2-details.model';
import { CampaignV2EditModel } from '../models/campaign-v2-edit.model';
import { CampaignV2SearchModel } from '../models/campaign-v2-search.model';
import { CampaignV2SumModel } from '../models/campaign-v2-sum.model';
import { CampaignV2SummaryPreviewParamsModel } from '../models/campaign-v2-summary-preview.params.model';
import { CampaignV2TargetModel } from '../models/campaign-v2-target.model';

export abstract class CampaignV2ServiceAbstract extends PagerServiceAbstract<
  CampaignV2DetailsModel,
  CampaignV2SearchModel,
  CampaignV2CreateModel,
  CampaignV2EditModel
> {
  detailsModel = CampaignV2DetailsModel;
  filterModel = CampaignV2SearchModel;
  createModel = CampaignV2CreateModel;
  editModel = CampaignV2EditModel;

  abstract setTarget(
    model: CampaignV2TargetModel,
    id: number
  ): Observable<ResponseContainer<CampaignV2DetailsModel>>;

  abstract getPayoutUrl(
    model: MangopayRequestUrlModel,
    id: number
  ): Observable<ResponseContainer<MangopayUrlModel>>;

  abstract payFromWallet(
    model: MangopayRequestUrlModel,
    id: number
  ): Observable<ResponseContainer<MangopayUrlModel>>;

  abstract getSummaryPreview(
    model: CampaignV2SummaryPreviewParamsModel
  ): Observable<ResponseContainer<CampaignV2SumModel>>;
}
