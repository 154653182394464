import { Expose } from 'class-transformer';

export class BankAccountCreateModel {
  @Expose()
  iban: string;
  @Expose()
  bic: string;
  @Expose()
  countryId: number;
  @Expose()
  zip: string; // max 10 characters
  @Expose()
  city: string; // max 255 characters
  @Expose()
  address: string;
  @Expose()
  name?: string; // max 255 characters
}
