export enum UserRole {
  read = 0,
  write,
  companyRead = 20,
  companyWrite = 21,
  teamCreate = 22,
  teamAllRead = 23,
  teamAllWrite = 24,
  teamRead = 30,
  teamWrite = 31,
}
