import { Expose } from 'class-transformer';

import { BankAccountMandateStatus } from './bank-account-mandate.status';
import { BankAccountMandateScheme } from './bank-account-mandate.scheme';

export class BankAccountMandateModel {
  @Expose()
  status: BankAccountMandateStatus;

  @Expose()
  schema: BankAccountMandateScheme;

  @Expose()
  documentUrl?: string;

  @Expose()
  bankReference?: string;

  @Expose()
  confirmationUrl?: string;

  @Expose()
  resultMessage?: string;
}
