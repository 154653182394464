import { PagerServiceAbstract } from '../../tools/reducer-helper/pager-service';
import { CompanyCreateModel } from '../models/company-create.model';
import { CompanyDetailsModel } from '../models/company-details.model';
import { CompanyEditModel } from '../models/company-edit.model';
import { CompanySearchModel } from '../models/company-search.model';

export abstract class CompanyServiceAbstract extends PagerServiceAbstract<
  CompanyDetailsModel,
  CompanySearchModel,
  CompanyCreateModel,
  CompanyEditModel
> {
  detailsModel = CompanyDetailsModel;
  filterModel = CompanySearchModel;
  createModel = CompanyCreateModel;
  editModel = CompanyEditModel;
}
