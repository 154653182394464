import { inject, Injectable } from '@angular/core';
import { createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { LiveChangeEffects } from '@songpush/core/tools/reducer-helper';
import {
  debounceTime,
  delay,
  map,
  merge,
  mergeMap,
  of,
  pairwise,
  startWith,
} from 'rxjs';
import { UserDetailsModel } from '../../../user/models/user-details.model';
import { UserSearchModel } from '../../../user/models/user-search.model';
import { WebsocketChanges } from '../../../websocket/models/websocket-changes';
import { WebsocketTarget } from '../../../websocket/models/websocket-target';
import { WebsocketActions } from '../../../websocket/store/action';
import { SessionActions } from '../actions';
import { SessionSelector } from '../selectors';

@Injectable()
export class SessionLiveChangeEffects extends LiveChangeEffects<
  'Session',
  UserDetailsModel,
  UserSearchModel
> {
  protected actions = SessionActions;
  protected websocketTarget = WebsocketTarget.Session;

  protected getWebsocketDeleteAction = () => null;
  protected getWebsocketCreateAction = (action: WebsocketChanges) =>
    SessionActions.refreshUser({
      manager: false,
      userId: action.id,
    });
  protected getWebsocketEditAction = (action: WebsocketChanges) =>
    SessionActions.refreshUser({
      manager: false,
      userId: action.id,
    });
  protected getWebsocketReloadAllAction = (action: WebsocketChanges) =>
    SessionActions.refreshUser({
      manager: false,
      userId: action.id,
    });

  tokenFromLogin$ = this.actions$.pipe(
    ofType(SessionActions.login),
    map((action) => action.token)
  );

  tokenFromSavedState$ = this.actions$.pipe(
    ofType(SessionActions.loadFromDbCompleted),
    map((action) => action?.state?.token)
  );

  tokenFromLogout$ = this.actions$.pipe(
    ofType(SessionActions.logout),
    map(() => null)
  );

  token$ = inject(Store).select(SessionSelector.selectToken);
  managerToken$ = inject(Store).select(SessionSelector.selectManagerToken);

  connectSession$ = createEffect(() =>
    this.token$.pipe(
      debounceTime(200),
      startWith(null),
      pairwise(),
      mergeMap(([prevTopic, topic]) =>
        merge(
          ...[
            ...(prevTopic
              ? [of(WebsocketActions.disconnect({ topic: prevTopic }))]
              : []),
            ...(topic
              ? [of(WebsocketActions.connect({ topic })).pipe(delay(400))]
              : []),
          ]
        )
      )
    )
  );

  connectManagerSession$ = createEffect(() =>
    this.managerToken$.pipe(
      debounceTime(200),
      startWith(null),
      pairwise(),
      mergeMap(([prevTopic, topic]) =>
        merge(
          ...[
            ...(prevTopic
              ? [of(WebsocketActions.disconnect({ topic: prevTopic }))]
              : []),
            ...(topic
              ? [of(WebsocketActions.connect({ topic })).pipe(delay(400))]
              : []),
          ]
        )
      )
    )
  );
}
