import { Observable, tap } from 'rxjs';

import { Action, Store } from '@ngrx/store';

import { PagerEntity } from '../../common/models/PagerEntity';
import { Scope } from '../../common/models/scope';
import { ILoadPageAction } from '../reducer-tools/reducer-tools';

type UpdatePagerAction = ILoadPageAction & Action;

export function keepPageScopeLoaded<DetailModel, SearchModel>(params: {
  updateAction: UpdatePagerAction;
  store: Store;
}): (source: Observable<Scope<DetailModel, SearchModel>>) => Observable<Scope<DetailModel, SearchModel>> {
  return (source: Observable<Scope<DetailModel, SearchModel>>) => {
    let prevPager: PagerEntity<DetailModel, SearchModel> = null;
    return source.pipe(
      tap(({ pager }) => {
        if (isPagerChanged(pager, prevPager)) {
          prevPager = pager;
          if (pager.maxCount === null && params.updateAction) {
            params.store.dispatch(params.updateAction);
          }
        }
      })
    );
  };
}

const isPagerChanged = (pager, prevPager) => pager.maxCount !== prevPager?.maxCount || prevPager?.id !== pager.id;
