import { Observable, filter, map } from 'rxjs';

export const notEmpty =
  () =>
  <T>(obs: Observable<T | null | undefined>) =>
    obs.pipe(
      filter((val) => !!val),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      map((val) => val!)
    );
