import { on } from '@ngrx/store';
import { PagerEntity } from '../../common/models/PagerEntity';
import { Mappings } from '../../tools/mapping/mappings';
import { createPagerReducer } from '../../tools/reducer-helper/create-pager-reducer.factory';
import { IHasPagerEntities } from '../../tools/reducer-helper/model/has-pager-entities';
import { BankAccountDetailsModel } from '../models/bank-account-details.model';
import { BankAccountSearchModel } from '../models/bank-account-search.model';
import { bankAccountAction } from './bank-account.action';

export type BankAccountState = IHasPagerEntities<
  BankAccountDetailsModel,
  BankAccountSearchModel
>;
const initialPager = new PagerEntity<
  BankAccountDetailsModel,
  BankAccountSearchModel
>({
  id: 'default',
  pages: {},
  selectedPageNum: null,
  maxCount: null,
  limit: 50,
  asc: false,
  orderBy: 'id',
  search: new BankAccountSearchModel({}),
});

const bankAccountInitialState: BankAccountState = {
  entities: {},
  ids: [],
  initialPager: initialPager,
  pagers: {},
  pagerIds: [],
  selectedId: null,
};

export const bankAccountReducer = createPagerReducer(
  {
    details: BankAccountDetailsModel,
    filter: BankAccountSearchModel,
    initialState: bankAccountInitialState,
    actions: bankAccountAction,
    source: 'BankAccount',
  },
  on(bankAccountAction.updateMandate, (state, action) =>
    Mappings.assign(state, {
      entities: {
        [state.ids[0]]: Mappings.assign(state.entities[state.ids[0]], {
          mandate: action.payload,
        }),
      },
    })
  )
);

/// TODO
