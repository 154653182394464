import { PagerEntity } from '../../common/models/PagerEntity';
import { createPagerReducer } from '../../tools/reducer-helper/create-pager-reducer.factory';
import { IHasPagerEntities } from '../../tools/reducer-helper/model/has-pager-entities';
import { SettingsNotificationGroupDetailsModel } from '../models/settings-notification-group-details.model';
import { SettingsNotificationGroupSearchModel } from '../models/settings-notification-group-search.model';
import { settingsNotificationGroupAction } from './settings-notification-group.action';

export type SettingsNotificationGroupState = IHasPagerEntities<
  SettingsNotificationGroupDetailsModel,
  SettingsNotificationGroupSearchModel
>;
const initialPager = new PagerEntity<
  SettingsNotificationGroupDetailsModel,
  SettingsNotificationGroupSearchModel
>({
  id: 'default',
  pages: {},
  selectedPageNum: 1,
  maxCount: null,
  limit: 50,
  asc: false,
  orderBy: 'id',
  search: new SettingsNotificationGroupSearchModel({}),
});

const settingsNotificationGroupInitialState: SettingsNotificationGroupState = {
  entities: {},
  ids: [],
  initialPager: initialPager,
  pagers: {},
  pagerIds: [],
  selectedId: null,
};

export const settingsNotificationGroupReducer = createPagerReducer({
  details: SettingsNotificationGroupDetailsModel,
  filter: SettingsNotificationGroupSearchModel,
  initialState: settingsNotificationGroupInitialState,
  actions: settingsNotificationGroupAction,
  source: 'SettingsNotificationGroup',
});
