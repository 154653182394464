import { Observable } from 'rxjs';

import { ResponseContainer } from '../../common/models/ResponseContainer';
import { PagerServiceAbstract } from '../../tools/reducer-helper/pager-service';
import { ContentCategoryModel } from '../models';

export abstract class ContentCategoryServiceAbstract extends PagerServiceAbstract<
  ContentCategoryModel,
  null,
  null,
  null
> {
  detailsModel = ContentCategoryModel;
  filterModel = null;
  createModel = null;
  editModel = null;

  create(): Observable<ResponseContainer<ContentCategoryModel>> {
    throw 'not supported';
  }
  edit(): Observable<ResponseContainer<ContentCategoryModel>> {
    throw 'not supported';
  }
  get(): Observable<ResponseContainer<ContentCategoryModel>> {
    throw 'not supported';
  }
}
