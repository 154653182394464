import { makeEnvironmentProviders } from '@angular/core';

import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { MoodService } from '../services/mood.service';
import { MoodServiceAbstract } from '../services/mood.service.abstract';
import { MoodEffects } from '../store/effect';
import { moodReducer } from '../store/reducer';

export const provideMoodState = () =>
  makeEnvironmentProviders([
    provideState('mood', moodReducer),
    provideEffects([MoodEffects]),
    { provide: MoodServiceAbstract, useClass: MoodService },
  ]);
