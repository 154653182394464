import { Injectable, inject } from '@angular/core';

import { delayWhen, filter, switchMap, take, tap } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';

import { Storage } from '@ionic/storage';
import { createEffect, ofType } from '@ngrx/effects';
import { instanceToPlain, plainToInstance } from 'class-transformer';

import { PagerEntity } from '../../../common/models/PagerEntity';
import { SettingsActions } from '../../../settings/store/actions';
import { SettingsSelector } from '../../../settings/store/selectors';
import { storageAction } from '../../../storage/state';
import { filterEmpty } from '../../../tools/map/filter-empty';
import { PagerEffects } from '../../../tools/reducer-helper/pager-effects';
import { LanguageModel } from '../../models/language.model';
import { LanguageServiceAbstract } from '../../services/language.service.abstract';
import { languageAction } from '../action/language.actions';
import { languageSelector } from '../selector/language.selector';

@Injectable()
export class LanguageEffects extends PagerEffects<
  'Language',
  LanguageModel,
  null,
  null,
  null
> {
  private readonly LANGUAGE_KEY = 'languages';
  storage = inject(Storage);

  loadFromDb$ = createEffect(() =>
    this.actions$.pipe(
      ofType(storageAction.ready),
      switchMap(() => this.storage.get(this.LANGUAGE_KEY)),
      filterEmpty(),
      map((plainState) =>
        plainState ? plainToInstance(LanguageModel, plainState as any[]) : null
      ),
      map((languages) =>
        languages
          ? languageAction.loadAllCompleted({
              payload: languages,
              pagerId: 'default',
              pager: new PagerEntity({ maxCount: languages.length }),
            })
          : languageAction.loadAll({ pagerId: 'default' })
      )
    )
  );

  languageChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.languageChanged),
      delayWhen(() =>
        this.store.select(SettingsSelector.getLoaded).pipe(
          filter((loaded) => !!loaded),
          take(1)
        )
      ),
      map(() =>
        languageAction.loadAll({
          pagerId: 'default',
        })
      )
    )
  );

  saveToDb$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(languageAction.loadAllCompleted),
        tap(({ payload }) =>
          this.storage.set(this.LANGUAGE_KEY, instanceToPlain(payload))
        )
      ),
    { dispatch: false }
  );

  constructor() {
    super(languageAction, languageSelector, inject(LanguageServiceAbstract));
  }
}
