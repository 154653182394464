export const interpolateString = (expr: string, params?: any): any => {
  const templateMatcher = /{{\s?([^{}\s]*)\s?}}/g;
  if (!params) {
    return expr;
  }
  return expr.replace(templateMatcher, (substring: string, b: string) => {
    const r = getValue(params, b);
    return isDefined(r) ? r : substring;
  });
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getValue = (target: any, key: string) => {
  const keys = typeof key === 'string' ? key.split('.') : [key];
  key = '';
  do {
    key += keys.shift();
    if (isDefined(target) && isDefined(target[key]) && (typeof target[key] === 'object' || !keys.length)) {
      target = target[key];
      key = '';
    } else if (!keys.length) {
      target = undefined;
    } else {
      key += '.';
    }
  } while (keys.length);

  return target;
};
const isDefined = (value: unknown) => typeof value !== 'undefined' && value !== null;
