import { Injectable, inject } from '@angular/core';

import { mergeMap } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';

import { createEffect, ofType } from '@ngrx/effects';

import { countryAction } from '../../../country/store/action/country.actions';
import { SessionActions } from '../../../session/store/actions/session.actions';
import { filterEmpty } from '../../../tools/map/filter-empty';
import { PagerEffects } from '../../../tools/reducer-helper/pager-effects';
import { CompanyCreateModel } from '../../models/company-create.model';
import { CompanyDetailsModel } from '../../models/company-details.model';
import { CompanyEditModel } from '../../models/company-edit.model';
import { CompanySearchModel } from '../../models/company-search.model';
import { CompanyServiceAbstract } from '../../services/company.service.abstract';
import { companyAction } from '../action/company.actions';
import { companySelector } from '../selector/company.selector';

@Injectable()
export class CompanyEffects extends PagerEffects<
  'Company',
  CompanyDetailsModel,
  CompanySearchModel,
  CompanyEditModel,
  CompanyCreateModel
> {
  resetLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.logoutCompleted),
      map(() =>
        companyAction.changeAllPager({
          matcher: () => true,
          payload: { maxCount: null },
        })
      )
    )
  );

  mergeCountries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(this.actions.singleLoadCompleted),
      mergeMap((action) => [
        action.payload.phoneCountry,
        action.payload.country,
      ]),
      filterEmpty(),
      map((country) =>
        countryAction.singleLoadCompleted({
          payload: country,
        })
      )
    )
  );

  constructor() {
    super(companyAction, companySelector, inject(CompanyServiceAbstract));
  }
}
