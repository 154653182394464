import { Expose } from 'class-transformer';

import { CampaignV2BriefingModel } from './campaign-v2-briefing.model';

export class CampaignV2HistoryModel {
  @Expose()
  id: number;
  @Expose()
  effectiveFrom: string;
  @Expose()
  briefing: CampaignV2BriefingModel;
}
