import { Expose } from 'class-transformer';

export class SpotifyTrackSearchModel {
  @Expose()
  search: string;

  constructor(ent: Partial<SpotifyTrackSearchModel>) {
    Object.assign(this, ent);
  }
}
