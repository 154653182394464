import { inject } from '@angular/core';
import { CanActivateFn, CanMatchFn, Route, Router } from '@angular/router';

import { filter, mergeMap } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { SessionSelector } from '../store/selectors/session.selector';

export const signedOutGuard: CanActivateFn | CanMatchFn = (route: Route) => {
  const store = inject(Store);
  const router = inject(Router);
  const skipRedirect = route.data.skipRedirect;
  return store.select(SessionSelector.selectLoaded).pipe(
    filter((loaded) => loaded === true),
    mergeMap(() => store.select(SessionSelector.selectSignedIn)),
    mergeMap(async (signedIn) => {
      if (!signedIn) {
        return true;
      } else {
        if (!skipRedirect) {
          await router.navigate(['/dashboard'], { replaceUrl: true });
        }
        return false;
      }
    })
  );
};
