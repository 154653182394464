import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';

import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { DealLimitFacade } from '../facade/deal-limit.facade';
import { DealLimitService } from '../services/deal-limit.service';
import { DealLimitServiceAbstract } from '../services/deal-limit.service.abstract';
import { DealLimitLiveChangeEffects } from '../store/deal-limit-livechange.effects';
import { DealLimitEffects } from '../store/deal-limit.effects';
import { dealLimitReducer } from '../store/deal-limit.reducer';

export const provideDealLimitState = (): EnvironmentProviders =>
  makeEnvironmentProviders([
    provideState('dealLimit', dealLimitReducer),
    provideEffects([DealLimitEffects, DealLimitLiveChangeEffects]),
    { provide: DealLimitServiceAbstract, useClass: DealLimitService },
    DealLimitFacade,
  ]);
