import { PagerServiceAbstract } from '../../tools/reducer-helper/pager-service';
import { StatCreateModel } from '../models/stat-create.model';
import { StatDetailsModel } from '../models/stat-details.model';
import { StatEditModel } from '../models/stat-edit.model';
import { StatSearchModel } from '../models/stat-search.model';

export abstract class StatServiceAbstract extends PagerServiceAbstract<
  StatDetailsModel,
  StatSearchModel,
  StatCreateModel,
  StatEditModel
> {}
