import { Expose, Transform } from 'class-transformer';


import { SongType } from './song.type';
import { Mappings } from '../../tools/mapping/mappings';

export class SongCreateModel {
  @Expose()
  artists: string[];

  @Expose()
  title: string;

  @Expose()
  @Transform((opts) => (opts?.value as string[])?.filter((str) => str?.length > 0), { toPlainOnly: true })
  featuring: string[];

  @Expose()
  // @Transform(({ value }) => Mappings.formatDateOnly(value), { toPlainOnly: true })
  release: string;

  @Expose()
  subGenreIds: number[];

  @Expose()
  languageIds: number[];

  @Expose()
  explicitLyrics: boolean;

  @Expose()
  explicitVideo: boolean;

  @Expose()
  url: string; // if type === video youtubeurl, if pitch fileurl

  @Expose()
  spotifyUrl: string;

  @Expose()
  moodIds: number[];

  @Expose()
  cover: string;

  @Expose()
  type: SongType;

  @Expose()
  mp3: string;

  @Expose()
  @Transform(({ value }) => (value ? Mappings.getBaseUrl(value) : ''))
  tiktokUrl: string;

  @Expose()
  tiktokMp3: string;
}
