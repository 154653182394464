import { Injectable, inject } from '@angular/core';

import { map } from 'rxjs/internal/operators/map';

import { createEffect, ofType } from '@ngrx/effects';

import { SessionActions } from '../../../session/store/actions/session.actions';
import { hasSongInStore } from '../../../song/guards/song-exits.guard';
import { SongSearchModel } from '../../../song/models/song-search.model';
import { songAction } from '../../../song/store/action/song.actions';
import { PagerEffects } from '../../../tools/reducer-helper/pager-effects';
import { CampaignV2CreateModel } from '../../models/campaign-v2-create.model';
import { CampaignV2DetailsModel } from '../../models/campaign-v2-details.model';
import { CampaignV2EditModel } from '../../models/campaign-v2-edit.model';
import { CampaignV2SearchModel } from '../../models/campaign-v2-search.model';
import { CampaignV2ServiceAbstract } from '../../services/campaign-v2.service.abstract';
import { campaignV2Action } from '../action/campaign-v2.actions';
import { campaignV2Selector } from '../selector/campaign-v2.selector';

@Injectable()
export class CampaignV2Effects extends PagerEffects<
  'CampaignV2',
  CampaignV2DetailsModel,
  CampaignV2SearchModel,
  CampaignV2EditModel,
  CampaignV2CreateModel
> {
  resetLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.logoutCompleted, SessionActions.selectUserSuccess),
      map(() =>
        campaignV2Action.changeAllPager({
          matcher: () => true,
          payload: { maxCount: null },
        })
      )
    )
  );

  resolveSong$ = createEffect(
    () =>
      this.resolveEntities(['songId'], songAction, SongSearchModel, (id) =>
        hasSongInStore(id, this.store).pipe(map((exist) => ({ exist, id })))
      ),
    { dispatch: false }
  );

  constructor() {
    super(
      campaignV2Action,
      campaignV2Selector,
      inject(CampaignV2ServiceAbstract)
    );
  }
}
