import { createPagerSelectors } from '../../tools/reducer-helper/create-pager-selector.factory';
import { DealLimitDetailsModel } from '../models/deal-limit-details.model';
import { DealLimitSearchModel } from '../models/deal-limit-search.model';
import { DealLimitState } from './deal-limit.reducer';

export const dealLimitSelector = createPagerSelectors<
  DealLimitDetailsModel,
  DealLimitSearchModel,
  DealLimitState
>('dealLimit');
