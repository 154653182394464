export enum DealStatus {
  created = 1001,
  canceledByCreator = 21000,
  canceledByAdmin = 22500,
  proofUrlInserted = 40000,
  proofUrlTrackingStart = 40200,
  proofUrlDeleted = 40350,
  proofUrlTrackingStop = 40400,
  reportedByArtist = 42000,
  reportedByAdmin = 42500,
  incomplete = 43000,
  reviewStart = 51000,
  reviewEnd = 52000,
  refunded = 101000,
  completed = 103000,
}
