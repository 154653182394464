import { Injectable, inject } from '@angular/core';

import { map } from 'rxjs/internal/operators/map';

import { createEffect, ofType } from '@ngrx/effects';

import { SessionActions } from '../../../session/store/actions/session.actions';
import { PagerEffects } from '../../../tools/reducer-helper/pager-effects';
import { UserCreateWithSocialModel } from '../../models';
import { UserDetailsModel } from '../../models/user-details.model';
import { UserEditModel } from '../../models/user-edit.model';
import { UserSearchModel } from '../../models/user-search.model';
import { UserServiceAbstract } from '../../services/user.service.abstract';
import { userAction } from '../action/user.actions';
import { userSelector } from '../selector/user.selector';

@Injectable()
export class UserEffects extends PagerEffects<
  'User',
  UserDetailsModel,
  UserSearchModel,
  UserEditModel,
  UserCreateWithSocialModel
> {
  resetLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.logoutCompleted),
      map(() =>
        userAction.changeAllPager({
          matcher: () => true,
          payload: { maxCount: null },
        })
      )
    )
  );

  constructor() {
    super(userAction, userSelector, inject(UserServiceAbstract));
  }
}
