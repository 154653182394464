import { Expose } from 'class-transformer';

export class DealLimitSearchModel {
  @Expose()
  search: string;

  constructor(ent: Partial<DealLimitSearchModel>) {
    Object.assign(this, ent);
  }
}
