import { Observable, tap } from 'rxjs';

import { Action, Store } from '@ngrx/store';

import { PagerEntity } from '../../common/models/PagerEntity';
import { ObjectExtensions } from '../object/object-extension';
import { IChangePagerAction } from '../reducer-helper/model/change-pager';
import { ILoadAllAction } from '../reducer-helper/model/load-all';
import { ISelectPageAction } from '../reducer-helper/model/select-page-action';

type ChangePagerAction<DetailModel, SearchModel> = IChangePagerAction<DetailModel, SearchModel> & Action;
type UpdateAction = ILoadAllAction | ISelectPageAction;

export function keepPagerLoaded<DetailModel, SearchModel>(params: {
  changePagerAction: ChangePagerAction<DetailModel, SearchModel>;
  store: Store;
}): (source: Observable<PagerEntity<DetailModel, SearchModel>>) => Observable<PagerEntity<DetailModel, SearchModel>>;

export function keepPagerLoaded<DetailModel, SearchModel>(params: {
  updateAction: UpdateAction;
  store: Store;
}): (source: Observable<PagerEntity<DetailModel, SearchModel>>) => Observable<PagerEntity<DetailModel, SearchModel>>;

export function keepPagerLoaded<DetailModel, SearchModel>(params: {
  changePagerAction: ChangePagerAction<DetailModel, SearchModel>;
  target: Partial<PagerEntity<DetailModel, SearchModel>>;
  store: Store;
}): (source: Observable<PagerEntity<DetailModel, SearchModel>>) => Observable<PagerEntity<DetailModel, SearchModel>>;

export function keepPagerLoaded<DetailModel, SearchModel>(params: {
  changePagerAction: ChangePagerAction<DetailModel, SearchModel>;
  updateAction: UpdateAction;
  target: Partial<PagerEntity<DetailModel, SearchModel>>;
  store: Store;
}): (source: Observable<PagerEntity<DetailModel, SearchModel>>) => Observable<PagerEntity<DetailModel, SearchModel>>;

export function keepPagerLoaded<DetailModel, SearchModel>(params: {
  updateAction: UpdateAction;
  changePagerAction: ChangePagerAction<DetailModel, SearchModel>;
  target: Partial<PagerEntity<DetailModel, SearchModel>>;
  store: Store;
}): (source: Observable<PagerEntity<DetailModel, SearchModel>>) => Observable<PagerEntity<DetailModel, SearchModel>> {
  return (source: Observable<PagerEntity<DetailModel, SearchModel>>) => {
    let prevPager: PagerEntity<DetailModel, SearchModel> = null;
    return source.pipe(
      tap((pager) => {
        if (isMatchingWithTarget(pager, params.target)) {
          if (isPagerChanged(pager, prevPager)) {
            prevPager = pager;
            if (pager.maxCount === null && params.updateAction) {
              params.store.dispatch(params.updateAction);
            }
          }
        } else if (params.changePagerAction) {
          params.store.dispatch(params.changePagerAction);
        }
      })
    );
  };
}

const isMatchingWithTarget = <DetailModel, SearchModel>(
  pager: PagerEntity<DetailModel, SearchModel>,
  target?: Partial<PagerEntity<DetailModel, SearchModel>>
) => {
  return ObjectExtensions.compare(target || {}, pager, true);
};

const isPagerChanged = (pager, prevPager) => pager.maxCount !== prevPager?.maxCount || prevPager?.id !== pager.id;
