import { createAction, props } from '@ngrx/store';

import { SettingsTheme } from '../../models/settings-theme';
import { SettingsState } from '../reducers/settings.reducer';
import {
  CHANGE_LANGUAGE,
  CHANGE_THEME,
  LANGUAGE_CHANGED,
  LOAD_FROM_DB,
  LOAD_FROM_DB_COMPLETED,
  THEME_CHANGED,
} from './settings.action.type';

export const changeLanguage = createAction(
  CHANGE_LANGUAGE,
  props<{
    language: string;
  }>()
);

export const changeTheme = createAction(
  CHANGE_THEME,
  props<{
    theme: SettingsTheme;
  }>()
);

export const loadFromDb = createAction(LOAD_FROM_DB);

export const loadFromDbCompleted = createAction(
  LOAD_FROM_DB_COMPLETED,
  props<{
    state: SettingsState;
  }>()
);

export const languageChanged = createAction(LANGUAGE_CHANGED);
export const themeChanged = createAction(THEME_CHANGED);
