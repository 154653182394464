import { Injectable, inject } from '@angular/core';

import { tap } from 'rxjs';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

import { analyticsAction } from '@songpush/core/analytics/store';

@Injectable()
export class GoogleAnalyticsEffects {
  protected readonly actions = inject(Actions);
  protected readonly googleAnalyticsService = inject(GoogleAnalyticsService);

  pageView$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(analyticsAction.pageView),
        tap(({ title, url }) =>
          this.googleAnalyticsService.pageView(url, title)
        )
      ),
    { dispatch: false }
  );

  campaignClose$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(analyticsAction.campaignClose),
        tap(({ type, ...eventData }) => {
          this.googleAnalyticsService.gtag(
            'event',
            'campaign_close',
            eventData
          );
        })
      ),
    { dispatch: false }
  );

  campaignImpression$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(analyticsAction.campaignImpression),
        tap(({ type, ...eventData }) => {
          this.googleAnalyticsService.gtag(
            'event',
            'campaign_impression',
            eventData
          );
        })
      ),
    { dispatch: false }
  );

  campaignPurchase$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(analyticsAction.campaignPurchase),
        tap(({ type, ...eventData }) => {
          this.googleAnalyticsService.gtag(
            'event',
            'campaign_purchase',
            eventData
          );
        })
      ),
    { dispatch: false }
  );

  campaignSelect$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(analyticsAction.campaignSelect),
        tap(({ type, ...eventData }) => {
          this.googleAnalyticsService.gtag(
            'event',
            'campaign_select',
            eventData
          );
        })
      ),
    { dispatch: false }
  );

  payerInteraction$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(analyticsAction.playerInteraction),
        tap(({ type, ...eventData }) => {
          this.googleAnalyticsService.gtag(
            'event',
            'player_interaction',
            eventData
          );
        })
      ),
    { dispatch: false }
  );

  campaignProofInsert$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(analyticsAction.campaignProofInsert),
        tap(({ type, ...eventData }) =>
          this.googleAnalyticsService.gtag('event', 'form_submit', {
            form_name: 'proofUrlInsert',
            form_submit_text: eventData.dealId,
            dealId: eventData.dealId,
          })
        )
      ),
    { dispatch: false }
  );
}
