import { PagerEntity } from '../../../common/models/PagerEntity';
import { createPagerReducer } from '../../../tools/reducer-helper/create-pager-reducer.factory';
import { IHasPagerEntities } from '../../../tools/reducer-helper/model/has-pager-entities';
import { CampaignV2DetailsModel } from '../../models/campaign-v2-details.model';
import { CampaignV2SearchModel } from '../../models/campaign-v2-search.model';
import { campaignV2Action } from '../action/campaign-v2.actions';

export type CampaignV2State = IHasPagerEntities<
  CampaignV2DetailsModel,
  CampaignV2SearchModel
>;
const initialPager = new PagerEntity<
  CampaignV2DetailsModel,
  CampaignV2SearchModel
>({
  id: 'default',
  pages: {},
  selectedPageNum: 1,
  maxCount: null,
  limit: 50,
  asc: false,
  orderBy: 'id',
  search: new CampaignV2SearchModel({}),
});

const campaignV2InitialState: CampaignV2State = {
  entities: {},
  ids: [],
  initialPager: initialPager,
  pagers: {},
  pagerIds: [],
  selectedId: null,
};

export const campaignV2Reducer = createPagerReducer({
  details: CampaignV2DetailsModel,
  filter: CampaignV2SearchModel,
  initialState: campaignV2InitialState,
  actions: campaignV2Action,
  source: 'CampaignV2',
});
