import { Expose, Transform } from 'class-transformer';

export class InstagramSongMetadataModel {
  @Expose()
  @Transform(({ obj }) => (obj.author ? [obj.author] : []))
  artists: string[];
  @Expose()
  title: string;
  @Expose()
  @Transform(({ value }) => value?.thumb ?? value)
  cover: string;
  @Expose({ name: 'mp3' })
  instagramMp3: string;
}
