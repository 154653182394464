import { createPagerSelectors } from '../../../tools/reducer-helper/create-pager-selector.factory';
import { UserDetailsModel } from '../../models/user-details.model';
import { UserSearchModel } from '../../models/user-search.model';
import { UserState } from '../reducer/user.reducer';

export const userSelector = createPagerSelectors<
  UserDetailsModel,
  UserSearchModel,
  UserState
>('user');
