import { PagerEntity } from '../../common/models/PagerEntity';
import { Mappings } from '../../tools/mapping/mappings';
import { createPagerReducer } from '../../tools/reducer-helper/create-pager-reducer.factory';
import { IHasPagerEntities } from '../../tools/reducer-helper/model/has-pager-entities';
import { TransactionDetailsModel } from '../models/transaction-details.model';
import { TransactionSearchModel } from '../models/transaction-search.model';
import { transactionAction } from './transaction.action';

export type TransactionState = IHasPagerEntities<TransactionDetailsModel, TransactionSearchModel>;

export const transactionInitialPager = new PagerEntity<TransactionDetailsModel, TransactionSearchModel>({
  id: 'default',
  pages: {},
  selectedPageNum: null,
  maxCount: null,
  limit: 50,
  asc: false,
  orderBy: 'id',
  search: new TransactionSearchModel({}),
});

export const transactionDefaultPager = Mappings.assign(transactionInitialPager, {
  id: 'default',
  search: Mappings.assign(transactionInitialPager.search, {}),
});

export const transactionInitialState: TransactionState = {
  entities: {},
  ids: [],
  initialPager: transactionInitialPager,
  pagers: {
    ['default']: transactionDefaultPager,
  },
  pagerIds: [],
  selectedId: null,
};

export const transactionReducer = createPagerReducer({
  details: TransactionDetailsModel,
  filter: TransactionSearchModel,
  initialState: transactionInitialState,
  actions: transactionAction,
  source: 'Transaction',
});
