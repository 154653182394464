import { Exclude, Expose } from 'class-transformer';

import { Identifier } from '../../tools/reducer-helper/model/identifier';

export class ManagerInvitationCreateModel {
  @Expose()
  email: string;
  @Exclude()
  teamId: Identifier;
  @Expose()
  roles: string;

  constructor(init?: Partial<ManagerInvitationCreateModel>) {
    if (init) {
      Object.assign(this, init);
    }
  }
}
