import { Expose, Transform } from 'class-transformer';

export class StatDetailsModel {
  @Expose()
  id: string;

  @Expose({ name: 'label' })
  @Transform(({ value }) => new Date(value))
  name: Date;

  @Expose()
  value: number;

  constructor(init?: StatDetailsModel) {
    if (init) {
      Object.assign(this, init);
    }
  }
}
