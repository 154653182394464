import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { AnalyticsLogin } from '../model/analytics-login';
import { AnalyticsPageView } from '../model/anaytics-page-view';
import { AnalyticsCampaignImpression } from '../model/analytics-campaign-imperssion';
import { AnalyticsCampaignSelect } from '../model/analytics-campaign-select';
import { AnalyticsPlayerInteraction } from '../model/analytics-player-interaction';
import { AnalyticsCampaignClose } from '../model/analytics-campaign-close';
import { AnalyticsCampaignPurchase } from '../model/analytics-campaign-purchase';
import { AnalyticsProofInsert } from '../model/analytics-deal-proof-insert';

export const analyticsAction = createActionGroup({
  source: 'Analytics',
  events: {
    pageView: props<AnalyticsPageView>(),
    login: props<AnalyticsLogin>(),
    campaignImpression: props<AnalyticsCampaignImpression>(),
    campaignSelect: props<AnalyticsCampaignSelect>(),
    campaignClose: props<AnalyticsCampaignClose>(),
    campaignPurchase: props<AnalyticsCampaignPurchase>(),
    playerInteraction: props<AnalyticsPlayerInteraction>(),
    campaignProofInsert: props<AnalyticsProofInsert>(),
    logout: emptyProps(),
  },
});
