import { PagerServiceAbstract } from '../../tools/reducer-helper/pager-service';
import { SongCreateModel } from '../models/song-create.model';
import { SongDetailsModel } from '../models/song-details.model';
import { SongEditModel } from '../models/song-edit.model';
import { SongSearchModel } from '../models/song-search.model';

export abstract class SongServiceAbstract extends PagerServiceAbstract<
  SongDetailsModel,
  SongSearchModel,
  SongCreateModel,
  SongEditModel
> {
  detailsModel = SongDetailsModel;
  filterModel = SongSearchModel;
  createModel = SongCreateModel;
  editModel = SongEditModel;
}
