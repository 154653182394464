import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';

import { UserDetailsModel } from '../../../user/models/user-details.model';
import { SessionState } from '../reducers/session.reducer';
import { UserSearchModel } from '../../../user/models/user-search.model';
import { Identifier } from '../../../tools/reducer-helper/model/identifier';
import { UserRelationType } from '../../../user/models/user-relation-type';
import { createPagerActionGroup } from '../../../tools/reducer-helper/create-pager-action-group.factory';



const source = 'Session' as const;
export const SessionActions = {
  ...createPagerActionGroup<'Session', UserDetailsModel, UserSearchModel>({ source }),
  reloadEntity: createAction(`[${source}] reloadEntity`, props<{ id: Identifier; relation?: UserRelationType }>()),
  ...createActionGroup({
    source,
    events: {
      login: props<{
        user: UserDetailsModel;
        token: string;
        path?: string;
      }>(),
      logout: props<{ path?: string }>(),
      logoutCompleted: props<{ path?: string }>(),
      loadFromDb: emptyProps(),
      loadFromDbCompleted: props<{
        state: SessionState;
      }>(),
      saveToDb: emptyProps(),
      saveToDbCompleted: props<{
        state: SessionState;
      }>(),
      userEdit: props<{
        user: Partial<UserDetailsModel>;
      }>(),
      refreshUser: props<{ manager: boolean; userId?: Identifier }>(),
      selectUser: props<{ selectedId: Identifier; skipNavigation?: boolean; redirectUrl?: string }>(),
      selectUserSuccess: props<{
        user: UserDetailsModel;
        token: string;
        path?: string;
        skipNavigation?: boolean;
        previousUserId: Identifier;
      }>(),
      emptySelectedUser: emptyProps(),
      selectUserFailed: props<{ selectedId: Identifier; message?: string }>(),
      selectTeam: props<{ id: Identifier; skipUserSelect?: boolean }>(),
      resetUserState: emptyProps(),
    },
  }),
};
