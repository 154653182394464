import { Expose } from 'class-transformer';

import { IdNameModel } from '../../common/models/id-name.model';

export class MoodModel extends IdNameModel {
  @Expose()
  id: number;

  @Expose()
  name: string;
}
