import { makeEnvironmentProviders } from '@angular/core';

import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { SocialBlockService } from '../services/social-block.service';
import { SocialBlockServiceAbstract } from '../services/social-block.service.abstract';
import { SocialBlockEffects } from '../store/effect';
import { socialBlockReducer } from '../store/reducer';

export const provideSocialBlockState = () =>
  makeEnvironmentProviders([
    provideState('socialBlock', socialBlockReducer),
    provideEffects([SocialBlockEffects]),
    { provide: SocialBlockServiceAbstract, useClass: SocialBlockService },
  ]);
