import { Expose } from 'class-transformer';

import { CampaignV2Status } from './campaign-v2-status';
import { Identifier } from '../../tools/reducer-helper/model/identifier';


export class CampaignV2SearchModel {
  @Expose()
  search?: string;

  @Expose()
  status?: CampaignV2Status | CampaignV2Status[];

  @Expose()
  songId?: Identifier;

  @Expose()
  ids?: number[];

  constructor(ent: Partial<CampaignV2SearchModel>) {
    Object.assign(this, ent);
  }
}
