import { Expose } from 'class-transformer';

import { WalletType } from './wallet-type';

export class WalletSearchModel {
  @Expose()
  search?: string;

  @Expose()
  types?: WalletType[];

  constructor(ent: Partial<WalletSearchModel>) {
    Object.assign(this, ent);
  }
}
