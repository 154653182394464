import { Injectable } from '@angular/core';
import { LiveChangeEffects } from '@songpush/core/tools/reducer-helper';
import { WebsocketTarget } from '../../../websocket/models/websocket-target';
import { SongDetailsModel } from '../../models/song-details.model';
import { SongSearchModel } from '../../models/song-search.model';
import { songAction } from '../action';

@Injectable()
export class SongLiveChangeEffects extends LiveChangeEffects<
  'Song',
  SongDetailsModel,
  SongSearchModel
> {
  protected actions = songAction;
  protected websocketTarget = WebsocketTarget.Song;
}
