import { Injectable, inject } from '@angular/core';

import { map } from 'rxjs/internal/operators/map';

import { createEffect, ofType } from '@ngrx/effects';

import { SessionActions } from '../../../session/store/actions/session.actions';
import { PagerEffects } from '../../../tools/reducer-helper/pager-effects';
import { hasUserInStore } from '../../../user/guards/user-exist.guard';
import { UserSearchModel } from '../../../user/models/user-search.model';
import { userAction } from '../../../user/store/action';
import { SongCreateModel } from '../../models/song-create.model';
import { SongDetailsModel } from '../../models/song-details.model';
import { SongEditModel } from '../../models/song-edit.model';
import { SongSearchModel } from '../../models/song-search.model';
import { SongServiceAbstract } from '../../services/song.service.abstract';
import { songAction } from '../action/song.actions';
import { songSelector } from '../selector/song.selector';

@Injectable()
export class SongEffects extends PagerEffects<
  'Song',
  SongDetailsModel,
  SongSearchModel,
  SongEditModel,
  SongCreateModel
> {
  resetLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.logoutCompleted, SessionActions.selectUserSuccess),
      map(() =>
        songAction.changeAllPager({
          matcher: () => true,
          payload: { maxCount: null },
        })
      )
    )
  );

  resolveArtists$ = createEffect(
    () =>
      this.resolveEntities(['userId'], userAction, UserSearchModel, (id) =>
        hasUserInStore(id, this.store).pipe(map((exist) => ({ exist, id })))
      ),
    { dispatch: false }
  );

  constructor() {
    super(songAction, songSelector, inject(SongServiceAbstract));
  }
}
