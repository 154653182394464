import { makeEnvironmentProviders } from '@angular/core';

import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { GenreService } from '../services/genre.service';
import { GenreServiceAbstract } from '../services/genre.service.abstract';
import { GenreEffects } from '../store/effect';
import { genreReducer } from '../store/reducer';

export const provideGenreState = () =>
  makeEnvironmentProviders([
    provideState('genre', genreReducer),
    provideEffects([GenreEffects]),
    { provide: GenreServiceAbstract, useClass: GenreService },
  ]);
