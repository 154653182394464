/**
 *
 * @param iso iso3166_2
 * @returns regexp that match with iso
 */
export const getTaxNumberRegex = (iso: string): RegExp | null => {
  switch (iso?.toUpperCase()) {
    case 'AT': // Austria
      return /^(AT)U[0-9]{8}$/i;
    case 'BE': // Belgium
      return /^(BE)0[0-9]{9}$/i;
    case 'BG': // Bulgaria
      return /^(BG)[0-9]{9,10}$/i;
    case 'HR': // Croatia
      return /^(HR)[0-9]{11}$/i;
    case 'CY': // Cyprus
      return /^(CY)[0-9]{8}[A-Z]$/i;
    case 'CZ': // Czech Republic
      return /^(CZ)[0-9]{8,10}$/i;
    case 'DE': // Germany
      return /^(DE)[0-9]{9}$/i;
    case 'DK': // Denmark
      return /^(DK)[0-9]{8}$/i;
    case 'EE': // Estonia
      return /^(EE)[0-9]{9}$/i;
    case 'GR': // Greece
      return /^(EL|GR)[0-9]{9}$/i;
    case 'ES': // Spain
      return /^(ES)(([A-Z]\d{7}(\d|[A-Z]))|(\d{8}[A-Z]))$/i;
    case 'FI': // Finland
      return /^(FI)[0-9]{8}$/i;
    case 'FR': // France
      return /^(FR)[0-9A-Z]{2}[0-9]{9}$/i;
    case 'GB': // United Kingdom
      return /^(GB)([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})$/i;
    case 'HU': // Hungary
      return /^(HU)[0-9]{8}$/i;
    case 'IE': // Ireland
      return /^(IE)[0-9]S[0-9]{5}L$/i;
    case 'IT': // Italy
      return /^(IT)[0-9]{11}$/i;
    case 'LT': // Lithuania
      return /^(LT)([0-9]{9}|[0-9]{12})$/i;
    case 'LU': // Luxembourg
      return /^(LU)[0-9]{8}$/i;
    case 'LV': // Latvia
      return /^(LV)[0-9]{11}$/i;
    case 'MT': // Malta
      return /^(MT)[0-9]{8}$/i;
    case 'NL': // Netherlands
      return /^(NL)[0-9]{9}B[0-9]{2}$/i;
    case 'PL': // Poland
      return /^(PL)[0-9]{10}$/i;
    case 'PT': // Portugal
      return /^(PT)[0-9]{9}$/i;
    case 'RO': // Romania
      return /^(RO)[0-9]{2,10}$/i;
    case 'SE': // Sweden
      return /^(SE)[0-9]{12}$/i;
    case 'SI': // Slovenia
      return /^(SI)[0-9]{8}$/i;
    case 'SK': // Slovakia
      return /^(SK)[0-9]{10}$/i;
    case 'CH': // Switzerland
      return /^(CHE)\d{9} (MWST|TVA|IVA)$/i;

    default:
      return null;
  }
};
