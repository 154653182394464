import { Expose } from 'class-transformer';

export class CitySearchModel {
  @Expose()
  countryId?: number;
  @Expose()
  stateId?: number;
  @Expose()
  search?: string;
  @Expose()
  zip?: string;

  constructor(ent: Partial<CitySearchModel>) {
    Object.assign(this, ent);
  }
}
