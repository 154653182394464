import { PagerEntity } from '../../../common/models/PagerEntity';
import { createPagerReducer } from '../../../tools/reducer-helper/create-pager-reducer.factory';
import { IHasPagerEntities } from '../../../tools/reducer-helper/model/has-pager-entities';
import { SubGenreModel } from '../../models/sub-genre.model';
import { subGenreAction } from '../action';

export type SubGenreState = IHasPagerEntities<SubGenreModel, null>;
const initialPager = new PagerEntity<SubGenreModel, null>({
  id: 'default',
  pages: {},
  selectedPageNum: 1,
  maxCount: null,
  limit: 50,
  asc: false,
  orderBy: 'id',
  search: null,
});

const subGenreInitialState: SubGenreState = {
  entities: {},
  ids: [],
  initialPager: initialPager,
  pagers: {},
  pagerIds: [],
  selectedId: null,
};

export const subGenreReducer = createPagerReducer({
  details: SubGenreModel,
  filter: null,
  initialState: subGenreInitialState,
  actions: subGenreAction,
  source: 'SubGenre',
});
