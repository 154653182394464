import { createPagerActionGroup } from '../../../tools/reducer-helper/create-pager-action-group.factory';
import { CountrySearchModel } from '../../models/country-search.model';
import { CountryModel } from '../../models/country.model';

export const countryAction = createPagerActionGroup<
  'Country',
  CountryModel,
  CountrySearchModel
>({
  source: 'Country',
});
