import { makeEnvironmentProviders } from '@angular/core';

import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { LanguageService } from '../services/language.service';
import { LanguageServiceAbstract } from '../services/language.service.abstract';
import { LanguageEffects } from '../store/effect';
import { languageReducer } from '../store/reducer';

export const provideLanguageState = () =>
  makeEnvironmentProviders([
    provideState('language', languageReducer),
    provideEffects([LanguageEffects]),
    { provide: LanguageServiceAbstract, useClass: LanguageService },
  ]);
