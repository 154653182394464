import { Type } from 'class-transformer';

import { WebsocketBaseModel } from './websocket-base.model';
import { WebsocketChanges } from './websocket-changes';
import { WebsocketWalletChange } from './websocket-credit-change';
import { WebsocketData } from './websocket-data';
import { WebsocketIdentify } from './websocket-identify';
import { WebsocketMatch } from './websocket-match';
import { WebsocketMessageType } from './websocket-message-type';
import { WebsocketSiteUpdated } from './websocket-site-updated';

export class WebsocketMessage {
  id: string;
  @Type(() => WebsocketBaseModel, {
    discriminator: {
      property: 'type',
      subTypes: [
        {
          name: `${WebsocketMessageType.Changes}`,
          value: WebsocketChanges,
        },
        {
          name: `${WebsocketMessageType.WebsocketIdentify}`,
          value: WebsocketIdentify,
        },
        {
          name: `${WebsocketMessageType.MatchCompleted}`,
          value: WebsocketMatch,
        },
        {
          name: `${WebsocketMessageType.WalletChange}`,
          value: WebsocketWalletChange,
        },
        {
          name: `${WebsocketMessageType.SiteUpdated}`,
          value: WebsocketSiteUpdated,
        },
      ],
    },
  })
  data: WebsocketData;
}
