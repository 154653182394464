import { Injectable, inject } from '@angular/core';

import { map } from 'rxjs/internal/operators/map';

import { createEffect, ofType } from '@ngrx/effects';

import { SessionActions } from '../../../session/store/actions/session.actions';
import { PagerEffects } from '../../../tools/reducer-helper/pager-effects';
import { SocialBlockCreateModel } from '../../models/social-block-create.model';
import { SocialBlockDetailsModel } from '../../models/social-block-details.model';
import { SocialBlockEditModel } from '../../models/social-block-edit.model';
import { SocialBlockSearchModel } from '../../models/social-block-search.model';
import { SocialBlockServiceAbstract } from '../../services/social-block.service.abstract';
import { socialBlockAction } from '../action/social-block.actions';
import { socialBlockSelector } from '../selector/social-block.selector';

@Injectable()
export class SocialBlockEffects extends PagerEffects<
  'SocialBlock',
  SocialBlockDetailsModel,
  SocialBlockSearchModel,
  SocialBlockEditModel,
  SocialBlockCreateModel
> {
  resetLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.logoutCompleted),
      map(() =>
        socialBlockAction.changeAllPager({
          matcher: () => true,
          payload: { maxCount: null },
        })
      )
    )
  );

  constructor() {
    super(
      socialBlockAction,
      socialBlockSelector,
      inject(SocialBlockServiceAbstract)
    );
  }
}
