import { PagerServiceAbstract } from '../../tools/reducer-helper/pager-service';
import { SpotifyTrackCreateModel } from '../models/spotify-track-create.model';
import { SpotifyTrackDetailsModel } from '../models/spotify-track-details.model';
import { SpotifyTrackEditModel } from '../models/spotify-track-edit.model';
import { SpotifyTrackSearchModel } from '../models/spotify-track-search.model';

export abstract class SpotifyTrackServiceAbstract extends PagerServiceAbstract<
  SpotifyTrackDetailsModel,
  SpotifyTrackSearchModel,
  SpotifyTrackCreateModel,
  SpotifyTrackEditModel
> {
  detailsModel = SpotifyTrackDetailsModel;
  filterModel = SpotifyTrackSearchModel;
  createModel = SpotifyTrackCreateModel;
  editModel = SpotifyTrackEditModel;
}
