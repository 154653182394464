import { Expose, Transform } from 'class-transformer';

import { Identifier } from '../../tools/reducer-helper/model/identifier';



export class ManagerInvitationDetailsModel {
  @Expose()
  id: Identifier;
  @Expose()
  email: string;
  @Expose()
  @Transform(({ value }) => BigInt((value || '0') + ''), { toClassOnly: true })
  @Transform((v) => v.value + '', { toPlainOnly: true })
  roles: bigint;
  @Expose()
  teamId: Identifier;
}
