export enum TicketType {
  // Common
  Other = 1,

  // Artist
  NotWatched = 100001,
  NotShared,
  CreatorHateSpeech,

  // Creator
  Nudity = 200001,
  Drugs,
  Violence,
  Spam,
  SelfInjury,
  ShockingLyrics,
  HateSpeech,

  // Deal
  NotRelevant = 300001, // Renamed - MinViews was before
  NotAudible,
  MinLength, // Deprecated, needs to be supported
  ProductPlacement,
  Duplicated,
  ShockingContent,
  PoorQuality,
  FakeViews,
  OtherMusic,
  OtherReason, // Deprecated, needs to be supported
  StillImage,
  NotOriginal,
  BriefingNotFollowed,
}
