import { makeEnvironmentProviders } from '@angular/core';

import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { SettingsNotificationGroupService } from '../providers/settings-notification-group.service';
import { SettingsNotificationGroupServiceAbstract } from '../providers/settings-notification-group.service.abstract';
import { SettingsNotificationGroupEffects } from '../store/settings-notification-group.effects';
import { settingsNotificationGroupReducer } from '../store/settings-notification-group.reducer';

export const provideSettingsNotificationGroupState = () =>
  makeEnvironmentProviders([
    provideState('settingsNotificationGroup', settingsNotificationGroupReducer),
    provideEffects([SettingsNotificationGroupEffects]),
    {
      provide: SettingsNotificationGroupServiceAbstract,
      useClass: SettingsNotificationGroupService,
    },
  ]);
