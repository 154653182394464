import { Injectable, inject } from '@angular/core';

import { getTaxNumberRegex } from '@songpush/validators/tools/tax-number-regex';

import { API_URL } from '../../common/tokens/api-url';
import { CompanyServiceAbstract } from './company.service.abstract';

@Injectable()
export class CompanyService extends CompanyServiceAbstract {
  url = `${inject(API_URL)}company`;
  companyPublicUrl = `${inject(API_URL)}company-public`;
  protected isManagerResource = true;
  protected onlyOwn = true;

  protected get getPageUrl(): string {
    return this.companyPublicUrl;
  }
  constructor() {
    super();
  }

  checkTaxNumber(taxNumber: string, countryISO: string) {
    return Promise.resolve(
      !!getTaxNumberRegex(countryISO)?.exec?.(taxNumber?.toLowerCase())
    );
  }
}
