import { Observable } from 'rxjs';

import { ResponseContainer } from '../../common/models/ResponseContainer';
import { MangopayRequestUrlModel } from '../models/mangopay-request-url.model';
import { MangopayUrlModel } from '../models/mangopay-url.model';
import { TransactionDetailsModel } from '../../transaction/models/transaction-details.model';

export abstract class MangopayServiceAbstract {
  abstract getUrl(model: MangopayRequestUrlModel): Observable<ResponseContainer<MangopayUrlModel>>;
  abstract requestPayment(paymentUrl: string, window?: Window): Observable<ResponseContainer<TransactionDetailsModel>>;
}
