import { Expose } from 'class-transformer';

import { DealStatus } from '../../deal/models/deal-status';
import { Identifier } from '../../tools/reducer-helper/model/identifier';

export class ManagerDealSearchModel {
  @Expose()
  status?: DealStatus;

  @Expose()
  teamId?: Identifier;

  @Expose()
  search?: string;

  constructor(ent: Partial<ManagerDealSearchModel>) {
    Object.assign(this, ent);
  }
}
