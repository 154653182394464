import { createPagerSelectors } from '../../../tools/reducer-helper/create-pager-selector.factory';
import { DealDetailsModel } from '../../models/deal-details.model';
import { DealSearchModel } from '../../models/deal-search.model';
import { DealState } from '../reducer/deal.reducer';

export const dealSelector = createPagerSelectors<
  DealDetailsModel,
  DealSearchModel,
  DealState
>('deal');
