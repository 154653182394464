import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';

import { Observable, defer, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { ticketAction } from '../../store/action';
import { ticketSelector } from '../../store/selector';
import { TicketServiceAbstract } from '../providers/ticket.service.abstract';

export const ticketExistGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot
): Observable<boolean> => {
  const router = inject(Router);
  return hasTicket(
    route.params.id,
    inject(TicketServiceAbstract),
    inject(Store)
  ).pipe(
    // navigate if !exist navigate to 404
    tap((exist) => (exist || null) ?? router.navigate(['404']))
  );
};

export const hasTicketInStore = (
  id: number,
  store = inject(Store)
): Observable<boolean> => {
  return store.select(ticketSelector.selectItem(id)).pipe(
    map((ent) => !!ent),
    take(1)
  );
};

export const hasTicketInApi = (
  id: number,
  ticketService = inject(TicketServiceAbstract),
  store = inject(Store)
): Observable<boolean> =>
  defer(() => ticketService.get(id)).pipe(
    map((resp) => resp.values),
    tap((ticket) => {
      if (ticket) {
        store.dispatch(
          ticketAction.singleLoadCompleted({
            payload: ticket,
          })
        );
      }
    }),
    map((payload) => !!payload),
    catchError(() => of(false))
  );

export const hasTicket = (
  id: number,
  ticketService = inject(TicketServiceAbstract),
  store = inject(Store)
): Observable<boolean> =>
  hasTicketInStore(id, store).pipe(
    switchMap((inStore) => {
      if (inStore) {
        return of(inStore);
      }
      return hasTicketInApi(id, ticketService, store);
    })
  );
