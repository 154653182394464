import { Injectable, inject } from '@angular/core';

import { map } from 'rxjs';

import { instanceToPlain, plainToClassFromExist } from 'class-transformer';

import { TeamEditModel } from '../models/team-edit.model';
import { TeamUserRemoveModel } from '../models/team-user-remove.model';
import { TeamServiceAbstract } from './team.service.abstract';
import { API_URL } from '../../common/tokens/api-url';
import { TeamDetailsModel } from '../models/team-details.model';
import { TeamSearchModel } from '../models/team-search.model';
import { TeamCreateModel } from '../models/team-create.model';
import { ResponseContainer } from '../../common/models/ResponseContainer';

@Injectable()
export class TeamService extends TeamServiceAbstract {
  url = `${inject(API_URL)}management/team`;
  removeUserUrl = `${inject(API_URL)}management/manager`;
  inviteUserUrl = `${inject(API_URL)}management/manager/invite`;

  detailsModel = TeamDetailsModel;
  filterModel = TeamSearchModel;
  createModel = TeamCreateModel;
  editModel = TeamEditModel;
  protected isManagerResource = true;

  removeUser(model: TeamUserRemoveModel) {
    const body = instanceToPlain(model);
    const params = this.getParams();
    return this.http
      .delete(this.removeUserUrl, { body, params })
      .pipe(map((resp) => plainToClassFromExist(new ResponseContainer(null), resp)));
  }
}
