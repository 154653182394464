import { PagerEntity } from '../../../common/models/PagerEntity';
import { IChangeAllPagerAction } from '../model/change-all-pager';
import { IHasPagerEntities } from '../model/has-pager-entities';
import { IHasId } from '../model/i-has-id';
import { patchPager } from './patch-pager';

export const changeAllPagerReduce = <T extends IHasId, Z, U extends IHasPagerEntities<T, Z>>(
  state: U,
  action: IChangeAllPagerAction<T, Z>,
  initialPager: PagerEntity<T, Z>
): U => {
  const pagers = Object.keys(state.pagers) as string[];
  if (action.matcher) {
    return pagers.reduce(
      (newState, pagerId) =>
        action.matcher(state.pagers[pagerId]) ? patchPager(newState, action.payload, initialPager, pagerId) : newState,
      state
    );
  }

  return pagers.reduce((newState, pagerId) => patchPager(newState, action.payload, initialPager, pagerId), state);
};
