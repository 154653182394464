import { Expose, Transform } from 'class-transformer';

import { SocialType } from '../../social/models/social-type';

export class DealLimitDetailsModel {
  @Expose()
  @Transform(({ obj }) => {
    return Math.ceil(Math.random() * 40);
  })
  id: number;

  @Expose()
  platform: SocialType;

  @Expose()
  limit: number;

  @Expose()
  used: number;
}
