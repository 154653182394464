import { Expose, Transform, Type } from 'class-transformer';

import { CampaignV2EstimationViewModel } from './campaign-v2-estimation-view.model';

export class CampaignV2SumModel {
  @Expose()
  @Transform(({ value }) => value / 100)
  VAT: number;
  @Expose()
  @Transform(({ value }) => value / 100)
  fee: number;
  @Expose()
  @Transform(({ value }) => value / 100)
  order: number;
  @Expose()
  @Transform(({ value }) => value / 100)
  totalWithVAT: number;
  @Expose()
  @Transform(({ value }) => value / 100)
  wallet: number;

  @Expose()
  @Type(() => CampaignV2EstimationViewModel)
  estimatedViews: CampaignV2EstimationViewModel;
}
