import { makeEnvironmentProviders } from '@angular/core';

import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { ManagerInvitationService } from '../providers/manager-invitation.service';
import { ManagerInvitationServiceAbstract } from '../providers/manager-invitation.service.abstract';
import { ManagerInvitationLiveChangeEffects } from '../store/manager-invitation-livechange.effects';
import { ManagerInvitationEffects } from '../store/manager-invitation.effects';
import { managerInvitationReducer } from '../store/manager-invitation.reducer';

export const provideManagerInvitationState = () =>
  makeEnvironmentProviders([
    provideState('managerInvitation', managerInvitationReducer),
    provideEffects([
      ManagerInvitationEffects,
      ManagerInvitationLiveChangeEffects,
    ]),
    {
      provide: ManagerInvitationServiceAbstract,
      useClass: ManagerInvitationService,
    },
  ]);
