import { inject } from '@angular/core';

import { map } from 'rxjs';

import { createEffect, ofType } from '@ngrx/effects';

import { SessionActions } from '../../session/store/actions/session.actions';
import { PagerEffects } from '../../tools/reducer-helper/pager-effects';
import { ManagerInvitationCreateModel } from '../models/manager-invitation-create.model';
import { ManagerInvitationDetailsModel } from '../models/manager-invitation-details.model';
import { ManagerInvitationEditModel } from '../models/manager-invitation-edit.model';
import { ManagerInvitationSearchModel } from '../models/manager-invitation-search.model';
import { ManagerInvitationServiceAbstract } from '../providers/manager-invitation.service.abstract';
import { managerInvitationAction } from './manager-invitation.action';
import { managerInvitationSelector } from './manager-invitation.selector';

export class ManagerInvitationEffects extends PagerEffects<
  'ManagerInvitation',
  ManagerInvitationDetailsModel,
  ManagerInvitationSearchModel,
  ManagerInvitationEditModel,
  ManagerInvitationCreateModel
> {
  resetLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.logoutCompleted),
      map(() => managerInvitationAction.resetState())
    )
  );

  constructor() {
    super(
      managerInvitationAction,
      managerInvitationSelector,
      inject(ManagerInvitationServiceAbstract)
    );
  }
}
