import { Mappings } from '../../mapping/mappings';
import { IHasPagerEntities } from '../model/has-pager-entities';
import { IHasId } from '../model/i-has-id';
import { ILoadAllCompletedAction } from '../model/load-all-completed';
import { mergeIds } from './merge-ids';

export const loadAllCompletedReduce = <T extends IHasId, Z, U extends IHasPagerEntities<T, Z>>(
  state: U,
  action: ILoadAllCompletedAction<T, Z>
): U => {
  const newEntities = action.payload.reduce(
    (newEnts, user) => Mappings.assign(newEnts, { [user.id]: user }),
    {} as typeof state.entities
  );
  const entities = Mappings.assign(state.entities, newEntities);
  const ids = mergeIds(
    state.ids,
    action.payload.map(({ id }) => id)
  );
  return Mappings.assign(state, {
    entities,
    ids,
    pagers: Mappings.assign(state.pagers, {
      [action.pagerId]: action.pager,
    }),
  } as Partial<U>);
};
