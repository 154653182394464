import { Expose } from 'class-transformer';

export class TicketSearchModel {
  @Expose()
  search: string;

  @Expose()
  campaignId?: number | '';

  @Expose()
  slaveScheduleId?: number | '';

  constructor(ent: Partial<TicketSearchModel>) {
    Object.assign(this, ent);
  }
}
