import { Expose, Type } from 'class-transformer';

import { CompanyDetailsModel } from '../../company/models/company-details.model';



export class InviteDetailsModel {
  @Expose()
  email: string;
  @Expose()
  existUser: boolean;
  @Expose()
  teamName: string;
  @Expose()
  @Type(() => CompanyDetailsModel)
  company: CompanyDetailsModel;
}
