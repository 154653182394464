import { Expose, Transform } from 'class-transformer';

import { SystemMessageType } from './system-message.type';
import { Mappings } from '../../tools/mapping/mappings';

export class SystemMessageSearchModel {
  @Expose()
  @Transform(({ value }) =>
    Array.isArray(value)
      ? value.map((v) => Mappings.paramToNumber(v))
      : [Mappings.paramToNumber(value)]
  )
  type: SystemMessageType[];

  @Expose()
  displayFrom: string;

  @Expose()
  displayTo: string;

  @Expose()
  search?: string;

  constructor(ent: Partial<SystemMessageSearchModel>) {
    Object.assign(this, ent);
  }
}
