import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ResponseContainer } from '@songpush/core/common/models';




@Injectable()
export abstract class EmailServiceAbstract {
  abstract unsubscribe(token: string): Observable<ResponseContainer<unknown>>;
}
