import { Injectable, inject } from '@angular/core';

import { map } from 'rxjs';

import { plainToClassFromExist, instanceToPlain } from 'class-transformer';

import { SettingsNotificationGroupEditModel } from '../models/settings-notification-group-edit.model';
import { SettingsNotificationGroupServiceAbstract } from './settings-notification-group.service.abstract';
import { API_URL } from '../../common/tokens/api-url';
import { ResponseContainer } from '../../common/models/ResponseContainer';
import { SettingsNotificationGroupDetailsModel } from '../models/settings-notification-group-details.model';
import { SettingsNotificationGroupSearchModel } from '../models/settings-notification-group-search.model';
import { SettingsNotificationGroupCreateModel } from '../models/settings-notification-group-create.model';
import { SettingsNotificationMarketingDetailsModel } from '../models/settings-notification-marketing-details.model';
import { SettingsNotificationMarketingEditModel } from '../models/settings-notification-marketing-edit.model';

@Injectable()
export class SettingsNotificationGroupService extends SettingsNotificationGroupServiceAbstract {
  url = `${inject(API_URL)}notifications/subscribe/group`;
  marketingUrl = `${inject(API_URL)}notifications/subscribe/marketing`;

  detailsModel = SettingsNotificationGroupDetailsModel;
  filterModel = SettingsNotificationGroupSearchModel;
  createModel = SettingsNotificationGroupCreateModel;
  editModel = SettingsNotificationGroupEditModel;

  protected isManagerResource = true;

  getMarketing() {
    const params = this.getParams();
    return this.http
      .get(this.marketingUrl, { params })
      .pipe(
        map((resp) => plainToClassFromExist(new ResponseContainer(SettingsNotificationMarketingDetailsModel), resp))
      );
  }

  setMarketing(model: SettingsNotificationMarketingEditModel) {
    const params = this.getParams();
    const domain = instanceToPlain(model);
    return this.http
      .put(this.marketingUrl, domain, { params })
      .pipe(
        map((resp) => plainToClassFromExist(new ResponseContainer(SettingsNotificationMarketingDetailsModel), resp))
      );
  }
}
