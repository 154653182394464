import { NgSwitch, NgSwitchCase } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'ui-logo',
  standalone: true,
  imports: [NgSwitch,NgSwitchCase],
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent {
  @Input()
  @HostBinding('class')
  theme: 'postive' | 'negative' = 'postive';

  @Input()
  @HostBinding('class')
  type: 'mark' | 'logo' | 'defs' = 'mark';
}
