import { Injectable, inject } from '@angular/core';

import { Observable, map } from 'rxjs';

import { plainToClassFromExist } from 'class-transformer';


import { StatEditModel } from '../models/stat-edit.model';
import { StatServiceAbstract } from './stat.service.abstract';
import { StatType } from '../models/stat.type';
import { StatDetailsModel } from '../models/stat-details.model';
import { API_URL } from '../../common/tokens/api-url';
import { StatSearchModel } from '../models/stat-search.model';
import { StatCreateModel } from '../models/stat-create.model';
import { PagerEntity } from '../../common/models/PagerEntity';
import { PagerResponseContainer } from '../../common/models/PagerResponseContainer';
import { Mappings } from '../../tools/mapping/mappings';


const urls: Record<StatType, string> = {
  [StatType.DealView]: 'deal/stat/view',
  [StatType.DealShare]: 'deal/stat/share',
  [StatType.DealSave]: 'deal/stat/save',
  [StatType.DealLike]: 'deal/stat/like',
  [StatType.DealComment]: 'deal/stat/comment',

  [StatType.CampaignV2DealNotReported]: 'campaign-v2/stat/totalAmountGross',
  [StatType.CampaignV2DealBilled]: 'campaign-v2/stat/billedAmountGross',
  [StatType.CampaignV2View]: 'campaign-v2/stat/view',
  [StatType.CampaignV2Share]: 'campaign-v2/stat/share',
  [StatType.CampaignV2Save]: 'campaign-v2/stat/save',
  [StatType.CampaignV2Like]: 'campaign-v2/stat/like',
  [StatType.CampaignV2Comment]: 'campaign-v2/stat/comment',
  [StatType.CampaignV2ProofUrls]: 'campaign-v2/stat/proofUrl',
};

const currencyTypes = [StatType.CampaignV2DealBilled, StatType.CampaignV2DealNotReported];

@Injectable()
export class StatService extends StatServiceAbstract {
  url = `${inject(API_URL)}`;
  detailsModel = StatDetailsModel;
  filterModel = StatSearchModel;
  createModel = StatCreateModel;
  editModel = StatEditModel;

  getPage(pager: PagerEntity<StatDetailsModel, StatSearchModel>): Observable<PagerResponseContainer<StatDetailsModel>> {
    const params = this.getParams(Mappings.pagerToDomain(pager));

    if (!pager.search.type) {
      throw '[StatService] getPage -> bad request';
    }

    return this.http.get<any>(`${this.url}${urls[pager.search.type]}`, { params }).pipe(
      map((resp) =>
        plainToClassFromExist(new PagerResponseContainer(this.detailsModel), {
          ...resp,
          values: {
            ...resp?.values,
            results: resp.values.results.map((item) => ({
              ...item,
              id: `${item.label}-${pager.search.resolution}-${pager.search.type}`,
              value: currencyTypes.includes(pager.search.type) ? item.value / 100 : item.value,
            })),
          },
        })
      )
    );
  }
}
