import { makeEnvironmentProviders } from '@angular/core';

import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { CampaignV2Service } from '../services/campaign-v2.service';
import { CampaignV2ServiceAbstract } from '../services/campaign-v2.service.abstract';
import {
  CampaignV2Effects,
  CampaignV2LiveChangeEffects,
} from '../store/effect';
import { campaignV2Reducer } from '../store/reducer';

export const provideCampaignV2State = () =>
  makeEnvironmentProviders([
    provideState('campaignV2', campaignV2Reducer),
    provideEffects([CampaignV2Effects, CampaignV2LiveChangeEffects]),
    { provide: CampaignV2ServiceAbstract, useClass: CampaignV2Service },
  ]);
