import { on } from '@ngrx/store';
import { Exclude, Expose, Type } from 'class-transformer';

import { PagerEntity } from '../../../common/models';
import { Mappings } from '../../../tools/mapping/mappings';
import { createPagerReducer } from '../../../tools/reducer-helper/create-pager-reducer.factory';
import { IHasPagerEntities } from '../../../tools/reducer-helper/model/has-pager-entities';
import { Identifier } from '../../../tools/reducer-helper/model/identifier';
import { changePagerReduce } from '../../../tools/reducer-helper/state-modifier/change-pager.reduce';
import {
  UserRelationType,
  UserSearchModel,
  UserType,
} from '../../../user/models';
import { UserDetailsModel } from '../../../user/models/user-details.model';
import { SessionActions } from '../actions';

export class SessionState
  implements IHasPagerEntities<UserDetailsModel, UserSearchModel>
{
  @Type(() => UserDetailsModel)
  @Expose()
  manager?: UserDetailsModel;
  @Expose()
  managerToken?: string;
  @Expose()
  token: string;
  @Exclude()
  loaded: boolean;
  @Expose()
  selectedId: Identifier;
  @Exclude()
  pagers: { [key: Identifier]: PagerEntity<UserDetailsModel, UserSearchModel> };
  @Expose()
  entities: {
    [key: string]: UserDetailsModel;
    [key: number]: UserDetailsModel;
  };
  @Expose()
  ids: Identifier[];
  @Expose()
  teamId?: Identifier;
  @Exclude()
  initialPager: PagerEntity<UserDetailsModel, UserSearchModel>;
  @Exclude()
  pagerIds: string[];
  constructor(init?: SessionState) {
    if (init) {
      Object.assign(this, init);
    }
  }
}

const initialPager = new PagerEntity<UserDetailsModel, UserSearchModel>({
  asc: false,
  limit: 50,
  pages: {},
  search: new UserSearchModel({}),
  maxCount: null,
  id: 'default',
  selectedPageNum: 0,
});

export const sessionInitialState: SessionState = new SessionState({
  token: null,
  manager: null,
  managerToken: null,
  loaded: false,
  selectedId: null,
  entities: {},
  ids: [],
  pagers: {
    ['managed']: Mappings.assign(initialPager, {
      search: new UserSearchModel({
        realtion: UserRelationType.managed,
      }),
    }),
  },
  pagerIds: [],
  initialPager,
});

export const sessionReducer = createPagerReducer(
  {
    details: UserDetailsModel,
    filter: UserSearchModel,
    initialState: sessionInitialState,
    actions: SessionActions,
    source: 'Session',
  },
  on(SessionActions.login, (state, action) => {
    if (action.user.type === UserType.Manager) {
      return Mappings.assign(state, {
        managerToken: action.token,
        manager: action.user,
        token: null,
        loaded: true,
      });
    } else {
      return Mappings.assign(state, {
        token: action.token,
        manager: null,
        managerToken: null,
        selectedId: action.user.id,
        ids: [action.user.id],
        entities: { [action.user.id]: action.user },
        loaded: true,
      });
    }
  }),
  on(SessionActions.selectUserSuccess, (state, action) =>
    Mappings.assign(state, {
      token: action.token,
      selectedId: action.user.id,
      ids: Mappings.mergeIds(state.ids, [action.user.id]),
      entities: { ...state.entities, [action.user.id]: action.user },
      loaded: true,
    })
  ),
  on(SessionActions.loadFromDbCompleted, (state, action) =>
    Mappings.assign(state, {
      ...(action.state || {}),
      loaded: true,
    })
  ),
  on(SessionActions.logout, (state) =>
    Mappings.assign(state, { token: null, managerToken: null })
  ),
  on(SessionActions.logoutCompleted, () =>
    Mappings.assign(sessionInitialState, { loaded: true })
  ),
  on(SessionActions.userEdit, (state, action) => {
    if (action.user.type === UserType.Manager) {
      return Mappings.assign(state, {
        manager: Mappings.assign(state.manager, action.user),
      });
    }
    return Mappings.assign(state, {
      entities: Mappings.assign(state.entities, {
        [action.user.id]: Mappings.assign(
          state.entities[action.user.id],
          action.user
        ),
      }),
    });
  }),
  on(SessionActions.selectTeam, (state, { id }) =>
    changePagerReduce(
      state,
      {
        pagerId: 'managed',
        payload: {
          maxCount: null,
          search: new UserSearchModel({
            realtion: UserRelationType.managed,
            teamIds: [id],
          }),
        },
        type: '',
      },
      state.initialPager
    )
  ),
  on(SessionActions.emptySelectedUser, (state) =>
    Mappings.assign(state, { selectedId: null, token: null })
  ),
  on(SessionActions.selectTeam, (state, { id }) =>
    Mappings.assign(state, { teamId: id })
  )
);
