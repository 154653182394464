import { Expose, Transform } from 'class-transformer';

import { WebsocketAction } from './websocket-action';
import { WebsocketMessageType } from './websocket-message-type';
import { WebsocketTarget } from './websocket-target';

export class WebsocketChanges {
  @Expose()
  @Transform(() => WebsocketMessageType.Changes)
  readonly type = WebsocketMessageType.Changes;
  @Expose()
  target: WebsocketTarget;
  @Expose()
  action: WebsocketAction;
  @Expose()
  id: number;
  @Expose()
  scope?: WebsocketTarget;
}
