import { Exclude, Expose, Type } from 'class-transformer';

import { ResponseStatus } from './ResponseStatus';

export class ResponseContainer<T> {
  @Expose()
  @Type(() => ResponseStatus)
  status: ResponseStatus;

  @Expose()
  @Type((options) => (options.newObject as ResponseContainer<T>).type)
  values: T;

  @Exclude()
  private type: new (...args: unknown[]) => T;

  constructor(testType: T | (new (...args: unknown[]) => T)) {
    if (testType instanceof Function) {
      this.type = testType;
      this.values = new testType();
    } else {
      this.values = testType;
    }
  }
}
