import { Expose } from 'class-transformer';

import { IdNameModel } from '../../common/models/id-name.model';

export class GenreModel extends IdNameModel {
  @Expose()
  id: number;

  @Expose()
  name: string;

  @Expose()
  img?: string;
}
