import { PagerEntity } from '../../../common/models/PagerEntity';
import { createPagerReducer } from '../../../tools/reducer-helper/create-pager-reducer.factory';
import { IHasPagerEntities } from '../../../tools/reducer-helper/model/has-pager-entities';
import { CompanyDetailsModel } from '../../models/company-details.model';
import { CompanySearchModel } from '../../models/company-search.model';
import { companyAction } from '../action/company.actions';

export type CompanyState = IHasPagerEntities<
  CompanyDetailsModel,
  CompanySearchModel
>;
const initialPager = new PagerEntity<CompanyDetailsModel, CompanySearchModel>({
  id: 'default',
  pages: {},
  selectedPageNum: 1,
  maxCount: null,
  limit: 50,
  asc: false,
  orderBy: 'id',
  search: new CompanySearchModel({}),
});

const companyInitialState: CompanyState = {
  entities: {},
  ids: [],
  initialPager: initialPager,
  pagers: {},
  pagerIds: [],
  selectedId: null,
};

export const companyReducer = createPagerReducer({
  details: CompanyDetailsModel,
  filter: CompanySearchModel,
  initialState: companyInitialState,
  actions: companyAction,
  source: 'Company',
});
