import { makeEnvironmentProviders } from '@angular/core';

import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { SubGenreService } from '../services/sub-genre.service';
import { SubGenreServiceAbstract } from '../services/sub-genre.service.abstract';
import { SubGenreEffects } from '../store/effect';
import { subGenreReducer } from '../store/reducer';

export const provideSubGenreState = () =>
  makeEnvironmentProviders([
    provideState('sub-genre', subGenreReducer),
    provideEffects([SubGenreEffects]),
    { provide: SubGenreServiceAbstract, useClass: SubGenreService },
  ]);
