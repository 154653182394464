import { Expose } from 'class-transformer';

import { SocialType } from '../../social/models/social-type';

export class CampaignV2CreateModel {
  @Expose()
  name: string;
  @Expose()
  songId: number;
  @Expose()
  description: string;
  @Expose()
  descriptionHtml?: string;
  @Expose()
  referenceUrl: string[];
  @Expose()
  mandatoryBriefing: boolean;
  @Expose()
  platform: SocialType;
}
