import { Injectable, inject } from '@angular/core';

import { map } from 'rxjs/internal/operators/map';

import { createEffect, ofType } from '@ngrx/effects';

import { SessionActions } from '../../../session/store/actions/session.actions';
import { PagerEffects } from '../../../tools/reducer-helper/pager-effects';
import { CitySearchModel } from '../../models/city-search.model';
import { CityModel } from '../../models/city.model';
import { CityServiceAbstract } from '../../services/city.service.abstract';
import { cityAction } from '../action/city.actions';
import { citySelector } from '../selector/city.selector';

@Injectable()
export class CityEffects extends PagerEffects<
  'City',
  CityModel,
  CitySearchModel,
  null,
  null
> {
  resetLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.logoutCompleted),
      map(() =>
        cityAction.changeAllPager({
          matcher: () => true,
          payload: { maxCount: null },
        })
      )
    )
  );

  constructor() {
    super(cityAction, citySelector, inject(CityServiceAbstract));
  }
}
