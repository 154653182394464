import { Expose, Transform } from 'class-transformer';

import { Identifier } from '../../tools/reducer-helper/model/identifier';

export class SocialLabelModel {
  @Expose()
  labelId: Identifier;

  @Expose()
  @Transform(({ value }) => value ?? null)
  value: number;
}
