import { Expose } from 'class-transformer';

import { Identifier } from '../../tools/reducer-helper/model/identifier';

export class SettingsNotificationGroupDetailsModel {
  @Expose()
  id: Identifier;
  @Expose()
  name: string;
  @Expose()
  pushSubscribed: boolean;
  @Expose()
  mailSubscribed: boolean;
}
