import { PagerEntity } from '../../../common/models/PagerEntity';
import { createPagerReducer } from '../../../tools/reducer-helper/create-pager-reducer.factory';
import { IHasPagerEntities } from '../../../tools/reducer-helper/model/has-pager-entities';
import { DealDetailsModel } from '../../models/deal-details.model';
import { DealSearchModel } from '../../models/deal-search.model';
import { dealAction } from '../action/deal.actions';

export type DealState = IHasPagerEntities<DealDetailsModel, DealSearchModel>;
const initialPager = new PagerEntity<DealDetailsModel, DealSearchModel>({
  id: 'default',
  pages: {},
  selectedPageNum: 1,
  maxCount: null,
  limit: 50,
  asc: false,
  orderBy: 'id',
  search: new DealSearchModel({
    search: '',
  }),
});

const dealInitialState: DealState = {
  entities: {},
  ids: [],
  initialPager: initialPager,
  pagers: {},
  pagerIds: [],
  selectedId: null,
};

export const dealReducer = createPagerReducer({
  details: DealDetailsModel,
  filter: DealSearchModel,
  initialState: dealInitialState,
  actions: dealAction,
  source: 'Deal',
});
