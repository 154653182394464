import { Observable } from 'rxjs/internal/Observable';

import { ResponseContainer } from '../../common/models/ResponseContainer';
import { PagerServiceAbstract } from '../../tools/reducer-helper/pager-service';
import { CountryModel } from '../models';
import { CountrySearchModel } from '../models/country-search.model';

export abstract class CountryServiceAbstract extends PagerServiceAbstract<
  CountryModel,
  CountrySearchModel,
  undefined,
  undefined
> {
  create(): Observable<ResponseContainer<CountryModel>> {
    throw new Error('Method not supported');
  }
  edit(): Observable<ResponseContainer<CountryModel>> {
    throw new Error('Method not supported');
  }
}
