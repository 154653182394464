import { HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { Observable } from 'rxjs';

import { classToPlain, instanceToPlain } from 'class-transformer';

import { ResponseContainer } from '../../common/models/ResponseContainer';
import { API_URL } from '../../common/tokens/api-url';
import { ENVIRONMENT } from '../../common/tokens/environment';
import { MangopayRequestUrlModel } from '../../mangopay/models/mangopay-request-url.model';
import { MangopayUrlModel } from '../../mangopay/models/mangopay-url.model';
import { toResponse } from '../../tools/map';
import { CampaignV2DetailsModel } from '../models/campaign-v2-details.model';
import { CampaignV2SumModel } from '../models/campaign-v2-sum.model';
import { CampaignV2SummaryPreviewParamsModel } from '../models/campaign-v2-summary-preview.params.model';
import { CampaignV2TargetModel } from '../models/campaign-v2-target.model';
import { CampaignV2ServiceAbstract } from './campaign-v2.service.abstract';

@Injectable()
export class CampaignV2Service extends CampaignV2ServiceAbstract {
  url = `${inject(API_URL)}campaign-v2`;
  private environment = inject(ENVIRONMENT);
  protected tartgetUrl = `${this.url}/target`;
  protected payoutUrl = `${this.url}/payin`;
  protected payoutFromWalletUrl = `${this.url}/payin/wallet`;
  protected summaryPreviewUrl = `${this.url}/summary-preview`;

  constructor() {
    super();
  }

  setTarget(
    model: CampaignV2TargetModel,
    id: number
  ): Observable<ResponseContainer<CampaignV2DetailsModel>> {
    const domain = instanceToPlain(model);
    return this.http
      .put(`${this.tartgetUrl}/${id}`, domain)
      .pipe(toResponse(CampaignV2DetailsModel));
  }

  getPayoutUrl(
    model: MangopayRequestUrlModel,
    id: number
  ): Observable<ResponseContainer<MangopayUrlModel>> {
    let domain = classToPlain(model);
    if (this.environment.hybrid) {
      domain = { ...domain, platform: 3 };
    }
    return this.http
      .post(`${this.payoutUrl}/${id}`, domain)
      .pipe(toResponse(MangopayUrlModel));
  }
  payFromWallet(model: MangopayRequestUrlModel, id: number) {
    const domain = classToPlain(model);
    let params: HttpParams;
    if (this.environment.hybrid) {
      params = new HttpParams({ fromObject: { platform: 3 } });
    }
    return this.http
      .post(`${this.payoutFromWalletUrl}/${id}`, domain, { params })
      .pipe(toResponse(MangopayUrlModel));
  }

  getSummaryPreview(
    model: CampaignV2SummaryPreviewParamsModel
  ): Observable<ResponseContainer<CampaignV2SumModel>> {
    const body = instanceToPlain(model);
    return this.http
      .post(this.summaryPreviewUrl, body)
      .pipe(toResponse(CampaignV2SumModel));
  }
}
