import { Observable } from 'rxjs';

import { Identifier } from '../../tools/reducer-helper/model/identifier';
import { PagerServiceAbstract } from '../../tools/reducer-helper/pager-service';
import { ManagerInvitationCreateModel } from '../models/manager-invitation-create.model';
import { ManagerInvitationDetailsModel } from '../models/manager-invitation-details.model';
import { ManagerInvitationEditModel } from '../models/manager-invitation-edit.model';
import { ManagerInvitationSearchModel } from '../models/manager-invitation-search.model';
import { ResponseContainer } from '../../common/models/ResponseContainer';


export abstract class ManagerInvitationServiceAbstract extends PagerServiceAbstract<
  ManagerInvitationDetailsModel,
  ManagerInvitationSearchModel,
  ManagerInvitationCreateModel,
  ManagerInvitationEditModel
> {
  abstract resend(id: Identifier): Observable<ResponseContainer<null>>;
}
