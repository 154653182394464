import { Expose, Transform } from 'class-transformer';

import { IdNameModel } from '../../common/models/id-name.model';

export class CountryModel extends IdNameModel {
  @Expose()
  id: number;

  @Expose()
  name: string;

  @Expose({ name: 'callingcode' })
  @Transform(({ value }) => (`${value}`.startsWith('+') ? value : `+${value}`))
  code?: string;

  @Expose({ name: 'iso3166_2' })
  iso?: string;

  @Expose()
  isEU?: boolean;

  @Expose()
  @Transform(({ obj }) => obj.iso3166_2 === 'DE')
  isDE?: boolean;
}
