import { Injectable, inject } from '@angular/core';

import { map } from 'rxjs/internal/operators/map';

import { createEffect, ofType } from '@ngrx/effects';

import { SessionActions } from '../../../session/store/actions/session.actions';
import { PagerEffects } from '../../../tools/reducer-helper/pager-effects';
import { SpotifyTrackCreateModel } from '../../models/spotify-track-create.model';
import { SpotifyTrackDetailsModel } from '../../models/spotify-track-details.model';
import { SpotifyTrackEditModel } from '../../models/spotify-track-edit.model';
import { SpotifyTrackSearchModel } from '../../models/spotify-track-search.model';
import { SpotifyTrackServiceAbstract } from '../../services/spotify-track.service.abstract';
import { spotifyTrackAction } from '../action/spotify-track.actions';
import { spotifyTrackSelector } from '../selector/spotify-track.selector';

@Injectable()
export class SpotifyTrackEffects extends PagerEffects<
  'SpotifyTrack',
  SpotifyTrackDetailsModel,
  SpotifyTrackSearchModel,
  SpotifyTrackEditModel,
  SpotifyTrackCreateModel
> {
  resetLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.logoutCompleted, SessionActions.selectUserSuccess),
      map(() =>
        spotifyTrackAction.changeAllPager({
          matcher: () => true,
          payload: { maxCount: null },
        })
      )
    )
  );

  constructor() {
    super(
      spotifyTrackAction,
      spotifyTrackSelector,
      inject(SpotifyTrackServiceAbstract)
    );
  }
}
