import { Expose, Transform } from 'class-transformer';

import { WebsocketMessageType } from './websocket-message-type';

export class WebsocketSiteUpdated {
  @Expose()
  @Transform(() => WebsocketMessageType.SiteUpdated)
  readonly type = WebsocketMessageType.SiteUpdated;

  constructor(init?: WebsocketSiteUpdated) {
    if (init) {
      Object.assign(this, init);
    }
  }
}
