import { createSelector } from '@ngrx/store';

import { memoize } from '../../../tools/memoize';
import { createPagerSelectors } from '../../../tools/reducer-helper/create-pager-selector.factory';
import { SocialBlockDetailsModel } from '../../models/social-block-details.model';
import { SocialBlockSearchModel } from '../../models/social-block-search.model';
import { SocialBlockState } from '../reducer/social-block.reducer';

const createSocialBlockSelector = () => {
  const pagerSelector = createPagerSelectors<
    SocialBlockDetailsModel,
    SocialBlockSearchModel,
    SocialBlockState
  >('socialBlock');

  const selectAllFlat = memoize((pagerId = 'default') =>
    createSelector(pagerSelector.selectPagerItems(pagerId), (items) =>
      items.map(({ name }) => name)
    )
  );

  return { ...pagerSelector, selectAllFlat };
};

export const socialBlockSelector = createSocialBlockSelector();
