import { Injectable, inject } from '@angular/core';

import { InvoiceServiceAbstract } from './invoice.service.abstract';
import { InvoiceDetailsModel } from '../models/invoice-details.model';
import { InvoiceSearchModel } from '../models/invoice-search.model';
import { API_URL } from '../../common/tokens/api-url';


@Injectable()
export class InvoiceService extends InvoiceServiceAbstract {
  public readonly detailsModel = InvoiceDetailsModel;
  public readonly filterModel = InvoiceSearchModel;
  public readonly createModel = null;
  public readonly editModel = null;
  public readonly url = `${inject(API_URL)}invoice`;
  protected isManagerResource = true;
  constructor() {
    super();
  }
}
