import { Expose } from 'class-transformer';

export class ManagerEditModel {
  @Expose()
  firstName: string;
  @Expose()
  lastName: string;
  @Expose()
  postition: string;

  constructor(init?: Partial<ManagerEditModel>) {
    if (init) {
      Object.assign(this, init);
    }
  }
}
