import { Expose } from 'class-transformer';

import { InvoiceType } from './invoice-type';

export class InvoiceDetailsModel {
  @Expose({ name: 'invoiceId' })
  id: number;

  @Expose({ name: 'invoiceType' })
  type: InvoiceType;

  @Expose()
  campaignId?: number; // artist only
  @Expose()
  transactionId?: number; // creator only

  @Expose()
  inserted: string;
}
