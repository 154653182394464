import { makeEnvironmentProviders } from '@angular/core';

import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { SystemMessageService } from '../providers/system-message.service';
import { SystemMessageServiceAbstract } from '../providers/system-message.service.abstract';
import { SystemMessageLiveChangeEffects } from '../store/system-message-livechange.effects';
import { SystemMessageEffects } from '../store/system-message.effects';
import { systemMessageReducer } from '../store/system-message.reducer';

export const provideSystemMessageState = () =>
  makeEnvironmentProviders([
    provideState('systemMessage', systemMessageReducer),
    provideEffects([SystemMessageEffects, SystemMessageLiveChangeEffects]),
    { provide: SystemMessageServiceAbstract, useClass: SystemMessageService },
  ]);
