import { inject } from '@angular/core';
import { Router } from '@angular/router';

import { filter, map, tap } from 'rxjs';

import { createEffect, ofType } from '@ngrx/effects';

import { SessionActions } from '../../session/store/actions/session.actions';
import { PagerEffects } from '../../tools/reducer-helper/pager-effects';
import { TeamCreateModel } from '../models/team-create.model';
import { TeamDetailsModel } from '../models/team-details.model';
import { TeamEditModel } from '../models/team-edit.model';
import { TeamSearchModel } from '../models/team-search.model';
import { TeamServiceAbstract } from '../providers/team.service.abstract';
import { teamAction } from './team.action';
import { teamSelector } from './team.selector';

export class TeamEffects extends PagerEffects<
  'Team',
  TeamDetailsModel,
  TeamSearchModel,
  TeamEditModel,
  TeamCreateModel
> {
  router = inject(Router);

  resetLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.logoutCompleted),
      map(() => teamAction.resetState())
    )
  );

  teamChange$ = createEffect(
    () =>
      this.store.select(teamSelector.selectScope('default')).pipe(
        filter(
          ({ ids, pager: { maxCount } }) =>
            ids.length === 0 && maxCount !== null
        ),
        tap(() => this.router.navigateByUrl('/registration/no-team'))
      ),
    {
      dispatch: false,
    }
  );

  constructor() {
    super(teamAction, teamSelector, inject(TeamServiceAbstract));
  }
}
