import { PagerEntity } from '../../../common/models/PagerEntity';
import { IHasPagerEntities } from '../model/has-pager-entities';
import { IHasId } from '../model/i-has-id';
import { ISelectPageAction } from '../model/select-page-action';
import { patchPager } from './patch-pager';

export const selectPageReduce = <T extends IHasId, Z, U extends IHasPagerEntities<T, Z>>(
  state: U,
  action: ISelectPageAction,
  initialPager: PagerEntity<T, Z>
): U => patchPager(state, { selectedPageNum: action.selectedPageNum }, initialPager, action.pagerId);
