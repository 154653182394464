import { Expose, Transform, Type, plainToInstance } from 'class-transformer';

import { CurrencyV2Model } from '../../common/models/currency-v2.model';
import { SocialType } from '../../social/models/social-type';
import { CampaignV2BriefingModel } from './campaign-v2-briefing.model';
import { CampaignV2HistoryModel } from './campaign-v2-history.model';
import { CampaignV2KpiModel } from './campaign-v2-kpi.model';
import { CampaignV2Status } from './campaign-v2-status';

export class CampaignV2DetailsModel {
  @Expose()
  id: number;
  @Expose()
  name: string;
  @Expose()
  artistId: number;
  @Expose()
  songId: number;
  @Expose()
  @Type(() => CurrencyV2Model)
  price?: CurrencyV2Model; // creator only
  @Expose()
  @Type(() => CurrencyV2Model)
  budget?: CurrencyV2Model; // artist only
  @Expose()
  @Type(() => CurrencyV2Model)
  remainingBudget?: CurrencyV2Model; // artist only
  @Expose()
  @Transform(({ obj }) => obj.platform ?? obj.platforms?.[0])
  platform: SocialType;

  @Expose()
  status: CampaignV2Status;

  @Expose()
  startTime?: string; // artist only
  @Expose()
  endTime?: string; // artist only

  @Expose()
  inserted: string;
  @Expose()
  deleted: boolean;

  @Expose()
  @Type(() => CampaignV2KpiModel)
  kpi?: CampaignV2KpiModel; // artist only

  @Expose()
  @Type(() => CampaignV2HistoryModel)
  history?: CampaignV2HistoryModel[]; // artist only

  @Expose()
  @Transform(({ obj }) =>
    plainToInstance(
      CampaignV2BriefingModel,
      obj.history ? obj.history[0]?.briefing : obj.briefing
    )
  )
  @Type(() => CampaignV2BriefingModel)
  briefing: CampaignV2BriefingModel;

  @Expose()
  countryIds: number[];

  @Expose()
  po?: string;

  @Expose()
  filterPriceMultiplierMin: number;
  @Expose()
  filterPriceMultiplierMax: number;
  @Expose()
  filterViewScoreMin: number;
  @Expose()
  filterViewScoreMax: number;
  @Expose()
  filterViewScoreAuto: boolean;
  @Expose()
  filterPriceMultiplierAuto: boolean;

  @Expose()
  targetMinViewers?: number;
}
