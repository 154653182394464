import { createPagerActionGroup } from '../../tools/reducer-helper/create-pager-action-group.factory';
import { ManagerDealDetailsModel } from '../models/manager-deal-details.model';
import { ManagerDealSearchModel } from '../models/manager-deal-search.model';


export const managerDealAction = createPagerActionGroup<'ManagerDeal', ManagerDealDetailsModel, ManagerDealSearchModel>(
  {
    source: 'ManagerDeal',
  }
);
