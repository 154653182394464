import { Expose } from 'class-transformer';

export class SongSearchModel {
  @Expose()
  search?: string;

  @Expose()
  ids: number[];

  constructor(ent: Partial<SongSearchModel>) {
    Object.assign(this, ent);
  }
}
