import { makeEnvironmentProviders } from '@angular/core';

import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { TransactionService } from '../providers/transaction.service';
import { TransactionServiceAbstract } from '../providers/transaction.service.abstract';
import { TransactionLiveChangeEffects } from '../store/transaction-livechange.effects';
import { TransactionEffects } from '../store/transaction.effects';
import { transactionReducer } from '../store/transaction.reducer';

export const provideTransactionState = () =>
  makeEnvironmentProviders([
    provideState('transaction', transactionReducer),
    provideEffects([TransactionEffects, TransactionLiveChangeEffects]),
    { provide: TransactionServiceAbstract, useClass: TransactionService },
  ]);
