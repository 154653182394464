import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';

import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { BankAccountService } from '../providers/bank-account.service';
import { BankAccountServiceAbstract } from '../providers/bank-account.service.abstract';
import { BankAccountEffects, bankAccountReducer } from '../store';
import { BankAccountLiveChangeEffects } from '../store/bank-account-livechange.effects';

export const provideBankAccountState = (): EnvironmentProviders =>
  makeEnvironmentProviders([
    provideState('bankAccount', bankAccountReducer),
    provideEffects([BankAccountEffects, BankAccountLiveChangeEffects]),
    { provide: BankAccountServiceAbstract, useClass: BankAccountService },
  ]);
