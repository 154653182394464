import { createPagerActionGroup } from '../../../tools/reducer-helper/create-pager-action-group.factory';
import { SpotifyArtistDetailsModel } from '../../models/spotify-artist-details.model';
import { SpotifyArtistSearchModel } from '../../models/spotify-artist-search.model';

export const spotifyArtistAction = createPagerActionGroup<
  'SpotifyArtist',
  SpotifyArtistDetailsModel,
  SpotifyArtistSearchModel
>({
  source: 'SpotifyArtist',
});
