import { Expose } from 'class-transformer';

export class WalletBankingAliasModel {
  @Expose()
  id: number;
  @Expose()
  name: string;
  @Expose()
  bic: string;
  @Expose()
  iban: string;
  @Expose()
  isActive: boolean;
}
