import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';

import { Observable, defer, map, of, switchMap } from 'rxjs';

import { Storage } from '@ionic/storage';
import { plainToClassFromExist } from 'class-transformer';


import { InviteDetailsModel } from '../model/invite-details.model';
import { InviteServiceAbstract } from './inivite.service.abstract';
import { API_URL } from '../../common/tokens/api-url';
import { ResponseContainer } from '../../common/models/ResponseContainer';

const storagekey = 'invite';

export class InviteService implements InviteServiceAbstract {
  private readonly http = inject(HttpClient);
  private readonly storage = inject(Storage);
  private readonly inviteUrl = `${inject(API_URL)}management/manager/invite`;
  private readonly joinUrl = `${inject(API_URL)}management/join`;

  getInvite(inviteId: string): Observable<ResponseContainer<InviteDetailsModel>> {
    return this.http
      .get(`${this.inviteUrl}/${inviteId}`)
      .pipe(map((resp) => plainToClassFromExist(new ResponseContainer(InviteDetailsModel), resp)));
  }

  accept(inviteId: string): Observable<ResponseContainer<null>> {
    return this.http
      .post(`${this.joinUrl}/${inviteId}`, {})
      .pipe(map((resp) => plainToClassFromExist(new ResponseContainer(null), resp)));
  }

  decline(inviteId: string): Observable<ResponseContainer<null>> {
    return this.http
      .delete(`${this.joinUrl}/${inviteId}`, {})
      .pipe(map((resp) => plainToClassFromExist(new ResponseContainer(null), resp)));
  }
  saveInvite(inviteId: string): Observable<void> {
    return defer(() => this.storage.set(storagekey, inviteId));
  }
  clearInvite(): Observable<void> {
    return defer(() => this.storage.remove(storagekey));
  }

  getStoredInviteId() {
    return defer(() => this.storage.get(storagekey));
  }

  getStoredInvite() {
    return this.getStoredInviteId().pipe(
      switchMap((inviteId) => {
        if (inviteId) {
          return this.getInvite(inviteId);
        }
        return of(null);
      })
    );
  }
}
