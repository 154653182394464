import { createPagerActionGroup } from '../../../tools/reducer-helper/create-pager-action-group.factory';
import { CampaignV2DetailsModel } from '../../models/campaign-v2-details.model';
import { CampaignV2SearchModel } from '../../models/campaign-v2-search.model';

export const campaignV2Action = createPagerActionGroup<
  'CampaignV2',
  CampaignV2DetailsModel,
  CampaignV2SearchModel
>({
  source: 'CampaignV2',
});
