import { Injectable, inject } from '@angular/core';

import { map } from 'rxjs/internal/operators/map';

import { createEffect, ofType } from '@ngrx/effects';

import { hasDealInStore } from '../../../deal/guards/deal-exits.guard';
import { DealSearchModel } from '../../../deal/models/deal-search.model';
import { dealAction } from '../../../deal/store/action';
import { SessionActions } from '../../../session/store/actions/session.actions';
import { PagerEffects } from '../../../tools/reducer-helper/pager-effects';
import { hasUserInStore } from '../../../user/guards/user-exist.guard';
import { UserSearchModel } from '../../../user/models/user-search.model';
import { userAction } from '../../../user/store/action';
import { WebsocketTarget } from '../../../websocket/models';
import { TicketDealCreateModel } from '../../models/ticket-deal-create.model';
import { TicketDetailsModel } from '../../models/ticket-details.model';
import { TicketSearchModel } from '../../models/ticket-search.model';
import { TicketServiceAbstract } from '../../services/providers/ticket.service.abstract';
import { ticketAction } from '../action/ticket.actions';
import { ticketSelector } from '../selector/ticket.selector';

@Injectable()
export class TicketEffects extends PagerEffects<
  'Ticket',
  TicketDetailsModel,
  TicketSearchModel,
  unknown,
  TicketDealCreateModel
> {
  protected websocketTarget = WebsocketTarget.Ticket;

  resetLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.logoutCompleted, SessionActions.selectUserSuccess),
      map(() =>
        ticketAction.changeAllPager({
          matcher: () => true,
          payload: { maxCount: null },
        })
      )
    )
  );

  resolveUser$ = createEffect(
    () =>
      this.resolveEntities(['userId'], userAction, UserSearchModel, (id) =>
        hasUserInStore(id, this.store).pipe(map((exist) => ({ exist, id })))
      ),
    { dispatch: false }
  );

  resolveDeal$ = createEffect(
    () =>
      this.resolveEntities(['dealId'], dealAction, DealSearchModel, (id) =>
        hasDealInStore(id, this.store).pipe(map((exist) => ({ exist, id })))
      ),
    { dispatch: false }
  );

  constructor() {
    super(ticketAction, ticketSelector, inject(TicketServiceAbstract));
  }
}
