import { Expose } from 'class-transformer';

import { Identifier } from '../../tools/reducer-helper/model/identifier';

export class InstagramUserModel {
  @Expose()
  id: Identifier;
  @Expose()
  pageName: string;
  @Expose()
  username: string;
  @Expose()
  name: string;
  @Expose()
  followersCount: number;
  @Expose()
  mediaCount: number;
  @Expose()
  profilePictureUrl: string;
}
