import { Expose, Transform } from 'class-transformer';

import { WebsocketMessageType } from './websocket-message-type';

export class WebsocketIdentify {
  @Expose()
  @Transform(() => WebsocketMessageType.WebsocketIdentify)
  readonly type = WebsocketMessageType.WebsocketIdentify;
  @Expose({ name: 'id' })
  token: string;
}
