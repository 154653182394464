import { Expose, Type } from 'class-transformer';

import { CurrencyV2Model } from '../../common/models/currency-v2.model';
import { Identifier } from '../../tools/reducer-helper/model/identifier';
import { WalletType } from './wallet-type';
import { WalletBankingAliasModel } from './wallet-banking-aliast.model';

export class WalletDetailsModel {
  @Expose()
  id: Identifier;
  @Expose()
  payoutDate: string;
  @Expose()
  @Type(() => CurrencyV2Model)
  balance: CurrencyV2Model;
  @Expose()
  type: WalletType;
  @Expose()
  @Type(() => WalletBankingAliasModel)
  bankingAlias?: WalletBankingAliasModel;
}
