import { createSelector } from '@ngrx/store';

import { getTiktokSoundUrlRegex } from '@songpush/validators/tools/tiktok-sound-url-regex';

import { Mappings } from '../../../tools/mapping/mappings';
import { createPagerSelectors } from '../../../tools/reducer-helper/create-pager-selector.factory';
import { SongDetailsModel } from '../../models/song-details.model';
import { SongSearchModel } from '../../models/song-search.model';
import { SongState } from '../reducer/song.reducer';

const getSongIdType = (id: number | string) => {
  const tiktokRegex = getTiktokSoundUrlRegex();
  if (tiktokRegex.test(`${id}`)) {
    return 'tiktok';
  }
  if (isNaN(id as number)) {
    return 'spotify';
  }
  return 'internal';
};

const createSongSelector = () => {
  const pagerSelector = createPagerSelectors<
    SongDetailsModel,
    SongSearchModel,
    SongState
  >('song');

  const selectBySpotifyId = (spotifyId: string) =>
    createSelector(pagerSelector.selectAll, (list) =>
      list.find((item) => item.spotifyTrackId === spotifyId)
    );

  const selectByTiktokUrl = (tiktokUrl: string) =>
    createSelector(pagerSelector.selectAll, (list) =>
      list.find((item) => item.tiktokUrl === tiktokUrl)
    );

  const selectByUnkownId = (id: number | string) => {
    switch (getSongIdType(id)) {
      case 'internal':
        return pagerSelector.selectItem(id as number);
      case 'spotify':
        return selectBySpotifyId(id as string);
      case 'tiktok':
        return selectByTiktokUrl(Mappings.getBaseUrl(id as string));
    }
  };
  return { ...pagerSelector, selectByUnkownId };
};

export const songSelector = createSongSelector();
