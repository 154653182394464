import { PagerEntity } from '../../../common/models/PagerEntity';
import { createPagerReducer } from '../../../tools/reducer-helper/create-pager-reducer.factory';
import { IHasPagerEntities } from '../../../tools/reducer-helper/model/has-pager-entities';
import { SpotifyArtistDetailsModel } from '../../models/spotify-artist-details.model';
import { SpotifyArtistSearchModel } from '../../models/spotify-artist-search.model';
import { spotifyArtistAction } from '../action/spotify-artist.actions';

export type SpotifyArtistState = IHasPagerEntities<
  SpotifyArtistDetailsModel,
  SpotifyArtistSearchModel
>;
const initialPager = new PagerEntity<
  SpotifyArtistDetailsModel,
  SpotifyArtistSearchModel
>({
  id: 'default',
  pages: {},
  selectedPageNum: 1,
  maxCount: null,
  limit: 50,
  asc: false,
  orderBy: 'id',
  search: new SpotifyArtistSearchModel({}),
});

const spotifyArtistInitialState: SpotifyArtistState = {
  entities: {},
  ids: [],
  initialPager: initialPager,
  pagers: {},
  pagerIds: [],
  selectedId: null,
};

export const spotifyArtistReducer = createPagerReducer({
  details: SpotifyArtistDetailsModel,
  filter: SpotifyArtistSearchModel,
  initialState: spotifyArtistInitialState,
  actions: spotifyArtistAction,
  source: 'SpotifyArtist',
});
