/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Animation, createAnimation } from '@ionic/angular/standalone';

export const emptyTransitionAnimation = (navEl: HTMLElement, opts?: any): Animation => {
  const rootAnimation = createAnimation();
  rootAnimation
    .addElement(navEl)
    .duration(opts.duration ?? 0)
    .easing(opts.easing)
    .keyframes([
      { offset: 0, opacity: '0' },
      { offset: 1, opacity: '1' },
    ])
    .fill('both')
    .beforeRemoveClass('ion-page-invisible');

  return rootAnimation;
};
