import { DatePipe } from '@angular/common';
import { Injectable, OnDestroy, inject } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { Store } from '@ngrx/store';

import { analyticsAction } from '../../analytics/store/analytics.actions';
import {
  CreateAnalyticsCampaignInfoParams,
  createAnalyticsCampaignInfo,
} from '../../analytics/tools/create-analytics-campaign-info';
import { HOUR_IN_MS, SECOND_IN_MS } from '../../tools/timeconst/timeconst';

const initialState: AudioStreamState = {
  playing: false,
  readableCurrentTime: '',
  readableDuration: '',
  duration: undefined,
  currentTime: undefined,
  canplay: false,
  error: false,
  url: undefined,
};

@Injectable()
export class AudioService implements OnDestroy {
  private datePipe = inject(DatePipe);
  private store = inject(Store);

  private state$ = new BehaviorSubject(initialState);

  private player = new Audio();

  private analyticsInfo?: CreateAnalyticsCampaignInfoParams;

  audioEvents = [
    'ended',
    'error',
    'play',
    'playing',
    'pause',
    'timeupdate',
    'canplay',
    'loadedmetadata',
    'loadstart',
  ];

  eventHandler = (event: Event) =>
    this.state$.next(this.reduceState(this.state$.value, event));

  constructor() {
    this.addEvents(this.player, this.audioEvents, this.eventHandler);
  }

  ngOnDestroy(): void {
    this.removeEvents(this.player, this.audioEvents, this.eventHandler);
    this.state$.complete();
    this.player.remove();
  }

  private addEvents(obj, events, handler) {
    events.forEach((event) => {
      obj.addEventListener(event, handler);
    });
  }

  private removeEvents(obj, events, handler) {
    events.forEach((event) => {
      obj.removeEventListener(event, handler);
    });
  }

  play(
    url: string,
    loop?: boolean,
    analyticsInfo?: CreateAnalyticsCampaignInfoParams
  ) {
    // Play audio
    if (this.player.src !== url) {
      this.analyticsInfo = analyticsInfo;
      this.player.src = url;
      this.player.loop = !!loop;
      this.player.load();
    }
    this.player.play();

    if (analyticsInfo) {
      this.store.dispatch(
        analyticsAction.playerInteraction({
          ...createAnalyticsCampaignInfo({
            campaign: analyticsInfo.campaign,
            song: analyticsInfo.song,
            maxCount: 0,
            position: 0,
          }),
          action: 'start',
          playTime: this.player.currentTime,
        })
      );
    }
  }

  pause() {
    this.player.pause();
    if (this.analyticsInfo) {
      this.store.dispatch(
        analyticsAction.playerInteraction({
          ...createAnalyticsCampaignInfo({
            campaign: this.analyticsInfo.campaign,
            song: this.analyticsInfo.song,
            maxCount: 0,
            position: 0,
          }),
          action: 'stop',
          playTime: this.player.currentTime,
        })
      );
    }
  }

  stop() {
    this.player.pause();
    this.player.currentTime = 0;
    this.state$.next(this.reduceState(this.state$.value, { type: 'reset' }));
    if (this.analyticsInfo) {
      this.store.dispatch(
        analyticsAction.playerInteraction({
          ...createAnalyticsCampaignInfo({
            campaign: this.analyticsInfo.campaign,
            song: this.analyticsInfo.song,
            maxCount: 0,
            position: 0,
          }),
          action: 'stop',
          playTime: this.player.currentTime,
        })
      );
      this.analyticsInfo = undefined;
    }
  }

  seekTo(seconds: number) {
    this.player.currentTime = seconds;
  }

  formatTime(time: number, format = 'HH:mm:ss') {
    return this.datePipe.transform(
      new Date(time * SECOND_IN_MS - HOUR_IN_MS),
      format
    );
  }

  private reduceState(
    currentState: AudioStreamState,
    action: { type: string }
  ): AudioStreamState {
    const url = this.player.src;
    switch (action.type) {
      case 'canplay':
        return {
          ...currentState,
          duration: this.player.duration,
          readableDuration: this.formatTime(this.player.duration),
          canplay: true,
          url,
        };

      case 'playing':
        return {
          ...currentState,
          playing: true,
          url,
        };
      case 'pause':
        return {
          ...currentState,
          playing: false,
          url,
        };
      case 'timeupdate':
        return {
          ...currentState,
          currentTime: this.player.currentTime,
          readableCurrentTime: this.formatTime(this.player.currentTime),
        };
      case 'error':
        return {
          ...initialState,
          error: true,
          url,
        };
      case 'reset':
        if (currentState.url === url) {
          return {
            ...initialState,
            url,
          };
        } else {
          return currentState;
        }
      default:
        return currentState;
    }
  }

  getState(): Observable<AudioStreamState> {
    return this.state$.asObservable();
  }
}

export interface AudioStreamState {
  playing: boolean;
  readableCurrentTime: string;
  readableDuration: string;
  duration: number | undefined;
  currentTime: number | undefined;
  canplay: boolean;
  error: boolean;
  url: string;
}
