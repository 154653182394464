import { PagerEntity } from '../../../common/models/PagerEntity';
import { Mappings } from '../../mapping/mappings';
import { IHasPagerEntities } from '../model/has-pager-entities';
import { IHasId } from '../model/i-has-id';
import { ILoadPageCompleteAction } from '../model/load-page-completed';
import { mergeIds } from './merge-ids';

export const loadPageCompleteReduce = <T extends IHasId, Z, U extends IHasPagerEntities<T, Z>>(
  state: U,
  action: ILoadPageCompleteAction<T>,
  initialPager?: PagerEntity<T, Z>
): U => {
  const newEntities = action.payload.reduce(
    (newEnts, user) => Mappings.assign(newEnts, { [user.id]: user }),
    {} as typeof state.entities
  );
  const ids = action.payload.map(({ id }) => id);
  const pages = Mappings.assign(action.wipe ? {} : state.pagers?.[action.pagerId]?.pages ?? {}, { [action.page]: ids });
  const entities = Mappings.assign(state.entities, newEntities);

  return Mappings.assign(state, {
    entities,
    ids: mergeIds(state.ids, ids),
    pagers: Mappings.assign(state.pagers, {
      [action.pagerId]: Mappings.assign(state.pagers[action.pagerId] || initialPager, {
        pages,
        maxCount: action.maxCount,
      }),
    } as Partial<{ [key: string]: PagerEntity<T, Z> }>),
  } as Partial<U>);
};
