import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { LiveChangeEffects } from '@songpush/core/tools/reducer-helper';
import { WebsocketTarget } from '../../../websocket/models/websocket-target';
import { DealDetailsModel } from '../../models/deal-details.model';
import { DealSearchModel } from '../../models/deal-search.model';
import { dealAction } from '../action';

@Injectable()
export class DealLiveChangeEffects extends LiveChangeEffects<
  'Deal',
  DealDetailsModel,
  DealSearchModel
> {
  protected actions = dealAction;
  protected websocketTarget = WebsocketTarget.Deal;

  protected getWebsocketEditAction = (action) => {
    return [
      this.getWebsocketReloadAllAction(action) as Action,
      this.actions.reloadEntity({
        id: action.id,
      }),
    ];
  };
  protected getWebsocketDeleteAction = (action) => {
    return [
      this.getWebsocketReloadAllAction(action) as Action,
      this.actions.reloadEntity({
        id: action.id,
      }),
    ];
  };
}
