import { createPagerActionGroup } from '../../../tools/reducer-helper/create-pager-action-group.factory';
import { TicketDetailsModel } from '../../models/ticket-details.model';
import { TicketSearchModel } from '../../models/ticket-search.model';

export const ticketAction = createPagerActionGroup<
  'Ticket',
  TicketDetailsModel,
  TicketSearchModel
>({
  source: 'Ticket',
});
