import { createPagerActionGroup } from '../../../tools/reducer-helper/create-pager-action-group.factory';
import { CompanyDetailsModel } from '../../models/company-details.model';
import { CompanySearchModel } from '../../models/company-search.model';

export const companyAction = createPagerActionGroup<
  'Company',
  CompanyDetailsModel,
  CompanySearchModel
>({
  source: 'Company',
});
