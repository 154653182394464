import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { Observable, map } from 'rxjs';

import { plainToClassFromExist } from 'class-transformer';



import { ResponseContainer } from '@songpush/core/common/models';
import { API_URL } from '@songpush/core/common/tokens';

import { EmailServiceAbstract } from './email.service.abstract';

@Injectable()
export class EmailService extends EmailServiceAbstract {
  http = inject(HttpClient);
  apiUrl = inject(API_URL);

  unsubscribeUrl = `${this.apiUrl}/notifications/unsubscribe-mails`;

  unsubscribe(token: string): Observable<ResponseContainer<unknown>> {
    return this.http
      .delete(`${this.unsubscribeUrl}/${token}`)
      .pipe(map((resp) => plainToClassFromExist(new ResponseContainer(null), resp)));
  }
}
