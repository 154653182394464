import { createActionGroup, props } from '@ngrx/store';

import { createPagerActionGroup } from '../../tools/reducer-helper/create-pager-action-group.factory';
import { BankAccountDetailsModel } from '../models/bank-account-details.model';
import { BankAccountMandateModel } from '../models/bank-account-mandate.model';
import { BankAccountSearchModel } from '../models/bank-account-search.model';

const source = 'BankAccount' as const;
export const bankAccountAction = {
  ...createPagerActionGroup<
    typeof source,
    BankAccountDetailsModel,
    BankAccountSearchModel
  >({
    source: source,
  }),
  ...createActionGroup({
    source,
    events: {
      updateMandate: props<{ payload: BankAccountMandateModel }>(),
    },
  }),
};
