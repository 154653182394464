import { Injectable } from '@angular/core';
import { LiveChangeEffects } from '@songpush/core/tools/reducer-helper';
import { WebsocketTarget } from '../../../websocket/models/websocket-target';
import { CampaignV2DetailsModel } from '../../models/campaign-v2-details.model';
import { CampaignV2SearchModel } from '../../models/campaign-v2-search.model';
import { campaignV2Action } from '../action';
@Injectable()
export class CampaignV2LiveChangeEffects extends LiveChangeEffects<
  'CampaignV2',
  CampaignV2DetailsModel,
  CampaignV2SearchModel
> {
  protected actions = campaignV2Action;
  protected websocketTarget = WebsocketTarget.CampaignV2;

  protected getWebsocketCreateAction = () =>
    campaignV2Action.changeAllPager({
      payload: { hasNew: true },
      matcher: () => true,
    });
}
