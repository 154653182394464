import { Injectable } from '@angular/core';

import { Platform } from '@ionic/angular/standalone';
import { Storage } from '@ionic/storage';
import { classToPlain } from 'class-transformer';

import { SettingsLanguage } from '../models/settings-language';
import { SettingsState } from '../store/reducers/settings.reducer';
import { SettingsServiceAbstract } from './settings.service.abstract';

@Injectable()
export class SettingsService extends SettingsServiceAbstract {
  constructor(private storage: Storage, private platform: Platform) {
    super();
  }

  get(): Promise<SettingsState> {
    return this.getFromStorage();
  }

  getDefaultLanguage() {
    const browserLang = navigator.language;
    let lang;
    if (browserLang.toLocaleLowerCase().startsWith('de')) {
      lang = SettingsLanguage.de;
    } else {
      lang = SettingsLanguage.enUS;
    }
    return lang;
  }

  async set(newValue: SettingsState) {
    return this.storage.set('settings', classToPlain(newValue));
  }

  private async getFromStorage(): Promise<SettingsState> {
    await this.platform.ready();

    const settings = await this.storage.get('settings');

    return settings ?? {};
  }
}
