import { Injectable } from '@angular/core';
import { LiveChangeEffects } from '@songpush/core/tools/reducer-helper';
import { WebsocketTarget } from '../../websocket/models/websocket-target';
import { WalletDetailsModel, WalletSearchModel } from '../models';
import { walletAction } from './wallet.action';

@Injectable()
export class WalletLiveChangeEffects extends LiveChangeEffects<
  'Wallet',
  WalletDetailsModel,
  WalletSearchModel
> {
  protected readonly websocketTarget = WebsocketTarget.Wallet;
  protected readonly actions = walletAction;
  protected getWebsocketCreateAction = this.getWebsocketReloadAllAction;
  protected getWebsocketEditAction = this.getWebsocketReloadAllAction;
  protected getWebsocketDeleteAction = this.getWebsocketReloadAllAction;
}
