/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Expose } from 'class-transformer';

export class SpotifyArtistDetailsModel {
  @Expose()
  id: string;
  @Expose()
  name: string;
  @Expose()
  followers: number;
  @Expose()
  photo: string;
}
