import { createReducer, on } from '@ngrx/store';

import { SettingsTheme } from '../../models/settings-theme';
import { SettingsActions } from '../actions';

export interface SettingsState {
  language: string;
  theme: SettingsTheme;
  loaded: boolean;
}

const initState: SettingsState = {
  language: null,
  theme: SettingsTheme.System,
  loaded: false,
};

export const settingsReducer = createReducer(
  initState,
  on(SettingsActions.changeLanguage, (state, { language }) => ({ ...state, language })),
  on(SettingsActions.changeTheme, (state, { theme }) => ({ ...state, theme })),
  on(SettingsActions.loadFromDbCompleted, (prevState, { state }) => ({ ...prevState, ...state, loaded: true }))
);
