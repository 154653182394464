import { PagerEntity } from '../../../common/models/PagerEntity';
import { createPagerReducer } from '../../../tools/reducer-helper/create-pager-reducer.factory';
import { IHasPagerEntities } from '../../../tools/reducer-helper/model/has-pager-entities';
import { CitySearchModel } from '../../models/city-search.model';
import { CityModel } from '../../models/city.model';
import { cityAction } from '../action/city.actions';

export type CityState = IHasPagerEntities<CityModel, CitySearchModel>;
const initialPager = new PagerEntity<CityModel, CitySearchModel>({
  id: 'default',
  pages: {},
  selectedPageNum: 1,
  maxCount: null,
  limit: 50,
  asc: false,
  orderBy: 'id',
  search: new CitySearchModel({
    search: '',
  }),
});

const cityInitialState: CityState = {
  entities: {},
  ids: [],
  initialPager: initialPager,
  pagers: {},
  pagerIds: [],
  selectedId: null,
};

export const cityReducer = createPagerReducer({
  details: CityModel,
  filter: CitySearchModel,
  initialState: cityInitialState,
  actions: cityAction,
  source: 'City',
});
