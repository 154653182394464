import { Expose, Type } from 'class-transformer';

import { SocialModel } from '../../social/models/social.model';
import { Identifier } from '../../tools/reducer-helper/model/identifier';
import { IHasId } from '../../tools/reducer-helper/model/i-has-id';


export class UserPublicModel implements IHasId {
  @Expose()
  id: Identifier;
  @Expose()
  photo?: string;
  @Expose()
  name: string;
  @Expose()
  @Type(() => SocialModel)
  socials?: SocialModel[];
}
