import { PagerEntity } from '../../../common/models/PagerEntity';
import { IChangePagerAction } from '../model/change-pager';
import { IHasPagerEntities } from '../model/has-pager-entities';
import { IHasId } from '../model/i-has-id';
import { patchPager } from './patch-pager';

export const changePagerReduce = <T extends IHasId, Z, U extends IHasPagerEntities<T, Z>>(
  state: U,
  action: IChangePagerAction<T, Z>,
  initialPager: PagerEntity<T, Z>
): U => {
  return patchPager(state, action.payload, initialPager, action.pagerId);
};
