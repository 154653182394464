import { createSelector } from '@ngrx/store';

import { GenreModel } from '../../../genre/models/genre.model';
import { Mappings } from '../../../tools/mapping/mappings';
import { createPagerSelectors } from '../../../tools/reducer-helper/create-pager-selector.factory';
import { SubGenreModel } from '../../models/sub-genre.model';

const createSubGenreSelector = () => {
  const pagerSelector = createPagerSelectors<SubGenreModel, null, null>(
    'sub-genre'
  );

  const selectGroupByGenre = createSelector(pagerSelector.selectAll, (genres) =>
    genres.reduce(
      (group, genre) =>
        Mappings.assign(group, {
          [genre.genreId]: [...(group[genre.genreId] || []), genre],
        }),
      {} as { [key: number]: GenreModel[] }
    )
  );

  return {
    ...pagerSelector,
  };
};

export const subGenreSelector = createPagerSelectors<SubGenreModel, null, null>(
  'sub-genre'
);
