import { makeEnvironmentProviders } from '@angular/core';

import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { UserService } from '../services/user.service';
import { UserServiceAbstract } from '../services/user.service.abstract';
import { UserEffects } from '../store/effect';
import { UserLiveChangeEffects } from '../store/effect/user-livechange.effects';
import { userReducer } from '../store/reducer';

export const provideUserState = () =>
  makeEnvironmentProviders([
    provideState('user', userReducer),
    provideEffects([UserEffects, UserLiveChangeEffects]),
    { provide: UserServiceAbstract, useClass: UserService },
  ]);
