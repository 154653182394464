import { Expose } from 'class-transformer';

import { SystemMessageType } from './system-message.type';
import { Identifier } from '../../tools/reducer-helper/model/identifier';

export class SystemMessageDetailsModel {
  @Expose()
  id: Identifier;
  @Expose()
  message: string;
  @Expose()
  type: SystemMessageType;
  @Expose()
  displayFrom: string;
  @Expose()
  displayTo: string;
}
