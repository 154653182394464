import { Expose } from 'class-transformer';

export class BankAccountEditModel {
  @Expose()
  iban: string;
  @Expose()
  bic: string;
  @Expose()
  countryId?: number;
  @Expose()
  zip?: string;
  @Expose()
  city?: string;
  @Expose()
  address?: string;
  @Expose()
  name?: string;
}
