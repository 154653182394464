import { Observable } from 'rxjs';

import { ResponseContainer } from '../../common/models/ResponseContainer';
import { PagerServiceAbstract } from '../../tools/reducer-helper/pager-service';
import { LanguageModel } from '../models';

export abstract class LanguageServiceAbstract extends PagerServiceAbstract<
  LanguageModel,
  null,
  null,
  null
> {
  detailsModel = LanguageModel;
  filterModel = null;
  createModel = null;
  editModel = null;

  create(): Observable<ResponseContainer<LanguageModel>> {
    throw 'not supported';
  }
  edit(): Observable<ResponseContainer<LanguageModel>> {
    throw 'not supported';
  }
  get(): Observable<ResponseContainer<LanguageModel>> {
    throw 'not supported';
  }
}
