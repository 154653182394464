import { PagerEntity } from '../../../common/models/PagerEntity';
import { createPagerReducer } from '../../../tools/reducer-helper/create-pager-reducer.factory';
import { IHasPagerEntities } from '../../../tools/reducer-helper/model/has-pager-entities';
import { LanguageModel } from '../../models/language.model';
import { languageAction } from '../action/language.actions';

export type LanguageState = IHasPagerEntities<LanguageModel, null>;
const initialPager = new PagerEntity<LanguageModel, null>({
  id: 'default',
  pages: {},
  selectedPageNum: 1,
  maxCount: null,
  limit: 50,
  asc: false,
  orderBy: 'id',
  search: null,
});

const languageInitialState: LanguageState = {
  entities: {},
  ids: [],
  initialPager: initialPager,
  pagers: {},
  pagerIds: [],
  selectedId: null,
};

export const languageReducer = createPagerReducer({
  details: LanguageModel,
  filter: null,
  initialState: languageInitialState,
  actions: languageAction,
  source: 'Language',
});
