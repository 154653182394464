import { createPagerActionGroup } from '../../tools/reducer-helper/create-pager-action-group.factory';
import { ManagerInvitationDetailsModel } from '../models/manager-invitation-details.model';
import { ManagerInvitationSearchModel } from '../models/manager-invitation-search.model';


export const managerInvitationAction = createPagerActionGroup<
  'ManagerInvitation',
  ManagerInvitationDetailsModel,
  ManagerInvitationSearchModel
>({
  source: 'ManagerInvitation',
});
