import { Expose, Type } from 'class-transformer';

import { Identifier } from '../../tools/reducer-helper/model/identifier';
import { SocialType } from '../../social/models/social-type';
import { CurrencyV2Model } from '../../common/models/currency-v2.model';
import { IdNameImgModel } from '../../common/models/id-name-img.model';

export class ManagerDealDetailsModel {
  @Expose()
  id: Identifier;
  @Expose()
  platform: SocialType;
  @Expose()
  @Type(() => CurrencyV2Model)
  price: CurrencyV2Model;
  @Expose()
  expire: string;
  @Expose()
  inserted: string;
  @Expose()
  @Type(() => IdNameImgModel)
  creator: IdNameImgModel;
  @Expose()
  @Type(() => IdNameImgModel)
  manager: IdNameImgModel;
  @Expose()
  @Type(() => IdNameImgModel)
  song: IdNameImgModel;
  @Expose()
  @Type(() => IdNameImgModel)
  artist: IdNameImgModel;
}
