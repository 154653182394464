import { Exclude, Expose } from 'class-transformer';

import { Identifier } from '../../tools/reducer-helper/model/identifier';

export class SettingsNotificationGroupEditModel {
  @Exclude()
  id: Identifier;
  @Expose({ name: 'pushSubscribe' })
  pushSubscribed: boolean;
  @Expose({ name: 'mailSubscribe' })
  mailSubscribed: boolean;
}
