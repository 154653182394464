import { PagerEntity } from '../../../common/models/PagerEntity';
import { createPagerReducer } from '../../../tools/reducer-helper/create-pager-reducer.factory';
import { IHasPagerEntities } from '../../../tools/reducer-helper/model/has-pager-entities';
import { InvoiceDetailsModel } from '../../models/invoice-details.model';
import { InvoiceSearchModel } from '../../models/invoice-search.model';
import { invoiceAction } from '../action/invoice.actions';

export type InvoiceState = IHasPagerEntities<
  InvoiceDetailsModel,
  InvoiceSearchModel
>;
const initialPager = new PagerEntity<InvoiceDetailsModel, InvoiceSearchModel>({
  id: 'default',
  pages: {},
  selectedPageNum: 1,
  maxCount: null,
  limit: 50,
  asc: false,
  orderBy: 'id',
  search: new InvoiceSearchModel({}),
});

const invoiceInitialState: InvoiceState = {
  entities: {},
  ids: [],
  initialPager: initialPager,
  pagers: {},
  pagerIds: [],
  selectedId: null,
};

export const invoiceReducer = createPagerReducer({
  details: InvoiceDetailsModel,
  filter: InvoiceSearchModel,
  initialState: invoiceInitialState,
  actions: invoiceAction,
  source: 'Invoice',
});
