import { PagerEntity } from '../../../common/models/PagerEntity';
import { createPagerReducer } from '../../../tools/reducer-helper/create-pager-reducer.factory';
import { IHasPagerEntities } from '../../../tools/reducer-helper/model/has-pager-entities';
import { TicketDetailsModel } from '../../models/ticket-details.model';
import { TicketSearchModel } from '../../models/ticket-search.model';
import { ticketAction } from '../action/ticket.actions';

export type TicketState = IHasPagerEntities<
  TicketDetailsModel,
  TicketSearchModel
>;
const initialPager = new PagerEntity<TicketDetailsModel, TicketSearchModel>({
  id: 'default',
  pages: {},
  selectedPageNum: 1,
  maxCount: null,
  limit: 50,
  asc: false,
  orderBy: 'id',
  search: new TicketSearchModel({}),
});

const ticketInitialState: TicketState = {
  entities: {},
  ids: [],
  initialPager: initialPager,
  pagers: {},
  pagerIds: [],
  selectedId: null,
};

export const ticketReducer = createPagerReducer({
  details: TicketDetailsModel,
  filter: TicketSearchModel,
  initialState: ticketInitialState,
  actions: ticketAction,
  source: 'Ticket',
});
