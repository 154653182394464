import { createPagerSelectors } from '../../tools/reducer-helper/create-pager-selector.factory';
import { TransactionDetailsModel } from '../models/transaction-details.model';
import { TransactionSearchModel } from '../models/transaction-search.model';
import { TransactionState } from './transaction.reducer';

export const transactionSelector = createPagerSelectors<
  TransactionDetailsModel,
  TransactionSearchModel,
  TransactionState
>('transaction');
