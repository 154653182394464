import { Injectable, inject } from '@angular/core';

import { instanceToPlain } from 'class-transformer';

import { API_URL } from '../../common/tokens/api-url';
import { toResponse } from '../../tools/map';
import { SpotifyArtistSetResponseModel } from '../models/spotift-artist-set-response.model';
import { SpotifyArtistSetModel } from '../models/spotify-artist-set.model';
import { SpotifyArtistServiceAbstract } from './spotify-artist.service.abstract';

@Injectable()
export class SpotifyArtistService extends SpotifyArtistServiceAbstract {
  url = `${inject(API_URL)}spotify-artist`;

  protected isManagerResource = true;

  constructor() {
    super();
  }

  set(model: SpotifyArtistSetModel) {
    const domain = instanceToPlain(model);

    return this.http
      .post(this.url, domain, { params: this.getParams() })
      .pipe(toResponse(SpotifyArtistSetResponseModel));
  }
}
