import { Expose, Type, Exclude } from 'class-transformer';

import { CurrencyV2Model } from '../../common/models/currency-v2.model';

export class CampaignV2KpiModel {
  @Expose()
  participants: number;

  @Expose()
  followers: number;

  @Expose()
  all: number;

  @Expose()
  completed: number;

  @Expose()
  inProgress: number;

  @Expose()
  reported: number;

  @Expose()
  videos: number;

  @Expose()
  views: number;

  @Expose()
  likes: number;

  @Expose()
  comments: number;

  @Expose()
  shares: number;

  @Expose()
  clicks: number;

  @Expose()
  saves: number;

  @Expose()
  participantsWithProofUrl: number;

  @Expose()
  @Type(() => CurrencyV2Model)
  billedAmount: CurrencyV2Model;

  @Expose()
  @Type(() => CurrencyV2Model)
  totalAmount: CurrencyV2Model;

  @Exclude()
  get interactions() {
    return this.likes + this.comments + this.shares + this.saves;
  }
  @Exclude()
  get cpm() {
    return (this.billedAmount.grossAmount * 1000) / this.views;
  }
  @Exclude()
  get cpi() {
    return (
      this.billedAmount.grossAmount /
      (this.likes + this.comments + this.shares + this.saves)
    );
  }
  @Exclude()
  get cpc() {
    return this.totalAmount.grossAmount / this.videos;
  }

  @Exclude()
  get interactionRate() {
    return (this.interactions * 100) / this.views;
  }
  @Exclude()
  get likeRate() {
    return (this.likes * 100) / this.views;
  }
  @Exclude()
  get commentRate() {
    return (this.comments * 100) / this.views;
  }
  @Exclude()
  get shareRate() {
    return (this.shares * 100) / this.views;
  }
  @Exclude()
  get saveRate() {
    return (this.saves * 100) / this.views;
  }
  @Exclude()
  get commentLikeRate() {
    return (this.comments * 100) / this.likes;
  }
  @Exclude()
  get uniqueCreatorRate() {
    return (this.participantsWithProofUrl * 100) / this.videos;
  }
}
