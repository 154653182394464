import { Exclude, Expose, Transform } from 'class-transformer';

import { IdNameModel } from '../../common/models/id-name.model';
import { SubGenreModel } from '../../sub-genre/models/sub-genre.model';
import { Mappings } from '../../tools/mapping/mappings';
import { SongType } from './song.type';
import { Identifier } from '../../tools/reducer-helper/model/identifier';

export class SongDetailsModel extends IdNameModel {
  @Expose()
  id: number;

  @Expose()
  artists: string[];

  @Expose()
  title: string;

  @Expose()
  featuring: string[];

  @Expose()
  release: string;

  @Expose()
  subGenreIds: number[];

  @Expose()
  languageIds: number[];

  @Expose()
  explicitLyrics: boolean;

  @Expose()
  explicitVideo: boolean;

  @Expose()
  @Transform(({ value }: { value: string }) =>
    value ? Mappings.youtubeEmbed(value) : value
  )
  url: string; // if type === video youtubeurl, if pitch fileurl

  @Expose({ name: 'spotifyUrl' })
  spotifyTrackId: string;

  @Expose({ name: 'any' })
  @Transform(({ obj }) =>
    obj.spotifyUrl ? Mappings.spotifyEmbed(obj.spotifyUrl) : obj.spotifyUrl
  )
  spotifyUrl: string;

  @Expose()
  moodIds: number[];

  @Expose()
  type: SongType;

  @Expose()
  cover: string;

  @Expose()
  onlyBaseEditable: boolean;

  @Exclude()
  subGenres: SubGenreModel[];

  @Expose()
  mp3: string;

  @Expose()
  inserted: string;

  @Expose()
  userId: Identifier;

  @Expose()
  @Transform(({ value }) => (value ? Mappings.getBaseUrl(value) : null))
  tiktokUrl: string;

  @Expose()
  instagramUrl: string;

  @Expose()
  tiktokMp3: string;

  @Expose()
  instagramMp3: string;
}
