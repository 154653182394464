import { inject } from '@angular/core';

import { map } from 'rxjs';

import { createEffect, ofType } from '@ngrx/effects';

import { SessionActions } from '../../session/store/actions/session.actions';
import { PagerEffects } from '../../tools/reducer-helper/pager-effects';
import { ManagerDealCreateModel } from '../models/manager-deal-create.model';
import { ManagerDealDetailsModel } from '../models/manager-deal-details.model';
import { ManagerDealEditModel } from '../models/manager-deal-edit.model';
import { ManagerDealSearchModel } from '../models/manager-deal-search.model';
import { ManagerDealServiceAbstract } from '../providers/manager-deal.service.abstract';
import { managerDealAction } from './manager-deal.action';
import { managerDealSelector } from './manager-deal.selector';

export class ManagerDealEffects extends PagerEffects<
  'ManagerDeal',
  ManagerDealDetailsModel,
  ManagerDealSearchModel,
  ManagerDealEditModel,
  ManagerDealCreateModel
> {
  resetLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.logoutCompleted),
      map(() =>
        managerDealAction.changeAllPager({
          matcher: () => true,
          payload: { maxCount: null },
        })
      )
    )
  );

  constructor() {
    super(
      managerDealAction,
      managerDealSelector,
      inject(ManagerDealServiceAbstract)
    );
  }
}
