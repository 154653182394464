import { Observable } from 'rxjs';


import { InviteDetailsModel } from '../model/invite-details.model';
import { ResponseContainer } from '../../common/models/ResponseContainer';

export abstract class InviteServiceAbstract {
  abstract getInvite(inviteId: string): Observable<ResponseContainer<InviteDetailsModel>>;
  abstract saveInvite(invite: string): Observable<void>;
  abstract clearInvite(): Observable<void>;
  abstract getStoredInviteId(): Observable<string>;
  abstract getStoredInvite(): Observable<ResponseContainer<InviteDetailsModel>>;
  abstract accept(inviteId: string): Observable<ResponseContainer<null>>;
  abstract decline(inviteId: string): Observable<ResponseContainer<null>>;
}
