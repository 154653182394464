import { Injectable, inject } from '@angular/core';

import { getTaxNumberRegex } from '@songpush/validators/tools/tax-number-regex';

import { API_URL } from '../../common/tokens/api-url';
import { toResponse } from '../../tools/map';
import { BankAccountCreateModel } from '../models/bank-account-create.model';
import { BankAccountDetailsModel } from '../models/bank-account-details.model';
import { BankAccountEditModel } from '../models/bank-account-edit.model';
import { BankAccountMandateModel } from '../models/bank-account-mandate.model';
import { BankAccountSearchModel } from '../models/bank-account-search.model';
import { BankAccountServiceAbstract } from './bank-account.service.abstract';

@Injectable()
export class BankAccountService extends BankAccountServiceAbstract {
  url = `${inject(API_URL)}company/bank-account`;
  removeUserUrl = `${inject(API_URL)}management/manager`;
  inviteUserUrl = `${inject(API_URL)}management/manager/invite`;
  mandateUrl = `${inject(API_URL)}company/bank-account/mandate`;

  detailsModel = BankAccountDetailsModel;
  filterModel = BankAccountSearchModel;
  createModel = BankAccountCreateModel;
  editModel = BankAccountEditModel;
  protected isManagerResource = true;
  protected onlyOwn = true;

  checkTaxNumber(taxNumber: string, countryISO: string) {
    return Promise.resolve(
      !!getTaxNumberRegex(countryISO)?.exec?.(taxNumber?.toLowerCase())
    );
  }

  createMandate() {
    const params = this.getParams();
    return this.http
      .post(this.mandateUrl, {}, { params })
      .pipe(toResponse(BankAccountMandateModel));
  }
}
