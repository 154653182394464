import { inject } from '@angular/core';

import { map, mergeMap } from 'rxjs';

import { createEffect, ofType } from '@ngrx/effects';

import { SessionActions } from '../../session/store/actions/session.actions';
import { SettingsActions } from '../../settings/store/actions';
import { PagerEffects } from '../../tools/reducer-helper/pager-effects';
import { UserType } from '../../user/models/user-type';
import { SystemMessageCreateModel } from '../models/system-message-create.model';
import { SystemMessageDetailsModel } from '../models/system-message-details.model';
import { SystemMessageEditModel } from '../models/system-message-edit.model';
import { SystemMessageSearchModel } from '../models/system-message-search.model';
import { SystemMessageServiceAbstract } from '../providers/system-message.service.abstract';
import { systemMessageAction } from './system-message.action';
import { systemMessageSelector } from './system-message.selector';

export class SystemMessageEffects extends PagerEffects<
  'SystemMessage',
  SystemMessageDetailsModel,
  SystemMessageSearchModel,
  SystemMessageEditModel,
  SystemMessageCreateModel
> {
  resetLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.logoutCompleted),
      map(() =>
        systemMessageAction.changeAllPager({
          matcher: () => true,
          payload: { maxCount: null },
        })
      )
    )
  );

  resetOnUserChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.selectUserSuccess),
      mergeMap(({ user: { type } }) => [
        systemMessageAction.changePager({
          payload: { maxCount: null },
          pagerId: `${UserType[type]}`.toLowerCase(),
        }),
      ])
    )
  );

  reloadOnLanguageChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.changeLanguage),
      map(() =>
        systemMessageAction.changeAllPager({
          payload: {
            maxCount: null,
          },
        })
      )
    )
  );

  constructor() {
    super(
      systemMessageAction,
      systemMessageSelector,
      inject(SystemMessageServiceAbstract)
    );
  }
}
