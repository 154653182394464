export enum SocialType {
  Google = 1,
  Twitter = 2,
  Facebook = 3,
  Instagram = 4,
  Spotify = 5,
  TikTok = 6,
  Twitch = 7,
  Youtube = 8,
  YoutubeChannels = 9,
  Apple = 10,
  SpotifyArtist = 11,
}
