import { Expose } from 'class-transformer';

import { SocialType } from '../../social/models/social-type';
import { Identifier } from '../../tools/reducer-helper/model/identifier';
import { UserType } from './user-type';

export class UserCreateWithSocialModel {
  @Expose()
  teamId?: Identifier;
  @Expose()
  name?: string;
  @Expose()
  userType?: UserType;
  @Expose()
  type?: SocialType;
  constructor(init?: Partial<UserCreateWithSocialModel>) {
    if (init) {
      Object.assign(this, init);
    }
  }
}
