export const environment = {
  production: Boolean('${APP_PRODUCTION}'),
  domain: '${APP_DOMAIN}',
  websocketUrl: '${APP_WEBSOCKET_URL}',
  apiUrl: '${APP_API_URL}',
  imageUrl: '${APP_IMAGE_URL}',
  mediaUrl: '${APP_MEDIA_URL}',
  uploadUrl: '${APP_UPLOAD_URL}',
  downloadUrl: '${APP_DOWNLOAD_URL}',
  shortlinkUrl: '${APP_SHORTLINK_URL}',
  facebookAppId: '${APP_FACEBOOK_APPID}',
  googleClientId: '${APP_GOOGLE_CLIENT_ID}',
  spotifyClientId: '${APP_SPOTIFY_CLIENT_ID}',
  twitchClientId: '${APP_TWITCH_CLIENT_ID}',
  appleClientId: '${APP_APPLE_CLIENT_ID}',
  googleAnalyticsId: '${APP_GOOGLE_ANALYTICS_ID}',
  googleTagManagerId: '${APP_TAG_MANAGER_ID}',
  tiktokClientId: '${APP_TIKTOK_CLIENT_ID}',
  instagramLink: '${APP_INSTAGRAM_LINK}',
};
