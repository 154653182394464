import { inject } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { combineLatest, map, Observable, switchMap } from 'rxjs';

import { Store } from '@ngrx/store';

import { PagerEntity } from '../../common/models/PagerEntity';
import { PagerSelector } from '../reducer-helper/create-pager-selector.factory';
import { IHasId } from '../reducer-helper/model/i-has-id';
import { Identifier } from '../reducer-helper/model/identifier';
import { PagerActions } from '../reducer-helper/model/pager-actions';
import { mergeIds } from '../reducer-helper/state-modifier/merge-ids';
import { getLoadedSource } from './loaded-source';
import { getValueWithChange } from './value-with-change';

export const selectPreloadedMultiSelection = <
  DetailsModel extends IHasId,
  FilterModel
>(
  control: AbstractControl<Identifier[]>,
  selector: PagerSelector<DetailsModel, FilterModel>,
  pagerId: string,
  store = inject(Store)
) => selectMultiSelection(control, selector, pagerId, null, store, true);

export const selectMultiSelection = <
  SelectorKey extends string,
  DetailsModel extends IHasId,
  FilterModel
>(
  control: AbstractControl<Identifier[]>,
  selector: PagerSelector<DetailsModel, FilterModel>,
  pagerId: string,
  action?: PagerActions<SelectorKey, DetailsModel, FilterModel>,
  store = inject(Store),
  loadAll = false
): Observable<MultiSelection<DetailsModel, FilterModel>> =>
  getValueWithChange(control).pipe(
    switchMap((formValue) =>
      combineLatest([
        getLoadedSource(
          () => store,
          () => selector,
          pagerId,
          selector.stateKey,
          () => action,
          loadAll
        ),
        store.select(selector.selectEntities),
      ]).pipe(
        map(([{ pager, ids }, entities]) => ({
          formValue,
          pager,
          all: (formValue ? mergeIds(ids, formValue) : ids)
            .map((id) => entities[id])
            .filter((item) => !!item),
          selected: (formValue ?? [])
            .map((id) => entities[id])
            .filter((item) => !!item),
          selectedId: formValue,
          action,
          pagerId,
        }))
      )
    )
  );

export type MultiSelection<DetailsModel extends IHasId, FilterModel> = {
  formValue: any;
  pager: PagerEntity<DetailsModel, FilterModel>;
  all: DetailsModel[];
  selected: DetailsModel[];
  selectedId: any[];
  action: PagerActions<string, DetailsModel, FilterModel>;
  pagerId: string;
};
