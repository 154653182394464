import { ElementRef, Injectable, OnDestroy, inject } from '@angular/core';

import { Observable, Subject, defer, fromEvent, of } from 'rxjs';
import { distinctUntilChanged, map, share, startWith, switchMap, takeUntil } from 'rxjs/operators';

@Injectable()
export class LifecycleHookService implements OnDestroy {
  private readonly el = inject(ElementRef);
  public readonly destroy$ = new Subject();
  public readonly willEnter$ = fromEvent(this.el.nativeElement, 'ionViewWillEnter').pipe(
    map((_, index) => index),
    share(),
    takeUntil(this.destroy$)
  );
  public readonly willLeave$ = fromEvent(this.el.nativeElement, 'ionViewWillLeave').pipe(
    share(),
    takeUntil(this.destroy$)
  );
  public readonly willEnterOrInit$ = this.willEnter$.pipe(startWith(0), distinctUntilChanged());

  constructor() {
    // 1 subscription is always required
    this.willEnterOrInit$.subscribe();
  }

  /**
   * @method rxjs operator,it will listen only when page in entered state, and takeUntil page are destroyed
   */
  listenUntilRequired() {
    return <T>(source: Observable<T>): Observable<T> =>
      this.willEnterOrInit$.pipe(
        switchMap(() => source.pipe(takeUntil(this.willLeave$))),
        takeUntil(this.destroy$)
      );
  }

  /**
   * @depricated use listen<T>(observable: () => Observable<T>): Observable<T>
   */
  listen<T>(observable: Observable<T>): Observable<T>;
  listen<T>(observable: () => Observable<T>): Observable<T>;

  listen<T>(observable: Observable<T> | (() => Observable<T>)): Observable<T> {
    let obs: Observable<T>;

    if (observable instanceof Function) {
      obs = (this.willEnter$ ?? defer(() => of(null))).pipe(
        switchMap(() => observable().pipe(takeUntil(this.willLeave$))),
        share(),
        takeUntil(this.destroy$)
      );
    } else {
      obs = observable.pipe(this.listenUntilRequired(), share());
    }

    obs.subscribe();
    return obs;
  }

  willLeave<T>(observable: () => Observable<T>) {
    const obs = (this.willLeave$ ?? defer(() => of(null))).pipe(
      switchMap(() => observable()),
      takeUntil(this.destroy$)
    );
    obs.subscribe();
    return obs;
  }

  willEnter<T>(observable: () => Observable<T>) {
    const obs = (this.willEnter$ ?? defer(() => of(null))).pipe(
      switchMap(() => observable()),
      takeUntil(this.destroy$)
    );
    obs.subscribe();
    return obs;
  }

  whenEntered<T>(observable: () => Observable<T>): Observable<T> {
    return defer(() =>
      (this.willEnter$ ?? defer(() => of(null))).pipe(
        switchMap(() => observable().pipe(takeUntil(this.willLeave$ ?? of()))),
        takeUntil(this.destroy$)
      )
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
