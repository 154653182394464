export enum WalletType {
  undefined = 0,
  payout = 1,
  sub = 2,
  technicalPayoutEuro = 50,
  tempomaryMain = 100,
  campaign = 200,
  campaignReserved = 250,
  payin = 500,
}
