import { Injectable, inject } from '@angular/core';

import { EMPTY, shareReplay, switchMap } from 'rxjs';

import { Store } from '@ngrx/store';

import { SessionSelector } from '../../session/store/selectors/session.selector';
import { UserType } from '../../user/models/user-type';
import { dealLimitSelector } from '../store/deal-limit.selector';
import { keepScopeLoaded } from '../../tools/operators/keep-scope-loaded';
import { dealLimitAction } from '../store/deal-limit.actions';

@Injectable()
export class DealLimitFacade {
  private readonly store = inject(Store);

  defaultScope = this.store.select(SessionSelector.selectUserType).pipe(
    switchMap((userType) => {
      if (userType === UserType.Creator) {
        return this.store.select(dealLimitSelector.selectScope()).pipe(
          keepScopeLoaded({
            store: this.store,
            changePagerAction: dealLimitAction.changePager({
              payload: {
                maxCount: null,
              },
              pagerId: 'default',
            }),
            target: {},
            updateAction: dealLimitAction.loadAll({ pagerId: 'default' }),
          })
        );
      }
      return EMPTY;
    }),
    shareReplay({
      refCount: true,
      bufferSize: 1,
    })
  );
}
