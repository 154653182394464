import { Observable } from 'rxjs';

import { ResponseContainer } from '../../common/models/ResponseContainer';
import { PagerServiceAbstract } from '../../tools/reducer-helper/pager-service';
import { SettingsNotificationGroupCreateModel } from '../models/settings-notification-group-create.model';
import { SettingsNotificationGroupDetailsModel } from '../models/settings-notification-group-details.model';
import { SettingsNotificationGroupEditModel } from '../models/settings-notification-group-edit.model';
import { SettingsNotificationGroupSearchModel } from '../models/settings-notification-group-search.model';
import { SettingsNotificationMarketingDetailsModel } from '../models/settings-notification-marketing-details.model';
import { SettingsNotificationMarketingEditModel } from '../models/settings-notification-marketing-edit.model';

export abstract class SettingsNotificationGroupServiceAbstract extends PagerServiceAbstract<
  SettingsNotificationGroupDetailsModel,
  SettingsNotificationGroupSearchModel,
  SettingsNotificationGroupCreateModel,
  SettingsNotificationGroupEditModel
> {
  abstract getMarketing(): Observable<ResponseContainer<SettingsNotificationMarketingDetailsModel>>;
  abstract setMarketing(
    model: SettingsNotificationMarketingEditModel
  ): Observable<ResponseContainer<SettingsNotificationMarketingDetailsModel>>;
}
