import { Expose } from 'class-transformer';

import { SocialType } from '../../social/models/social-type';

export class DealCreateModel {
  @Expose()
  campaignId: number;
  @Expose()
  platform: SocialType;
}
