import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { defer, Observable, of, throwError, timer } from 'rxjs';
import { map, mergeMap, retryWhen, switchMap } from 'rxjs/operators';

import { classToPlain, plainToClassFromExist } from 'class-transformer';

import { ResponseContainer } from '../../common/models/ResponseContainer';
import { IWindowManagerService, windowManagerService } from '../../common/services/window-manger';
import { ENVIRONMENT } from '../../common/tokens/environment';
import { MangopayRequestUrlModel } from '../models/mangopay-request-url.model';
import { MangopayUrlModel } from '../models/mangopay-url.model';
import { MangopayServiceAbstract } from './mangopay.service.abstract';
import { TransactionServiceAbstract } from '../../transaction/providers/transaction.service.abstract';
import { TransactionDetailsModel } from '../../transaction/models/transaction-details.model';
import { TransactionStatus } from '../../transaction/models/transaction-status';


@Injectable()
export class MangopayService extends MangopayServiceAbstract {
  private paymentUrl = `${this.apiUrl}payment/create-payin`;
  constructor(
    private http: HttpClient,
    @Inject('API_URL')
    private apiUrl: string,
    private transactionService: TransactionServiceAbstract,
    @Inject(windowManagerService)
    private windowManager: IWindowManagerService,
    @Inject(ENVIRONMENT)
    private environment: any
  ) {
    super();
  }

  getUrl(model: MangopayRequestUrlModel): Observable<ResponseContainer<MangopayUrlModel>> {
    const domain = classToPlain(model);
    let params: HttpParams;
    if (this.environment.hybrid) {
      params = new HttpParams({ fromObject: { platform: 3 } });
    }

    return this.http
      .post(this.paymentUrl, domain, { params })
      .pipe(map((resp) => plainToClassFromExist(new ResponseContainer(MangopayUrlModel), resp)));
  }

  requestPayment(paymentUrl: string, window?: Window): Observable<ResponseContainer<TransactionDetailsModel>> {
    const targetWindow = window ?? this.windowManager.createBlank();
    return defer(() => this.windowManager.customOAuth(paymentUrl, 'transactionId', true, targetWindow)).pipe(
      switchMap((id) =>
        of(id).pipe(
          switchMap((id) => this.transactionService.get(id as unknown as number)),
          map((resp) => {
            if (resp.values.status === TransactionStatus.failed || resp.values.status === TransactionStatus.succeeded) {
              return resp;
            } else {
              throw resp;
            }
          }),
          retryWhen((attemps) =>
            attemps.pipe(
              mergeMap((value) => {
                if (value?.values?.paymentStatus) {
                  return timer(1500);
                }
                return throwError(value);
              })
            )
          )
        )
      )
    );
  }
}
