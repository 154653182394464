import { Expose } from 'class-transformer';

import { UserType } from './user-type';

export class UserRegistrationModel {
  @Expose()
  email: string;
  @Expose()
  password: string;
  @Expose()
  rePassword: string;
  @Expose()
  type: UserType;
  @Expose()
  inviteKey?: string;
}
