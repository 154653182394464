import { Injectable } from '@angular/core';

import { take } from 'rxjs/operators';

import { AlertController } from '@bkkr/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { FormDirtyGuardAbstract } from '@songpush/core/form/guards/form-dirty-guard';
import { SessionSelector } from '@songpush/core/session/store/selectors';



@Injectable({ providedIn: 'root' })
export class FormDirtyGuard extends FormDirtyGuardAbstract {
  constructor(private alertCtrl: AlertController, private translate: TranslateService, private store: Store) {
    super();
  }

  async requestConfirmation(): Promise<boolean> {
    const dialog = await this.alertCtrl.create({
      header: this.translate.instant('GLOBAL_WARNING_UNSAVED_CHANGES_TITLE'),
      message: this.translate.instant('GLOBAL_WARNING_UNSAVED_CHANGES_DESC'),
      buttons: [
        {
          text: this.translate.instant('GLOBAL_NO'),
          role: 'cancel',
          handler: () => ({ result: false }),
        },
        {
          text: this.translate.instant('GLOBAL_LEAVE'),
          role: 'destructive',
          handler: () => ({ result: true }),
        },
      ],
    });
    dialog.present();

    const dialogResponse = await dialog.onDidDismiss();
    return !!dialogResponse.data?.result;
  }

  isLoggedIn(): Promise<boolean> {
    return this.store.select(SessionSelector.selectSignedIn).pipe(take(1)).toPromise();
  }
}
