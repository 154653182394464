import { makeEnvironmentProviders } from '@angular/core';

import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { ContentCategoryService } from '../services/content-category.service';
import { ContentCategoryServiceAbstract } from '../services/content-category.service.abstract';
import { ContentCategoryEffects } from '../store/effect';
import { contentCategoryReducer } from '../store/reducer';

export const provideContentCategoryState = () =>
  makeEnvironmentProviders([
    provideState('content-category', contentCategoryReducer),
    provideEffects([ContentCategoryEffects]),
    { provide: ContentCategoryServiceAbstract, useClass: ContentCategoryService },
  ]);
