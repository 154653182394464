import { Injectable, inject } from '@angular/core';

import { filter, map, tap } from 'rxjs';

// import { ToastController } from '@ionic/angular/standalone';
import { ToastController } from '@ionic/angular/standalone';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';

import { WebsocketMessageType } from '@songpush/core/websocket/models';
import { WebsocketActions } from '@songpush/core/websocket/store/action';
import { WebsocketService } from '@songpush/shared/websocket';

@Injectable()
export class WebscocketLocalEffects {
  private actions$ = inject(Actions);
  private websocketService = inject(WebsocketService);
  private toastCtrl = inject(ToastController);
  private translate = inject(TranslateService);

  connect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WebsocketActions.connect),
        map(({ topic }) => this.websocketService.connect(topic))
      ),
    { dispatch: false }
  );

  disconnect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WebsocketActions.disconnect),
        map(({ topic }) => this.websocketService.close(topic))
      ),
    { dispatch: false }
  );

  onMessage$ = createEffect(() =>
    this.websocketService
      .onMessage()
      .pipe(map((data) => WebsocketActions.message({ payload: data })))
  );

  events$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WebsocketActions.message),
        filter(
          ({ payload }) => payload?.type === WebsocketMessageType.SiteUpdated
        ),
        tap(() => this.displayRefreshSiteNote())
      ),
    { dispatch: false }
  );

  async displayRefreshSiteNote() {
    const toast = await this.toastCtrl.create({
      mode: 'md',
      header: this.translate.instant('TOAST_REFRESH_SITE_HEADER'),
      message: this.translate.instant('TOAST_REFRESH_SITE_MESSAGE'),
      buttons: [
        {
          text: this.translate.instant('TOAST_REFRESH_SITE_BTN_RELOAD'),
          role: 'info',
          handler: () => {
            window.location.reload();
          },
        },
      ],
    });
    toast.present();
  }
}
