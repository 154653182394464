import { Observable } from 'rxjs/internal/Observable';

import { ResponseContainer } from '../../common/models/ResponseContainer';
import { PagerServiceAbstract } from '../../tools/reducer-helper/pager-service';
import { TeamCreateModel } from '../models/team-create.model';
import { TeamDetailsModel } from '../models/team-details.model';
import { TeamEditModel } from '../models/team-edit.model';
import { TeamSearchModel } from '../models/team-search.model';
import { TeamUserRemoveModel } from '../models/team-user-remove.model';

export abstract class TeamServiceAbstract extends PagerServiceAbstract<
  TeamDetailsModel,
  TeamSearchModel,
  TeamCreateModel,
  TeamEditModel
> {
  abstract removeUser(model: TeamUserRemoveModel): Observable<ResponseContainer<null>>;
}
