import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';

import { map, switchMap, take } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { SessionSelector } from '../../../session/store/selectors/session.selector';
import { UserInitStatus } from '../../../user/models/user-init-status';

export const initStatusGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const router = inject(Router);
  const initStatus$ = inject(Store).select(SessionSelector.selectInitStatus);
  const isManager$ = inject(Store).select(SessionSelector.selectIsManager);
  const managerInitStatus$ = inject(Store).select(SessionSelector.selectManagerInitstatus);
  const selectedInitStatus = route.data?.initStatus as UserInitStatus[];

  return isManager$.pipe(
    switchMap((isManager) =>
      (isManager ? managerInitStatus$ : initStatus$).pipe(map((initStatus) => ({ initStatus, isManager })))
    ),
    map(({ initStatus, isManager }) => {
      if (selectedInitStatus.includes(initStatus)) {
        return true;
      } else {
        const redirectUrl = isManager ? resolveManagerNavigationUrl(initStatus) : resolveUserNavigationUrl(initStatus);
        router.navigate([redirectUrl]);
        return !redirectUrl;
      }
    }),
    take(1)
  );
};

const resolveUserNavigationUrl = (initStatus: UserInitStatus) => {
  switch (initStatus) {
    case UserInitStatus.Completed:
      return '/dashboard';
    case UserInitStatus.WaitingForEmailVerification:
      return 'registration/verify-email';
    case UserInitStatus.WaitingForSocials:
      return 'registration/oauth';
    case UserInitStatus.WaitingForDatas:
      return 'registration/datas';
    case UserInitStatus.WaitingForCompany:
      return 'registration/finish';
    case UserInitStatus.Pending:
      return 'registration/waiting';
    case UserInitStatus.Rejected:
      return 'registration/rejected';
    default:
      return null;
  }
};

const resolveManagerNavigationUrl = (initStatus: UserInitStatus) => {
  switch (initStatus) {
    case UserInitStatus.Completed:
      return '/dashboard';
    case UserInitStatus.WaitingForEmailVerification:
      return 'registration/verify-email';
    case UserInitStatus.WaitingForDatas:
      return 'registration/datas';
    case UserInitStatus.WaitingForCompany:
      return 'registration/company/invite';
    case UserInitStatus.Pending:
      return 'registration/waiting';
    case UserInitStatus.Rejected:
      return 'registration/rejected';
    default:
      return null;
  }
};
