import { createPagerActionGroup } from '../../../tools/reducer-helper/create-pager-action-group.factory';
import { SongDetailsModel } from '../../models/song-details.model';
import { SongSearchModel } from '../../models/song-search.model';

export const songAction = createPagerActionGroup<
  'Song',
  SongDetailsModel,
  SongSearchModel
>({
  source: 'Song',
});
