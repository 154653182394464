import { inject } from '@angular/core';

import { map } from 'rxjs';

import { createEffect, ofType } from '@ngrx/effects';

import { SettingsNotificationGroupEditModel } from '../models/settings-notification-group-edit.model';
import { settingsNotificationGroupAction } from './settings-notification-group.action';
import { settingsNotificationGroupSelector } from './settings-notification-group.selector';
import { PagerEffects } from '../../tools/reducer-helper/pager-effects';
import { SessionActions } from '../../session/store/actions/session.actions';
import { SettingsNotificationGroupServiceAbstract } from '../providers/settings-notification-group.service.abstract';
import { SettingsNotificationGroupDetailsModel } from '../models/settings-notification-group-details.model';
import { SettingsNotificationGroupSearchModel } from '../models/settings-notification-group-search.model';
import { SettingsNotificationGroupCreateModel } from '../models/settings-notification-group-create.model';

export class SettingsNotificationGroupEffects extends PagerEffects<
  'SettingsNotificationGroup',
  SettingsNotificationGroupDetailsModel,
  SettingsNotificationGroupSearchModel,
  SettingsNotificationGroupEditModel,
  SettingsNotificationGroupCreateModel
> {
  resetLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.logoutCompleted),
      map(() => settingsNotificationGroupAction.resetState())
    )
  );

  constructor() {
    super(
      settingsNotificationGroupAction,
      settingsNotificationGroupSelector,
      inject(SettingsNotificationGroupServiceAbstract)
    );
  }
}
