import { Injectable, inject } from '@angular/core';


import { ManagerDealEditModel } from '../models/manager-deal-edit.model';
import { ManagerDealServiceAbstract } from './manager-deal.service.abstract';
import { API_URL } from '../../common/tokens/api-url';
import { ManagerDealDetailsModel } from '../models/manager-deal-details.model';
import { ManagerDealSearchModel } from '../models/manager-deal-search.model';
import { ManagerDealCreateModel } from '../models/manager-deal-create.model';

@Injectable()
export class ManagerDealService extends ManagerDealServiceAbstract {
  url = `${inject(API_URL)}deal`;

  detailsModel = ManagerDealDetailsModel;
  filterModel = ManagerDealSearchModel;
  createModel = ManagerDealCreateModel;
  editModel = ManagerDealEditModel;
  protected isManagerResource = true;
}
