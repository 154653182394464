import { makeEnvironmentProviders } from '@angular/core';

import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { SpotifyArtistService } from '../services/spotify-artist.service';
import { SpotifyArtistServiceAbstract } from '../services/spotify-artist.service.abstract';
import { SpotifyArtistEffects } from '../store/effect';
import { spotifyArtistReducer } from '../store/reducer';

export const provideSpotifyArtistState = () =>
  makeEnvironmentProviders([
    provideState('spotifyArtist', spotifyArtistReducer),
    provideEffects([SpotifyArtistEffects]),
    { provide: SpotifyArtistServiceAbstract, useClass: SpotifyArtistService },
  ]);
