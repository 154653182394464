import { Expose } from 'class-transformer';

export class UserPasswordChangeModel {
  @Expose()
  oldPassword: string;

  @Expose()
  password: string;

  @Expose()
  rePassword: string;
}
