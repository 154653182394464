import { Injectable, inject } from '@angular/core';

import { map } from 'rxjs/internal/operators/map';

import { createEffect, ofType } from '@ngrx/effects';

import { SessionActions } from '../../../session/store/actions/session.actions';
import { PagerEffects } from '../../../tools/reducer-helper/pager-effects';
import { CountrySearchModel } from '../../models/country-search.model';
import { CountryModel } from '../../models/country.model';
import { CountryServiceAbstract } from '../../providers/country.service.abstract';
import { countryAction } from '../action/country.actions';
import { countrySelector } from '../selector/country.selector';

@Injectable()
export class CountryEffects extends PagerEffects<
  'Country',
  CountryModel,
  CountrySearchModel,
  null,
  null
> {
  resetLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.logoutCompleted),
      map(() =>
        countryAction.changeAllPager({
          matcher: () => true,
          payload: { maxCount: null },
        })
      )
    )
  );

  constructor() {
    super(countryAction, countrySelector, inject(CountryServiceAbstract));
  }
}
