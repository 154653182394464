import { createActionGroup, props } from '@ngrx/store';

import { WebsocketData } from '../../models/websocket-data';

export const WebsocketActions = createActionGroup({
  source: 'Websocket',
  events: {
    message: props<{ payload: WebsocketData }>(),
    connect: props<{ topic: string }>(),
    disconnect: props<{ topic: string }>(),
  },
});
