import { inject, Injectable } from '@angular/core';

import { map } from 'rxjs/internal/operators/map';

import { createEffect, ofType } from '@ngrx/effects';

import { SessionActions } from '../../session/store/actions/session.actions';
import { PagerEffects } from '../../tools/reducer-helper/pager-effects';
import { DealLimitCreateModel } from '../models/deal-limit-create.model';
import { DealLimitDetailsModel } from '../models/deal-limit-details.model';
import { DealLimitEditModel } from '../models/deal-limit-edit.model';
import { DealLimitSearchModel } from '../models/deal-limit-search.model';
import { DealLimitServiceAbstract } from '../services/deal-limit.service.abstract';
import { dealLimitAction } from './deal-limit.actions';
import { dealLimitSelector } from './deal-limit.selector';

@Injectable()
export class DealLimitEffects extends PagerEffects<
  'DealLimit',
  DealLimitDetailsModel,
  DealLimitSearchModel,
  DealLimitEditModel,
  DealLimitCreateModel
> {
  resetLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.logoutCompleted, SessionActions.selectUserSuccess),
      map(() =>
        dealLimitAction.changeAllPager({
          matcher: () => true,
          payload: { maxCount: null },
        })
      )
    )
  );

  constructor() {
    super(dealLimitAction, dealLimitSelector, inject(DealLimitServiceAbstract));
  }
}
