import { Observable, map } from 'rxjs';

import { ClassConstructor, plainToClassFromExist } from 'class-transformer';

import { PagerResponseContainer } from '../../common/models/PagerResponseContainer';
import { ResponseContainer } from '../../common/models/ResponseContainer';

export const toResponse =
  <T>(model: ClassConstructor<T>) =>
  (src: Observable<any>) =>
    src.pipe(
      map((resp) =>
        resp ? plainToClassFromExist(new ResponseContainer(model), resp) : null
      )
    );

export const toEmptyResponse =
  () =>
  (src: Observable<any>): Observable<ResponseContainer<null>> =>
    src.pipe(
      map((resp) =>
        resp ? plainToClassFromExist(new ResponseContainer(null), resp) : null
      )
    );

export const toPagerResponse =
  <T>(model: ClassConstructor<T>) =>
  (src: Observable<any>) =>
    src.pipe(
      map((resp) =>
        resp
          ? plainToClassFromExist(new PagerResponseContainer(model), resp)
          : null
      )
    );
