import { Routes } from '@angular/router';

import { initStatusGuard } from '@songpush/core/common/guards/init-status';
import { loadedGuard } from '@songpush/core/common/guards/loaded';
import {
  sessionManagerGuard,
  sessionManagerRefreshGuard,
  sessionNotManagerGuard,
  sessionUserRefreshGuard,
  signedInGuard,
  signedOutGuard,
} from '@songpush/core/session/guards';
import { UserInitStatus } from '@songpush/core/user/models';
import { ParentStateGuard } from '@songpush/shared/common/guards';

export const ROUTES: Routes = [
  {
    path: '',
    canActivate: [ParentStateGuard],
    canMatch: [loadedGuard],
    children: [
      {
        path: '',
        canActivate: [signedOutGuard],
        loadChildren: () => import('./router/signed-out.routes').then((mod) => mod.signedOutRoutes),
      },
      {
        path: '',
        canActivate: [signedInGuard],
        children: [
          {
            path: '',
            loadChildren: () => import('./router/all-usertype.routes').then((mod) => mod.allUserTypeRoutes),
          },
          {
            path: 'settings',
            canActivate: [initStatusGuard],
            data: { initStatus: [UserInitStatus.Completed] },
            loadComponent: () =>
              import('@songpush/shared/frame/pages/frame-settings').then((m) => m.FrameSettingsComponent),
            loadChildren: () => import('./router/settings.routes').then((mod) => mod.settingsRoutes),
          },
          {
            path: '',
            canActivate: [sessionUserRefreshGuard],
            canMatch: [sessionNotManagerGuard],
            loadChildren: () => import('./router/signed-in.routes').then((mod) => mod.signedInRoutes),
          },
          {
            path: '',
            canActivate: [sessionManagerRefreshGuard],
            canMatch: [sessionManagerGuard],
            loadChildren: () => import('./router/manager.routes').then((mod) => mod.managerRoutes),
          },
        ],
      },
      {
        path: '',
        loadChildren: () => import('@songpush/shared/email/routes').then((mod) => mod.emailRoutes),
      },
      {
        path: '',
        loadChildren: () => import('./router/common.routes').then((mod) => mod.commonRoutes),
      },
    ],
  },
];
