import { createPagerSelectors } from '../../tools/reducer-helper/create-pager-selector.factory';
import { ManagerInvitationDetailsModel } from '../models/manager-invitation-details.model';
import { ManagerInvitationSearchModel } from '../models/manager-invitation-search.model';
import { ManagerInvitationState } from './manager-invitation.reducer';

export const managerInvitationSelector = createPagerSelectors<
  ManagerInvitationDetailsModel,
  ManagerInvitationSearchModel,
  ManagerInvitationState
>('managerInvitation');
