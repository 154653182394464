import { Injectable, inject } from '@angular/core';

import { Observable } from 'rxjs';

import { ResponseContainer } from '../../common/models/ResponseContainer';
import { API_URL } from '../../common/tokens/api-url';
import { SpotifyTrackDetailsModel } from '../models/spotify-track-details.model';
import { SpotifyTrackServiceAbstract } from './spotify-track.service.abstract';

@Injectable()
export class SpotifyTrackService extends SpotifyTrackServiceAbstract {
  url = `${inject(API_URL)}spotify-song`;

  constructor() {
    super();
  }
  edit(): Observable<ResponseContainer<SpotifyTrackDetailsModel>> {
    throw 'not supported';
  }

  delete(): Observable<ResponseContainer<unknown>> {
    throw 'not supported';
  }
}
