import { Observable } from 'rxjs';

import { ResponseContainer } from '../../common/models/ResponseContainer';
import { PagerServiceAbstract } from '../../tools/reducer-helper/pager-service';
import { SubGenreModel } from '../models/sub-genre.model';

export abstract class SubGenreServiceAbstract extends PagerServiceAbstract<
  SubGenreModel,
  null,
  null,
  null
> {
  detailsModel = SubGenreModel;
  filterModel = null;
  createModel = null;
  editModel = null;

  create(): Observable<ResponseContainer<SubGenreModel>> {
    throw 'not supported';
  }
  edit(): Observable<ResponseContainer<SubGenreModel>> {
    throw 'not supported';
  }
  get(): Observable<ResponseContainer<SubGenreModel>> {
    throw 'not supported';
  }
}
