import { createSelector } from '@ngrx/store';

import { Scope } from '../../common/models/scope';
import { memoize } from '../memoize/memoize';
import { IHasPagerEntities } from './model/has-pager-entities';
import { IHasId } from './model/i-has-id';
import { Identifier } from './model/identifier';
import { OrderKey } from './model/oreder-key';

export const createPagerSelectors = <
  DetailsModel extends IHasId,
  FilterModel,
  StateModel extends IHasPagerEntities<DetailsModel, FilterModel>
>(
  stateKey: string
) => {
  const selectState = (state) => state[stateKey] as StateModel;
  const selectEntities = createSelector(selectState, (state) => state.entities);

  const selectPagers = createSelector(selectState, (state) => state.pagers);
  const selectIds = createSelector(selectState, (state) => state.ids);
  const selectInitialPager = createSelector(
    selectState,
    (state) => state.initialPager
  );

  const selectAll = createSelector(selectEntities, selectIds, (ents, ids) =>
    ids.map((id) => ents[id])
  );

  const selectItem = memoize((id: Identifier) =>
    createSelector(selectEntities, (ents) => ents[id])
  );

  const selectMaxPageNum = memoize((instance = 'default') =>
    createSelector(selectPager(instance), ({ maxCount, limit }) =>
      Math.ceil((maxCount || 0) / limit)
    )
  );

  const selectSelectedPage = memoize((instance = 'default') =>
    createSelector(selectEntities, selectPager(instance), (ent, pager) =>
      pager.pages[pager.selectedPageNum]?.map((id) => ent[id])
    )
  );

  const selectPagerPages = memoize((pagerId = 'default') =>
    createSelector(selectPager(pagerId), (pager) => pager.pages)
  );

  const selectAllPagerId = memoize((instance = 'default') =>
    createSelector(selectPagerPages(instance), (pages) =>
      Object.keys(pages || {})
        .map((id) => parseInt(id, 10))
        .reduce(
          (all, id) => [...all, ...(pages?.[id] || [])],
          [] as Identifier[]
        )
    )
  );

  const selectPagerItems = memoize((instance = 'default') =>
    createSelector(selectAllPagerId(instance), selectEntities, (ids, ents) =>
      ids.map((id) => ents[id])
    )
  );

  const selectPager = memoize((instance = 'default') =>
    createSelector(
      selectPagers,
      selectInitialPager,
      (pager, iPager) => pager[instance] || iPager
    )
  );

  const selectInstanceExist = memoize((instance: string) =>
    createSelector(selectPagers, (pagers) => !!pagers[instance])
  );

  const selectScope = memoize((instance = 'default') =>
    createSelector(
      selectInstanceExist(instance),
      selectPager(instance),
      selectAllPagerId(instance),
      selectEntities,
      (instanceExist, pager, ids, ents) =>
        ({
          instance: instanceExist ? instance : 'default',
          finalInstance: instance,
          pager,
          all: ids.map((id) => ents[id]),
          ids,
        } as Scope<DetailsModel, FilterModel>)
    )
  );

  const selectPageScope = memoize((instance = 'default') =>
    createSelector(
      selectInstanceExist(instance),
      selectPager(instance),
      selectEntities,
      (instanceExist, pager, ents) => ({
        instance: instanceExist ? instance : 'default',
        finalInstance: instance,
        pager,
        all: (pager?.pages?.[pager.selectedPageNum] || []).map(
          (id) => ents[id]
        ),
        ids: pager?.pages?.[pager.selectedPageNum] || [],
      })
    )
  );

  const selectOrderKey = memoize((instance = 'default') =>
    createSelector(
      selectPager(instance),
      ({ orderBy, asc }) =>
        `${orderBy as string}${asc ? 'asc' : 'desc'}` as OrderKey<DetailsModel>
    )
  );

  const selectMaxCount = memoize((instance = 'default') =>
    createSelector(selectPager(instance), ({ maxCount }) => maxCount)
  );

  const selectSelectedPageNum = memoize((instance = 'default') =>
    createSelector(
      selectPager(instance),
      ({ selectedPageNum }) => selectedPageNum
    )
  );

  const selectIndex = memoize((id: number | string, instance: string) =>
    createSelector(selectAllPagerId(instance), (ids) => {
      const selectedIndex = ids.indexOf(id as number);
      return selectedIndex;
    })
  );

  const selectPreviousId = memoize(
    (id: number | string, instance = 'default') =>
      createSelector(
        selectIndex(id, instance),
        selectAllPagerId(instance),
        selectEntities,
        (index, pagerIds) => {
          return pagerIds[index - 1];
        }
      )
  );

  const selectNextId = memoize((id: number | string, instance = 'default') =>
    createSelector(
      selectIndex(id, instance),
      selectAllPagerId(instance),
      (index, pagerIds) => {
        return pagerIds[index + 1];
      }
    )
  );

  const selectAt = memoize((instance = 'default', index: number) =>
    createSelector(
      selectAllPagerId(instance),
      selectEntities,
      (ids, entities) => entities[ids[index]]
    )
  );
  const selectSearch = memoize((instance = 'default') =>
    createSelector(selectPager(instance), ({ search }) => search)
  );

  return {
    stateKey,
    selectState,
    selectEntities,
    selectPagers,
    selectIds,
    selectInitialPager,
    selectAll,
    selectItem,
    selectMaxPageNum,
    selectSelectedPage,
    selectAllPagerId,
    selectPagerItems,
    selectPager,
    selectInstanceExist,
    selectScope,
    selectPageScope,
    selectOrderKey,
    selectMaxCount,
    selectSelectedPageNum,
    selectPreviousId,
    selectIndex,
    selectNextId,
    selectAt,
    selectSearch,
  };
};

export type PagerSelector<
  DetailsModel extends IHasId,
  FilterModel
> = ReturnType<typeof createPagerSelectors<DetailsModel, FilterModel, any>>;
