import { Expose, Transform, Type } from 'class-transformer';

import { ResponseErrorInput } from './ResponseErrorInput';

export class ResponseStatus {
  @Type(() => ResponseErrorInput)
  @Expose()
  inputs: ResponseErrorInput[] = [];
  @Expose()
  success: boolean;
  @Expose()
  @Transform((v) => (v.value ? v.value : null) as string)
  globalMessage?: string;
}
