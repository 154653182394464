import { makeEnvironmentProviders } from '@angular/core';

import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { CompanyService } from '../services/company.service';
import { CompanyServiceAbstract } from '../services/company.service.abstract';
import { CompanyEffects } from '../store/effect';
import { companyReducer } from '../store/reducer';

export const provideCompanyState = () =>
  makeEnvironmentProviders([
    provideState('company', companyReducer),
    provideEffects([CompanyEffects]),
    { provide: CompanyServiceAbstract, useClass: CompanyService },
  ]);
