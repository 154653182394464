import { makeEnvironmentProviders } from '@angular/core';

import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { SpotifyTrackService } from '../services/spotify-track.service';
import { SpotifyTrackServiceAbstract } from '../services/spotify-track.service.abstract';
import { SpotifyTrackEffects } from '../store/effect';
import { spotifyTrackReducer } from '../store/reducer';

export const provideSpotifyTrackState = () =>
  makeEnvironmentProviders([
    provideState('spotifyTrack', spotifyTrackReducer),
    provideEffects([SpotifyTrackEffects]),
    { provide: SpotifyTrackServiceAbstract, useClass: SpotifyTrackService },
  ]);
