import { createPagerSelectors } from '../../../tools/reducer-helper/create-pager-selector.factory';
import { TicketDetailsModel } from '../../models/ticket-details.model';
import { TicketSearchModel } from '../../models/ticket-search.model';
import { TicketState } from '../reducer/ticket.reducer';

export const ticketSelector = createPagerSelectors<
  TicketDetailsModel,
  TicketSearchModel,
  TicketState
>('ticket');
