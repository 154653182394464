export function memoize<A>(func: A): A;

export function memoize(func: (...params: any) => any) {
  const results = {};
  return (...args) => {
    const argsKey = JSON.stringify(args);
    if (!results[argsKey]) {
      results[argsKey] = func(...args);
    }
    return results[argsKey];
  };
}
