import { inject, Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { Storage } from '@ionic/storage-angular';
import { createEffect } from '@ngrx/effects';

import { storageAction } from './storage.action';
import { defer } from 'rxjs';

@Injectable()
export class StorageEffects {
  private storage = inject(Storage);

  ready$ = createEffect(() =>
    defer(() => this.storage.create()).pipe(map(() => storageAction.ready()))
  );
}
