import { Expose, Transform } from 'class-transformer';

import { Identifier } from '../../tools/reducer-helper/model/identifier';
import { TransactionNature } from './transaction-nature';
import { TransactionStatus } from './transaction-status';
import { TransactionType } from './transaction-type';

export class TransactionSearchModel {
  @Expose()
  search?: string;

  @Expose()
  walletId?: Identifier[];

  @Expose()
  @Transform(({ value }) => (value ? parseInt(value, 10) : null))
  dealId?: number | '';

  @Expose()
  @Transform(({ value }) => (value ? parseInt(value, 10) : null))
  invoiceId?: number | '';

  @Expose()
  nature?: TransactionNature;
  @Expose()
  status?: TransactionStatus;
  @Expose()
  type?: TransactionType;
  @Expose()
  dateFrom?: string;
  @Expose()
  dateTo?: string;

  constructor(ent: TransactionSearchModel) {
    Object.assign(this, ent);
  }
}
