import { inject, Injectable } from '@angular/core';

import { map, Observable, switchMap, take } from 'rxjs';

import { Store } from '@ngrx/store';

import { PagerResponseContainer } from '../../common/models/PagerResponseContainer';
import { API_URL } from '../../common/tokens/api-url';
import { SettingsSelector } from '../../settings/store/selectors';
import { toPagerResponse } from '../../tools/map';
import { filterEmpty } from '../../tools/map/filter-empty';
import { LanguageModel } from '../models/language.model';
import { LanguageServiceAbstract } from './language.service.abstract';

@Injectable()
export class LanguageService extends LanguageServiceAbstract {
  url = `${inject(API_URL)}datas/language`;
  store = inject(Store);

  constructor() {
    super();
  }

  getPage(): Observable<PagerResponseContainer<LanguageModel>> {
    return this.store.select(SettingsSelector.getLanguage).pipe(
      take(1),
      filterEmpty(),
      switchMap((lang) => this.http.get(`${this.url}/${lang}.json`)),
      map((results) => ({
        status: { success: true },
        values: { results, maxCount: results?.['length'] ?? 0 },
      })),
      toPagerResponse(LanguageModel)
    );
  }
}
