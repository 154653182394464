import { makeEnvironmentProviders, NgModule } from '@angular/core';
import { EffectsModule, provideEffects } from '@ngrx/effects';

import { StorageEffects } from '../state/storage.effect';

export const provideStorageState = () =>
  makeEnvironmentProviders([provideEffects([StorageEffects])]);

@NgModule({
  imports: [EffectsModule.forFeature([StorageEffects])],
})
export class StorageStateModule {}
