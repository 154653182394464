import { createReducer, on } from '@ngrx/store';

import { FlowProgressItemModel } from '@songpush/core/flow/model';
import { Mappings } from '@songpush/core/tools/mapping';

import { FlowOpts } from '../../models/flow-opts';
import { flowProgressAction } from '../action/flow-progress.actions';

export interface FlowProgressState extends FlowOpts {
  ids: number[];
  entities: { [key: number]: FlowProgressItemModel };
}

const initState: FlowProgressState = {
  ids: [],
  entities: {},
  selected: null,
  filled: null,
  collapse: null,
  canLeave: true,
};

export const flowProgressReducer = createReducer(
  initState,
  on(flowProgressAction.init, (state, action) =>
    Mappings.assign(state, {
      ...addPayloadToState(initState, action.payload),
      ...action.opts,
    })
  ),
  on(flowProgressAction.changeOpts, (state, action) =>
    Mappings.assign(state, action.payload as Partial<FlowProgressState>)
  ),
  on(flowProgressAction.edit, (state, action) =>
    Mappings.assign(state, editEntity(state, action.payload))
  ),
  on(flowProgressAction.cleanUp, (state, action) =>
    Mappings.assign(state, cleanUpEntities(state, action.ids))
  )
);
const addPayloadToState = (
  state: FlowProgressState,
  payload: FlowProgressItemModel[]
): Partial<FlowProgressState> => ({
  ids: Mappings.mergeIds(
    state.ids,
    payload.map((item) => item.id)
  ),
  entities: Mappings.entitiesPlusList(state.entities, payload, 'id'),
});

const editEntity = (
  state: FlowProgressState,
  payload: Partial<FlowProgressItemModel> & { id: number }
): Partial<FlowProgressState> => ({
  entities: Mappings.assign(state.entities, {
    [payload.id]: Mappings.assign(state.entities[payload.id], payload),
  }),
});

const cleanUpEntities = (state: FlowProgressState, ids: number[]) =>
  addPayloadToState(
    state,
    ids.map((id) => state.entities[id]).map((ent) => cleanUpEntity(ent))
  );

const cleanUpEntity = (entity: FlowProgressItemModel) => {
  const { ...cleaned } = entity;
  return cleaned;
};
