import { createPagerActionGroup } from '../../tools/reducer-helper/create-pager-action-group.factory';
import { SystemMessageDetailsModel } from '../models/system-message-details.model';
import { SystemMessageSearchModel } from '../models/system-message-search.model';


export const systemMessageAction = createPagerActionGroup<
  'SystemMessage',
  SystemMessageDetailsModel,
  SystemMessageSearchModel
>({
  source: 'SystemMessage',
});
