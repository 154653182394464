import { Expose, Transform } from 'class-transformer';

import { WebsocketMessageType } from './websocket-message-type';

export class WebsocketWalletChange {
  @Expose()
  @Transform(() => WebsocketMessageType.WalletChange)
  readonly type = WebsocketMessageType.WalletChange;
  @Expose()
  balance: number;
}
