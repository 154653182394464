import { PagerServiceAbstract } from '../../tools/reducer-helper/pager-service';
import { TransactionCreateModel } from '../models/transaction-create.model';
import { TransactionDetailsModel } from '../models/transaction-details.model';
import { TransactionEditModel } from '../models/transaction-edit.model';
import { TransactionSearchModel } from '../models/transaction-search.model';

export abstract class TransactionServiceAbstract extends PagerServiceAbstract<
  TransactionDetailsModel,
  TransactionSearchModel,
  TransactionCreateModel,
  TransactionEditModel
> {}
