import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanDeactivateFn,
  RouterStateSnapshot,
} from '@angular/router';

import { Store } from '@ngrx/store';

import { SessionActions } from '../store/actions/session.actions';

export const sessionSelectedUserCleanUpGuard: (
  exceptedRoutes: string[]
) => CanDeactivateFn<any> =
  (exceptedRoutes: string[]) =>
  (
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ) => {
    if (
      !new RegExp(/^\/(\d+)\//).test(nextState.url) &&
      correctRoute(exceptedRoutes, nextState.url)
    ) {
      const store = inject(Store);
      setTimeout(() =>
        store.dispatch(
          SessionActions.selectUser({ selectedId: null, skipNavigation: true })
        )
      );
    }
    return true;
  };

const correctRoute = (exceptUrls: string[], url: string) =>
  !exceptUrls.find((exceptUrl) => url.startsWith(exceptUrl));
