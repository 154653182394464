import { Expose, Type } from 'class-transformer';

import { CurrencyV2Model } from '../../common/models/currency-v2.model';



export class UserWalletModel {
  @Expose()
  payoutDate: string;
  @Expose()
  @Type(() => CurrencyV2Model)
  balance: CurrencyV2Model;
}
