import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable, map } from 'rxjs';

import { plainToClass, plainToClassFromExist } from 'class-transformer';

import { ResponseContainer } from '../../common/models/ResponseContainer';
import { SHORTLINK } from '../../common/tokens/short-link';
import { TiktokSongMetadataModel } from '../models/tiktok-song-metadata.model';
import { TiktokVideoMetadataModel } from '../models/tiktok-video-metadata.model';
import { TiktokVideoUrl } from '../models/tiktok-video-url.model';
import { TiktokServiceAbstract } from './tiktok.service.abstract';

@Injectable()
export class TiktokService extends TiktokServiceAbstract {
  private readonly metadataUrl = this.apiUrl + 'song/tiktok';
  private readonly videoMetadataUrl = 'https://www.tiktok.com/oembed';
  private readonly realLinkUrl = `${this.apiUrl}tiktok/short-link`;
  constructor(
    private http: HttpClient,
    @Inject(SHORTLINK)
    private apiUrl: string
  ) {
    super();
  }

  getSongMetadata(videoUrl: string): Observable<ResponseContainer<TiktokSongMetadataModel>> {
    const params = new HttpParams({ fromObject: { url: videoUrl } });
    return this.http
      .get(this.metadataUrl, { params })
      .pipe(map((resp) => plainToClassFromExist(new ResponseContainer(TiktokSongMetadataModel), resp)));
  }

  getVideoMetadata(videoUrl: string): Promise<TiktokVideoMetadataModel> {
    const params = new HttpParams({ fromObject: { url: videoUrl } });
    return this.http
      .get(this.videoMetadataUrl, { params })
      .pipe(map((resp) => plainToClass(TiktokVideoMetadataModel, resp)))
      .toPromise();
  }

  getRealLink(url: string) {
    const params = new HttpParams({ fromObject: { url } });
    return this.http
      .get(this.realLinkUrl, { params })
      .pipe(map((resp) => plainToClassFromExist(new ResponseContainer(TiktokVideoUrl), resp)))
      .toPromise();
  }
}
