import { createPagerActionGroup } from '../../../tools/reducer-helper/create-pager-action-group.factory';
import { SubGenreModel } from '../../models/sub-genre.model';

export const subGenreAction = createPagerActionGroup<
  'SubGenre',
  SubGenreModel,
  null
>({
  source: 'SubGenre',
});
