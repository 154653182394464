import { PagerEntity } from '../../../common/models/PagerEntity';
import { createPagerReducer } from '../../../tools/reducer-helper/create-pager-reducer.factory';
import { IHasPagerEntities } from '../../../tools/reducer-helper/model/has-pager-entities';
import { ContentCategoryModel } from '../../models/content-category.model';
import { contentCategoryAction } from '../action';

export type ContentCategoryState = IHasPagerEntities<
  ContentCategoryModel,
  null
>;
const initialPager = new PagerEntity<ContentCategoryModel, null>({
  id: 'default',
  pages: {},
  selectedPageNum: 1,
  maxCount: null,
  limit: 50,
  asc: false,
  orderBy: 'id',
  search: null,
});

const contentCategoryInitialState: ContentCategoryState = {
  entities: {},
  ids: [],
  initialPager: initialPager,
  pagers: {},
  pagerIds: [],
  selectedId: null,
};

export const contentCategoryReducer = createPagerReducer({
  details: ContentCategoryModel,
  filter: null,
  initialState: contentCategoryInitialState,
  actions: contentCategoryAction,
  source: 'ContentCategory',
});
