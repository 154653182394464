import { PagerEntity } from '../../../common/models/PagerEntity';
import { createPagerReducer } from '../../../tools/reducer-helper/create-pager-reducer.factory';
import { IHasPagerEntities } from '../../../tools/reducer-helper/model/has-pager-entities';
import { SongDetailsModel } from '../../models/song-details.model';
import { SongSearchModel } from '../../models/song-search.model';
import { songAction } from '../action/song.actions';

export type SongState = IHasPagerEntities<SongDetailsModel, SongSearchModel>;
const initialPager = new PagerEntity<SongDetailsModel, SongSearchModel>({
  id: 'default',
  pages: {},
  selectedPageNum: 1,
  maxCount: null,
  limit: 50,
  asc: false,
  orderBy: 'id',
  search: new SongSearchModel({
    search: '',
  }),
});

const songInitialState: SongState = {
  entities: {},
  ids: [],
  initialPager: initialPager,
  pagers: {},
  pagerIds: [],
  selectedId: null,
};

export const songReducer = createPagerReducer({
  details: SongDetailsModel,
  filter: SongSearchModel,
  initialState: songInitialState,
  actions: songAction,
  source: 'Song',
});
