import { Inject, Injectable } from '@angular/core';

import { Subject } from 'rxjs';
import { filter, map, share, tap } from 'rxjs/operators';

import { plainToClass } from 'class-transformer';
import { IMessageEvent, w3cwebsocket as WebsocketClient } from 'websocket';

import { ENVIRONMENT } from '@songpush/core/common/tokens';
import { filterEmpty } from '@songpush/core/tools/map';
import {
  WebsocketIdentify,
  WebsocketMessage,
  WebsocketMessageType,
} from '@songpush/core/websocket/models';

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  private readonly messageSubject = new Subject<{
    message: IMessageEvent;
    id: string;
  }>();

  private readonly mappedMessage = this.messageSubject.pipe(
    map((event) => {
      try {
        const domain = JSON.parse(event.message.data as string);
        return plainToClass(WebsocketMessage, {
          data: { ...domain, type: `${domain.type}` },
          id: event.id,
        });
      } catch {
        return null;
      }
    }),
    filterEmpty(),
    map((data) => data as WebsocketMessage),
    share()
  );

  private readonly mappedResponse = this.mappedMessage.pipe(
    map((message) => message.data),
    share()
  );

  clients: Record<string, WebsocketClient> = {};
  socketIds: Record<string, string> = {};

  constructor(@Inject(ENVIRONMENT) private environment: any) {
    this.mappedMessage
      .pipe(
        filter(
          ({ data: { type } }) =>
            type === WebsocketMessageType.WebsocketIdentify
        ),
        map((message) => ({
          socketId: (message.data as WebsocketIdentify).token,
          id: message.id,
        })),
        tap(
          ({ id, socketId }) =>
            (this.socketIds = { ...this.socketIds, [id]: socketId })
        )
      )
      .subscribe();
  }

  connect(token: string) {
    if (!this.clients[token]) {
      // setTimeout(() => this.clients[token]?.close(), 5000);
      this.clients[token] = new WebsocketClient(
        this.environment.websocketUrl,
        token
      );
      this.clients[token].onmessage = (message) =>
        this.messageSubject.next({ message, id: token });

      this.clients[token].onopen = () => {
        // console.log('wsopen', token);
      };
      this.clients[token].onerror = (err) => {
        // console.log('wserror', token, err);
        // console.log(err.message, err.name);
      };

      this.clients[token].onclose = (event) => {
        // console.log('closed', token, event);

        if (this.clients[token]) {
          this.removeClient(token);
          if (event.code !== 4000) {
            this.connect(token);
          }
        }
      };
    }
  }

  removeClient(token: string) {
    this.clients = Object.keys(this.clients)
      .filter((clientKey) => clientKey !== token)
      .reduce(
        (prev, curr) => ({ ...prev, [curr]: this.clients[curr] }),
        {} as Record<string, WebsocketClient>
      );

    this.socketIds = Object.keys(this.socketIds)
      .filter((clientKey) => clientKey !== token)
      .reduce(
        (prev, curr) => ({ ...prev, [curr]: this.socketIds[curr] }),
        {} as Record<string, string>
      );
  }

  close(token: string) {
    const client = this.clients[token];
    if (client) {
      this.removeClient(token);
      client.close(4000);
    }
  }

  onMessage() {
    return this.mappedResponse;
  }

  getSocketId(token: string) {
    return this.socketIds[token];
  }
}
