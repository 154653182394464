import { PagerEntity } from '../../common/models/PagerEntity';
import { createPagerReducer } from '../../tools/reducer-helper/create-pager-reducer.factory';
import { IHasPagerEntities } from '../../tools/reducer-helper/model/has-pager-entities';
import { DealLimitDetailsModel } from '../models/deal-limit-details.model';
import { DealLimitSearchModel } from '../models/deal-limit-search.model';
import { dealLimitAction } from './deal-limit.actions';

export type DealLimitState = IHasPagerEntities<
  DealLimitDetailsModel,
  DealLimitSearchModel
>;
const initialPager = new PagerEntity<
  DealLimitDetailsModel,
  DealLimitSearchModel
>({
  id: 'default',
  pages: {},
  selectedPageNum: 1,
  maxCount: null,
  limit: 50,
  asc: false,
  orderBy: 'id',
  search: new DealLimitSearchModel({}),
});

const dealLimitInitialState: DealLimitState = {
  entities: {},
  ids: [],
  initialPager: initialPager,
  pagers: {},
  pagerIds: [],
  selectedId: null,
};

export const dealLimitReducer = createPagerReducer({
  details: DealLimitDetailsModel,
  filter: DealLimitSearchModel,
  initialState: dealLimitInitialState,
  actions: dealLimitAction,
  source: 'DealLimit',
});
