import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, inject } from '@angular/core';

import { firstValueFrom } from 'rxjs';

import { IWindowManagerService, windowManagerService } from '@songpush/core/common/services/window-manger';
import { API_URL, ENVIRONMENT } from '@songpush/core/common/tokens';
import { OAuthServiceAbstract } from '@songpush/core/user/services';

declare const FB: any;
declare const googleAuthInstance: gapi.auth2.GoogleAuth;
@Injectable()
export class OAuthService extends OAuthServiceAbstract {
  private http = inject(HttpClient);
  private apiUrl = inject(API_URL);

  constructor(
    @Inject(ENVIRONMENT)
    private environment,
    @Inject(windowManagerService)
    private windowManager: IWindowManagerService
  ) {
    super();
  }
  getFacebookPermissions() {
    return this.http.get<string[]>(`${this.apiUrl}datas/instagram-graph-oauth-perm-list.json`);
  }

  instagram(): Promise<string> {
    const scopes = 'user_profile,user_media';

    const url =
      'https://www.instagram.com/oauth/authorize?' +
      'client_id=' +
      2922546368021025 +
      '&redirect_uri=' +
      encodeURIComponent(location.origin + '/instalogin') +
      '&scope=user_profile,user_media' +
      '&response_type=code';

    return this.windowManager.customOAuth(url, 'code', false);
  }

  async google(): Promise<string> {
    const scopes = [
      'https://www.googleapis.com/auth/userinfo.profile',
      'https://www.googleapis.com/auth/userinfo.email',
      'https://www.googleapis.com/auth/youtube.readonly',
    ];

    const url =
      'https://accounts.google.com/o/oauth2/v2/auth?' +
      'client_id=' +
      encodeURIComponent(this.environment.googleClientId) +
      '&scope=' +
      encodeURIComponent(scopes.join(' ')) +
      '&response_type=code' +
      '&redirect_uri=' +
      encodeURIComponent(location.origin + '/googlelogin');

    return this.windowManager.customOAuth(url, 'code');
  }

  spotify(): Promise<string> {
    const scopes = 'user-read-private user-read-email';
    const url =
      'https://accounts.spotify.com/authorize' +
      '?response_type=code' +
      '&client_id=' +
      this.environment.spotifyClientId +
      (scopes ? '&scope=' + encodeURIComponent(scopes) : '') +
      '&redirect_uri=' +
      encodeURIComponent(location.origin + '/spotifylogin');

    return this.windowManager.customOAuth(url, 'code');
  }
  twitch(): Promise<string> {
    const scopes = 'user_read';

    const url =
      'https://id.twitch.tv/oauth2/authorize' +
      '?client_id=' +
      this.environment.twitchClientId +
      '&redirect_uri=' +
      encodeURIComponent(location.origin + '/twitchlogin') +
      '&response_type=token' +
      '&scope=' +
      encodeURIComponent(scopes);

    return this.windowManager.customOAuth(url, 'access_token');
  }

  tiktok(): Promise<string> {
    const csrfState = Math.random().toString(36).substring(2);
    const scopes = ['user.info.basic', 'user.info.profile', 'user.info.stats', 'video.list'];
    const responseType = ['code'];
    const url =
      'https://www.tiktok.com/v2/auth/authorize/' +
      '?client_key=' +
      encodeURIComponent(this.environment.tiktokClientId) +
      '&scope=' +
      encodeURIComponent(scopes.join(',')) +
      '&response_type=' +
      encodeURIComponent(responseType.join(',')) +
      '&redirect_uri=' +
      encodeURIComponent(location.origin + '/tiktoklogin') +
      '&state=' +
      csrfState;

    return this.windowManager.customOAuth(url, 'code');
  }

  apple(): Promise<string> {
    const scopes = []; // ['email', 'name'];
    const responseType = ['code', 'id_token'];
    const url =
      'https://appleid.apple.com/auth/authorize' +
      '?client_id=' +
      encodeURIComponent(this.environment.appleClientId) +
      '&redirect_uri=' +
      encodeURIComponent(location.origin + '/applelogin') +
      '&response_type=' +
      encodeURIComponent(responseType.join(' ')) +
      '&scope=' +
      encodeURIComponent(scopes.join(' ')) +
      '&response_mode=fragment';
    return this.windowManager.customOAuth(url, 'code');
  }

  async facebookInstagram(): Promise<string> {
    const window = this.windowManager.newWindow('about:blank');
    const permissions = await firstValueFrom(this.getFacebookPermissions());
    const redirectUri = `${location.origin}/facebooklogin`;
    const appId = this.environment.facebookAppId;

    const url =
      'https://www.facebook.com/v20.0/dialog/oauth?' +
      `client_id=${appId}` +
      `&redirect_uri=${redirectUri}` +
      `&scope=${permissions.join(' ')}` +
      '&response_type=token' +
      '&display=popup';

    return this.windowManager.customOAuth(url, 'access_token', true, window);
  }
}
