import { Expose } from 'class-transformer';

import { CompanyType } from './company-type';

export class CompanyEditModel {
  @Expose()
  type: CompanyType;
  @Expose()
  taxNumber?: string;
  @Expose()
  firstName: string;
  @Expose()
  lastName: string;
  @Expose()
  name?: string;
  @Expose()
  countryId: number;
  @Expose()
  city: string;
  @Expose()
  zip: string;
  @Expose()
  address: string;
  @Expose()
  phoneCountryId?: number;
  @Expose()
  phone?: string;
  @Expose()
  email: string;
  @Expose()
  salesTax: boolean;
}
