import { createSelector } from '@ngrx/store';

import { WalletType } from '../models/wallet-type';
import { WalletDetailsModel } from '../models/wallet-details.model';
import { WalletSearchModel } from '../models/wallet-search.model';
import { createPagerSelectors } from '../../tools/reducer-helper/create-pager-selector.factory';
import { WalletState } from './wallet.reducer';

const createWalletSelector = () => {
  const collectionSelectors = createPagerSelectors<
    WalletDetailsModel,
    WalletSearchModel,
    WalletState
  >('wallet');

  const selectPayoutWallet = createSelector(
    collectionSelectors.selectAll,
    (all) => all.find(({ type }) => type === WalletType.payout)
  );
  const selectPayoutWalletId = createSelector(
    selectPayoutWallet,
    (wallet) => wallet?.id
  );
  const selectPayoutBalance = createSelector(
    selectPayoutWallet,
    (wallet) => wallet?.balance
  );
  const selectPayoutNetBalance = createSelector(
    selectPayoutBalance,
    (balance) => balance?.netAmount
  );
  const selectPayoutGrossBalance = createSelector(
    selectPayoutBalance,
    (balance) => balance?.grossAmount
  );
  const selectNextPayout = createSelector(
    selectPayoutWallet,
    (wallet) => wallet?.payoutDate
  );

  const selectPayinWallet = createSelector(
    collectionSelectors.selectAll,
    (all) => all.find(({ type }) => type === WalletType.payin)
  );

  const selectPayinWalletId = createSelector(
    selectPayinWallet,
    (wallet) => wallet?.id
  );
  const selectPayinBalance = createSelector(
    selectPayinWallet,
    (wallet) => wallet?.balance
  );
  const selectPayinNetBalance = createSelector(
    selectPayinBalance,
    (balance) => balance?.netAmount
  );
  const selectPayinGrossBalance = createSelector(
    selectPayinBalance,
    (balance) => balance?.grossAmount
  );
  const selectPayinBankingAlias = createSelector(
    selectPayinWallet,
    (wallet) => wallet?.bankingAlias
  );

  return {
    ...collectionSelectors,
    selectPayoutWalletId,
    selectPayoutWallet,
    selectPayoutBalance,
    selectPayoutGrossBalance,
    selectPayoutNetBalance,
    selectNextPayout,
    selectPayinWalletId,
    selectPayinWallet,
    selectPayinBalance,
    selectPayinNetBalance,
    selectPayinGrossBalance,
    selectPayinBankingAlias,
  };
};

export const walletSelector = createWalletSelector();
