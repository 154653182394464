
import { createPagerActionGroup } from '../../tools/reducer-helper/create-pager-action-group.factory';
import { TransactionDetailsModel } from '../models/transaction-details.model';
import { TransactionSearchModel } from '../models/transaction-search.model';


export const transactionAction = createPagerActionGroup<'Transaction', TransactionDetailsModel, TransactionSearchModel>(
  {
    source: 'Transaction',
  }
);
