import { HttpParams } from '@angular/common/http';

export const httpParamOverwrite = () => {
  HttpParams.prototype.toString = function () {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const me = this;
    this.init();
    return this.keys()
      .map((key) => {
        let eKey = me.encoder.encodeKey(key);
        const values = me.map.get(key);
        if (values.length > 1) {
          eKey += '[]';
        }
        return values.map((value) => eKey + '=' + me.encoder.encodeValue(value)).join('&');
      })
      .filter((val) => !!val)
      .join('&');
  };
};
