<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="'logo'">
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 2028 361"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xml:space="preserve"
      xmlns:serif="http://www.serif.com/"
      style="fill-rule: evenodd; clip-rule: evenodd; stroke-linejoin: round; stroke-miterlimit: 2"
    >
      <g>
        <path
          d="M875.875,204.292c0,-48.667 -38.333,-87.667 -88.333,-87.667c-50,0 -88.334,39 -88.334,87.667c0,48.666 38.334,87.666 88.334,87.666c50,0 88.333,-39 88.333,-87.666m-28.667,-0c0,33.333 -25.666,60.666 -59.666,60.666c-34,0 -59.334,-27.333 -59.334,-60.666c0,-33.334 25,-60.667 59.334,-60.667c34.333,0 59.666,27.333 59.666,60.667"
          style="fill: currentColor; fill-rule: nonzero"
        />
        <path
          d="M931.958,288.292c2.292,-0 4.167,-1.875 4.167,-4.167l0,-82.833c0,-32 19.667,-57.667 47,-57.667c25.667,-0 43.333,15.333 43.333,53.333l0,87.167c0,2.292 1.875,4.167 4.167,4.167l20,-0c2.292,-0 4.167,-1.875 4.167,-4.167l-0,-91.167c-0,-54 -28.667,-76.333 -64.334,-76.333c-33,-0 -50,18.667 -56.666,34.667l-0,-26.834c-0,-2.291 -1.875,-4.166 -4.167,-4.166l-17.667,-0c-2.291,-0 -4.166,1.875 -4.166,4.166l-0,159.667c-0,2.292 1.875,4.167 4.166,4.167l20,-0Z"
          style="fill: currentColor; fill-rule: nonzero"
        />
        <path
          d="M1261.25,274.625l0,-150.167c0,-2.291 -1.875,-4.166 -4.167,-4.166l-18.333,-0c-2.292,-0 -4.167,1.875 -4.167,4.166l0,23.167c-9.666,-14.667 -27.333,-31 -64.333,-31c-46.333,0 -85.333,35.333 -85.333,84c-0,48.667 39,83.667 85.333,83.667c31.667,-0 50.333,-13 62.667,-27.667l-0,20.667c-0,38.333 -26.334,56 -58,56c-27.792,-0 -45.25,-13.709 -53.334,-26.5c-1.208,-1.917 -3.708,-2.459 -5.625,-1.292l-17.291,10.417c-1.959,1.208 -2.584,3.75 -1.417,5.708c11.083,18.958 35.417,38.667 77.667,38.667c46.666,-0 86.333,-25.667 86.333,-85.667m-28.333,-74c-0,33.333 -25.667,56.667 -59.667,56.667c-34,-0 -59.333,-23.334 -59.333,-56.667c-0,-33.333 25,-57 59.333,-57c34.333,-0 59.667,23.667 59.667,57"
          style="fill: currentColor; fill-rule: nonzero"
        />
        <path
          d="M1480.21,131.958c0,-50 -38,-77 -74.666,-77l-90.834,0c-2.291,0 -4.166,1.875 -4.166,4.167l-0,225c-0,2.292 1.875,4.167 4.166,4.167l50,-0c2.292,-0 4.167,-1.875 4.167,-4.167l0,-75.167l36.667,0c36.666,0 74.666,-27 74.666,-77m-58.333,0c0,17.334 -11.333,27 -26.333,27l-26.667,0l0,-54l26.667,0c15.333,0 26.333,9.667 26.333,27"
          style="fill: currentColor; fill-rule: nonzero"
        />
        <path
          d="M1586.21,291.958c36.667,0 84,-22 84,-80l0,-94.166c0,-2.292 -1.875,-4.167 -4.166,-4.167l-48.334,-0c-2.291,-0 -4.166,1.875 -4.166,4.167l-0,87.166c-0,23.334 -10.667,33.667 -27.334,33.667c-16.666,-0 -27.333,-10.333 -27.333,-33.667l0,-87.166c0,-2.292 -1.875,-4.167 -4.167,-4.167l-48.333,-0c-2.292,-0 -4.167,1.875 -4.167,4.167l0,94.166c0,58 47.334,80 84,80"
          style="fill: currentColor; fill-rule: nonzero"
        />
        <path
          d="M1748.92,163.292c-0,-5 5.333,-8.334 13.666,-8.334c9,0 20.167,4.334 28.375,11.5c1.584,1.375 3.917,1.292 5.459,-0.125l27.916,-25.458c1.75,-1.583 1.834,-4.375 0.167,-6c-16,-15.5 -34.75,-24.917 -62.917,-24.917c-40,0 -66,22.334 -66,54.667c0,37 31.334,50.333 57,57.667c19,5.333 29.334,7.666 29.334,14.333c-0,5 -6.667,8.667 -20,8.667c-10.709,-0 -24.209,-3.375 -34.334,-12.917c-1.583,-1.5 -4.041,-1.417 -5.625,-0l-28.666,26.75c-1.667,1.583 -1.834,4.25 -0.209,5.875c17.459,17.958 44,26.958 70.542,26.958c43.333,0 71.667,-24.333 71.667,-56.333c-0,-41.333 -37,-53 -63.667,-60c-15,-4 -22.667,-7.667 -22.667,-12.333"
          style="fill: currentColor; fill-rule: nonzero"
        />
        <path
          d="M1912.04,288.292c2.291,-0 4.166,-1.875 4.166,-4.167l0,-85.25c0,-21.625 10.625,-33.583 27.25,-33.583c16.625,-0 27.25,11.958 27.25,33.583l0,85.25c0,2.292 1.875,4.167 4.167,4.167l48.167,-0c2.291,-0 4.166,-1.875 4.166,-4.167l0,-99.208c0,-54.834 -29.25,-74.459 -64.166,-74.459c-23.25,0 -38.542,9.292 -46.875,18.625l-0,-89.125c-0,-2.916 -2.917,-4.916 -5.667,-3.875l-48.167,18.459c-1.625,0.625 -2.666,2.166 -2.666,3.875l-0,225.708c-0,2.292 1.875,4.167 4.166,4.167l48.209,-0Z"
          style="fill: currentColor; fill-rule: nonzero"
        />
      </g>
      <g>
        <path
          d="M53.417,173.25l-11.417,29.75c-2.333,6.125 -8.333,10.25 -14.875,10.25c-1.958,0 -3.875,-0.375 -5.708,-1.083c-8.209,-3.167 -12.334,-12.375 -9.167,-20.625l11.417,-29.75c2.333,-6.125 8.333,-10.25 14.875,-10.25c1.958,-0 3.875,0.375 5.708,1.083c8.208,3.167 12.333,12.375 9.167,20.583m-5.167,-31.041c-3.208,-1.209 -6.5,-1.792 -9.708,-1.792c-10.917,-0 -21.167,6.625 -25.334,17.417l-11.416,29.791c-5.375,13.959 1.625,29.667 15.583,35.042c3.208,1.208 6.5,1.792 9.708,1.792c10.917,-0 21.167,-6.625 25.334,-17.417l11.416,-29.75c5.375,-14 -1.625,-29.667 -15.583,-35.042"
          style="fill: url(#_LinearLogo1); fill-rule: nonzero"
        />
        <path
          d="M149.417,163.542l-42.542,110.833c-2.333,6.125 -8.333,10.25 -14.917,10.25c-1.958,-0 -3.875,-0.375 -5.708,-1.083c-3.958,-1.542 -7.125,-4.5 -8.833,-8.417c-1.75,-3.875 -1.834,-8.208 -0.334,-12.208l42.542,-110.834c2.333,-6.125 8.333,-10.25 14.875,-10.25c1.958,0 3.875,0.375 5.708,1.084c8.209,3.166 12.334,12.375 9.167,20.625m-5.167,-31.042c-3.208,-1.208 -6.5,-1.792 -9.708,-1.792c-10.917,0 -21.167,6.625 -25.333,17.417l-42.542,110.833c-5.375,14 1.625,29.667 15.583,35.042c3.209,1.208 6.5,1.792 9.709,1.792c10.916,-0 21.166,-6.625 25.333,-17.417l42.542,-110.833c5.375,-14 -1.625,-29.667 -15.584,-35.042"
          style="fill: url(#_LinearLogo2); fill-rule: nonzero"
        />
        <path
          d="M180.958,321.708c-2.333,6.125 -8.333,10.25 -14.916,10.25c-1.959,0 -3.875,-0.375 -5.709,-1.083c-4,-1.542 -7.125,-4.5 -8.833,-8.417c-1.75,-3.875 -1.833,-8.208 -0.333,-12.208l55.458,-144.5c3.375,-7.875 12.5,-11.833 20.542,-8.75c8.125,3.125 12.125,12.083 9.208,20.25l-55.417,144.458Zm105.709,-319.916c-3.209,-1.209 -6.5,-1.792 -9.709,-1.792c-10.916,0 -21.166,6.625 -25.333,17.417l-110.875,288.875c-5.375,14 1.625,29.666 15.583,35.041c3.209,1.209 6.5,1.792 9.709,1.792c10.916,0 21.166,-6.625 25.333,-17.417l110.875,-288.875c5.375,-14 -1.625,-29.666 -15.583,-35.041"
          style="fill: url(#_LinearLogo3); fill-rule: nonzero"
        />
        <path
          d="M360.917,48.875c-3.209,-1.208 -6.5,-1.792 -9.709,-1.792c-10.916,0 -21.166,6.625 -25.333,17.417l-42.542,110.833c-5.375,13.959 1.625,29.667 15.584,35.042c3.208,1.208 6.5,1.792 9.708,1.792c10.917,-0 21.167,-6.625 25.333,-17.417l42.542,-110.833c5.375,-14 -1.625,-29.667 -15.583,-35.042"
          style="fill: url(#_LinearLogo4); fill-rule: nonzero"
        />
        <path
          d="M425.917,120.333c-3.209,-1.208 -6.5,-1.791 -9.709,-1.791c-10.916,-0 -21.166,6.625 -25.333,17.416l-11.417,29.75c-5.375,13.959 1.625,29.667 15.584,35.042c3.208,1.208 6.5,1.792 9.708,1.792c10.917,-0 21.167,-6.625 25.333,-17.417l11.417,-29.75c5.375,-14 -1.625,-29.667 -15.583,-35.042"
          style="fill: url(#_LinearLogo5); fill-rule: nonzero"
        />
      </g>
      <path
        d="M599.792,152.625c-26.667,-7.333 -49.334,-17.667 -49.334,-41.333c0,-21.667 19.334,-33.334 42.667,-33.334c19.542,0 33.917,6.667 46.833,17.542c0.459,0.375 1.75,1.542 3.417,3.083c2.167,1.959 5.625,1.167 6.667,-1.583c2.291,-5.958 5.833,-15.083 7.583,-19.667c0.625,-1.666 0.125,-3.541 -1.25,-4.666c-18.5,-14.667 -38.125,-22.042 -63.208,-22.042c-43.334,0 -71.667,28 -71.667,61.333c-0,43.334 35,60 70,69.334c28.333,7.333 53,16.666 53,42.666c-0,24.334 -17.667,41.334 -48.333,41.334c-26.25,-0 -42.834,-13.5 -56.625,-28.084c-0.5,-0.541 -1.75,-1.875 -3.209,-3.458c-2.083,-2.25 -5.833,-1.542 -6.958,1.333l-8,20.875c-0.583,1.5 -0.25,3.25 0.875,4.417c20.125,20.75 42.625,32.25 73.917,32.25c46.666,0 77.333,-26.667 77.333,-68.667c-0,-46.333 -39,-62 -73.667,-71.333l-0.041,0Z"
        style="fill: currentColor; fill-rule: nonzero"
      />
    </svg>
  </ng-container>
  <ng-container *ngSwitchCase="'mark'">
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 1999 1999"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xml:space="preserve"
      xmlns:serif="http://www.serif.com/"
      style="fill-rule: evenodd; clip-rule: evenodd; stroke-linejoin: round; stroke-miterlimit: 2"
    >
      <g>
        <path
          d="M1409.67,577.788l-167.475,436.32c-21.129,55.034 6.35,116.792 61.4,137.917c12.58,4.825 25.521,7.117 38.234,7.117c42.908,-0 83.379,-26.063 99.679,-68.529l167.492,-436.305c21.108,-55.05 -6.38,-116.791 -61.413,-137.916c-12.579,-4.825 -25.521,-7.121 -38.233,-7.121c-42.913,-0 -83.379,26.062 -99.684,68.517Z"
          style="fill: url(#_LinearMark1); fill-rule: nonzero"
        />
        <path
          d="M178.86,945.308l-44.963,117.134c-21.125,55.033 6.367,116.775 61.417,137.916c12.579,4.825 25.508,7.121 38.233,7.121c42.913,0 83.38,-26.062 99.684,-68.533l44.962,-117.121c21.109,-55.046 -6.383,-116.783 -61.412,-137.912c-12.584,-4.821 -25.529,-7.121 -38.238,-7.121c-42.908,-0 -83.375,26.066 -99.683,68.516Zm32.2,214.013c-32.329,-12.421 -48.521,-48.8 -36.121,-81.133l44.963,-117.117c9.25,-24.129 32.825,-40.317 58.641,-40.317c7.659,0 15.221,1.409 22.488,4.196c32.316,12.404 48.521,48.8 36.121,81.117l-44.963,117.133c-9.25,24.112 -32.821,40.317 -58.642,40.317c-7.658,-0 -15.22,-1.413 -22.487,-4.196Z"
          style="fill: url(#_LinearMark2); fill-rule: nonzero"
        />
        <path
          d="M1665.53,859.063l-44.963,117.133c-21.129,55.033 6.363,116.792 61.413,137.917c12.583,4.825 25.508,7.104 38.237,7.104c42.913,-0 83.375,-26.05 99.683,-68.517l44.963,-117.121c21.108,-55.046 -6.371,-116.787 -61.417,-137.916c-12.579,-4.825 -25.525,-7.117 -38.233,-7.117c-42.913,-0 -83.379,26.067 -99.683,68.517Z"
          style="fill: url(#_LinearMark3); fill-rule: nonzero"
        />
        <path
          d="M1117.41,392.488l-436.55,1137.26c-21.129,55.05 6.363,116.787 61.413,137.916c12.583,4.821 25.508,7.117 38.22,7.117c42.925,0 83.392,-26.062 99.696,-68.517l436.534,-1137.26c21.129,-55.046 -6.363,-116.787 -61.392,-137.912c-12.583,-4.825 -25.525,-7.121 -38.238,-7.121c-42.908,-0 -83.375,26.067 -99.683,68.517Zm-359.392,1234.13c-15.662,-6.004 -28.029,-17.763 -34.854,-33.084c-6.821,-15.32 -7.283,-32.379 -1.266,-48.045l218.345,-568.892c13.217,-30.95 49.209,-46.571 80.884,-34.412c31.933,12.25 47.716,47.633 36.25,79.666l-218.225,568.646c-9.271,24.113 -32.842,40.321 -58.659,40.321c-7.645,-0 -15.208,-1.413 -22.475,-4.2Z"
          style="fill: url(#_LinearMark4); fill-rule: nonzero"
        />
        <path
          d="M556.714,907.096l-167.475,436.321c-21.125,55.033 6.35,116.791 61.4,137.92c12.583,4.821 25.525,7.117 38.238,7.117c42.908,0 83.375,-26.062 99.679,-68.533l167.491,-436.304c21.113,-55.05 -6.379,-116.792 -61.412,-137.917c-12.583,-4.821 -25.525,-7.117 -38.238,-7.117c-42.912,0 -83.375,26.063 -99.683,68.513Zm-90.329,533.2c-15.646,-6.004 -28.033,-17.763 -34.854,-33.088c-6.825,-15.316 -7.267,-32.375 -1.267,-48.046l167.492,-436.304c9.254,-24.125 32.825,-40.32 58.641,-40.32c7.663,-0 15.221,1.416 22.488,4.204c32.317,12.4 48.525,48.796 36.121,81.129l-167.492,436.304c-9.254,24.113 -32.821,40.321 -58.637,40.321c-7.663,-0 -15.225,-1.413 -22.492,-4.2Z"
          style="fill: url(#_LinearMark5); fill-rule: nonzero"
        />
      </g>
    </svg>
  </ng-container>
  <ng-container *ngSwitchCase="'defs'">
    <svg>
      <defs>
        <linearGradient
          id="_LinearMark1"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(243.998,-635.635,635.635,243.998,1303.59,1152.03)"
        >
          <stop offset="0" style="stop-color: #fe3842; stop-opacity: 1" />
          <stop offset="1" style="stop-color: #fc1c67; stop-opacity: 1" />
        </linearGradient>
        <linearGradient
          id="_LinearMark2"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(121.472,-316.446,316.446,121.472,195.309,1200.36)"
        >
          <stop offset="0" style="stop-color: #fe3842; stop-opacity: 1" />
          <stop offset="1" style="stop-color: #fc1c67; stop-opacity: 1" />
        </linearGradient>
        <linearGradient
          id="_LinearMark3"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(121.472,-316.446,316.446,121.472,1681.97,1114.11)"
        >
          <stop offset="0" style="stop-color: #fe3842; stop-opacity: 1" />
          <stop offset="1" style="stop-color: #fc1c67; stop-opacity: 1" />
        </linearGradient>
        <linearGradient
          id="_LinearMark4"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(513.061,-1336.57,1336.57,513.061,742.274,1667.66)"
        >
          <stop offset="0" style="stop-color: #fe3842; stop-opacity: 1" />
          <stop offset="1" style="stop-color: #fc1c67; stop-opacity: 1" />
          <stop offset="1" style="stop-color: #fc1c67; stop-opacity: 1" />
        </linearGradient>
        <linearGradient
          id="_LinearMark5"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(243.998,-635.635,635.635,243.998,450.639,1481.34)"
        >
          <stop offset="0" style="stop-color: #fe3842; stop-opacity: 1" />
          <stop offset="1" style="stop-color: #fc1c67; stop-opacity: 1" />
        </linearGradient>

        <linearGradient
          id="_LinearLogo1"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(30.8333,-80.375,80.375,30.8333,17.4167,222.625)"
        >
          <stop offset="0" style="stop-color: #fe3842; stop-opacity: 1" />
          <stop offset="1" style="stop-color: #fc1c67; stop-opacity: 1" />
        </linearGradient>
        <linearGradient
          id="_LinearLogo2"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(1440.21,-6396.98,6396.98,1440.21,1975.41,11779)"
        >
          <stop offset="0" style="stop-color: #fe3842; stop-opacity: 1" />
          <stop offset="1" style="stop-color: #fc1c67; stop-opacity: 1" />
        </linearGradient>
        <linearGradient
          id="_LinearLogo3"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(130.333,-339.542,339.542,130.333,156.333,341.333)"
        >
          <stop offset="0" style="stop-color: #fe3842; stop-opacity: 1" />
          <stop offset="1" style="stop-color: #fc1c67; stop-opacity: 1" />
        </linearGradient>
        <linearGradient
          id="_LinearLogo4"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(1440.21,-6396.98,6396.98,1440.21,7225.11,8380.49)"
        >
          <stop offset="0" style="stop-color: #fe3842; stop-opacity: 1" />
          <stop offset="1" style="stop-color: #fc1c67; stop-opacity: 1" />
        </linearGradient>
        <linearGradient
          id="_LinearLogo5"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(486.565,-1620.36,1620.36,486.565,6603.18,4164.82)"
        >
          <stop offset="0" style="stop-color: #fe3842; stop-opacity: 1" />
          <stop offset="1" style="stop-color: #fc1c67; stop-opacity: 1" />
        </linearGradient>
      </defs>
    </svg>
  </ng-container>
</ng-container>
