import { makeEnvironmentProviders } from '@angular/core';

import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { DealFacade } from '../services/deal.facade';
import { DealService } from '../services/deal.service';
import { DealServiceAbstract } from '../services/deal.service.abstract';
import { DealLiveChangeEffects } from '../store/effect/deal-livechange.effects';
import { DealEffects } from '../store/effect/deal.effects';
import { dealReducer } from '../store/reducer/deal.reducer';

export const provideDealState = () =>
  makeEnvironmentProviders([
    provideState('deal', dealReducer),
    provideEffects([DealEffects, DealLiveChangeEffects]),
    { provide: DealServiceAbstract, useClass: DealService },
    DealFacade,
  ]);
