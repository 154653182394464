import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { filter, map, mergeMap } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { SessionSelector } from '../store/selectors/session.selector';

export const signedInGuard: CanActivateFn = () => {
  const store = inject(Store);
  const router = inject(Router);
  return store.select(SessionSelector.selectLoaded).pipe(
    filter((loaded) => loaded === true),
    mergeMap(() => store.select(SessionSelector.selectSignedIn)),
    map((signedIn) => {
      if (signedIn) {
        return true;
      } else {
        router.navigate(['/'], { replaceUrl: true });
        return false;
      }
    })
  );
};
