import { Observable } from 'rxjs';

import { ResponseContainer } from '../../common/models/ResponseContainer';
import { PagerServiceAbstract } from '../../tools/reducer-helper/pager-service';
import { MoodModel } from '../models';

export abstract class MoodServiceAbstract extends PagerServiceAbstract<
  MoodModel,
  null,
  null,
  null
> {
  detailsModel = MoodModel;
  filterModel = null;
  createModel = null;
  editModel = null;

  create(): Observable<ResponseContainer<MoodModel>> {
    throw 'not supported';
  }
  edit(): Observable<ResponseContainer<MoodModel>> {
    throw 'not supported';
  }
  get(): Observable<ResponseContainer<MoodModel>> {
    throw 'not supported';
  }
}
