import { createPagerSelectors } from '../../../tools/reducer-helper/create-pager-selector.factory';
import { InvoiceDetailsModel } from '../../models/invoice-details.model';
import { InvoiceSearchModel } from '../../models/invoice-search.model';
import { InvoiceState } from '../reducer/invoice.reducer';

export const invoiceSelector = createPagerSelectors<
  InvoiceDetailsModel,
  InvoiceSearchModel,
  InvoiceState
>('invoice');
