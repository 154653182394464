import { Expose, Transform } from 'class-transformer';

export class TiktokVideoMetadataModel {
  @Expose({ name: 'author_name' })
  @Transform(({ value }) => value?.split(/[,&]/))
  artists: string[];
  @Expose()
  title: string;
  @Expose({ name: 'thumbnail_url' })
  cover: string;
}
