import { createSelector } from '@ngrx/store';

import { createPagerSelectors } from '../../../tools/reducer-helper/create-pager-selector.factory';
import { CountrySearchModel } from '../../models/country-search.model';
import { CountryModel } from '../../models/country.model';
import { CountryState } from '../reducer/country.reducer';

const createCountrySelector = () => {
  const pagerSelector = createPagerSelectors<
    CountryModel,
    CountrySearchModel,
    CountryState
  >('country');

  const selectKnownIds = createSelector(
    pagerSelector.selectState,
    (state) => state.knownIds
  );

  return { ...pagerSelector, selectKnownIds };
};

export const countrySelector = createCountrySelector();
