import { Exclude, Expose } from 'class-transformer';

import { Identifier } from '../../tools/reducer-helper/model/identifier';
import { UserRelationType } from './user-relation-type';
import { UserType } from './user-type';

export class UserSearchModel {
  @Expose()
  search?: string;

  @Expose()
  ids?: number[];

  @Expose()
  type?: UserType;

  @Expose()
  teamIds?: Identifier[];

  @Exclude()
  realtion?: UserRelationType;

  constructor(ent?: Partial<UserSearchModel>) {
    if (ent) {
      Object.assign(this, ent);
    }
  }
}
