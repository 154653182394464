import { Injectable, inject } from '@angular/core';

import { Observable } from 'rxjs';

import { ResponseContainer } from '../../common/models/ResponseContainer';
import { API_URL } from '../../common/tokens/api-url';
import { toResponse } from '../../tools/map';
import { Identifier } from '../../tools/reducer-helper/model/identifier';
import { DealDetailsModel } from '../models';
import { DealServiceAbstract } from './deal.service.abstract';

@Injectable()
export class DealService extends DealServiceAbstract {
  url = `${inject(API_URL)}deal`;
  constructor() {
    super();
  }

  delete(id: Identifier): Observable<ResponseContainer<DealDetailsModel>> {
    const params = this.getParams();
    return this.http
      .delete(`${this.getDeleteUrl}/${id}`, { params })
      .pipe(toResponse(DealDetailsModel));
  }
}
