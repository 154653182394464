import { Expose, Transform } from 'class-transformer';

import { WebsocketMessageType } from './websocket-message-type';

export class WebsocketMatch {
  @Expose()
  @Transform(() => WebsocketMessageType.MatchCompleted)
  readonly type = WebsocketMessageType.MatchCompleted;
  @Expose()
  campaignIds: number[];
  @Expose()
  songId: number;
}
