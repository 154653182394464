import { Expose, Transform, Type } from 'class-transformer';

import { CountryModel } from '../../country/models/country.model';
import { UserType } from '../../user/models/user-type';
import { CompanyPayinProcess } from './company-payin-process';
import { CompanyType } from './company-type';

export class CompanyDetailsModel {
  @Expose()
  id: number;

  @Expose()
  type: CompanyType;
  @Expose()
  taxNumber?: string;
  @Expose()
  firstName: string;
  @Expose()
  lastName: string;
  @Expose()
  name?: string;
  @Expose()
  @Transform(({ obj }) => obj.country?.id, { toClassOnly: true })
  countryId: number;
  @Expose()
  @Type(() => CountryModel)
  country: CountryModel;
  @Expose()
  city: string;
  @Expose()
  zip: string;
  @Expose()
  address: string;
  @Expose()
  @Transform(({ obj }) => obj.phoneCountry?.id, { toClassOnly: true })
  phoneCountryId?: number;
  @Expose()
  @Type(() => CountryModel)
  phoneCountry?: CountryModel;
  @Expose()
  phone?: string;
  @Expose()
  email: string;
  @Expose()
  salesTax: boolean;
  @Expose()
  @Transform(
    ({ obj }) =>
      obj?.birthDate ||
      obj?.legalPersonType ||
      obj?.nationalityCountry ||
      obj?.phoneCountry
  )
  own: boolean;

  @Expose()
  campaignPayinProcess: CompanyPayinProcess;

  @Expose()
  enabledUserTypes: UserType[];

  @Expose()
  @Transform(({ value }) => parseFloat((value / 100).toFixed(10)), {
    toClassOnly: true,
  })
  campaignSepaAmount?: number;
}
