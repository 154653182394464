import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { PortalHostDirective } from './portal-host.directive';
import { PortalDirective } from './portal.directive';
import { PortalService } from './portal.service';

@NgModule({
  imports: [CommonModule, FormsModule, PortalHostDirective, PortalDirective],
  exports: [PortalHostDirective, PortalDirective],
})
export class PortalDirectiveModule {
  static forRoot(): ModuleWithProviders<PortalDirectiveModule> {
    return { ngModule: PortalDirectiveModule, providers: [PortalService] };
  }

  static forChild(): ModuleWithProviders<PortalDirectiveModule> {
    return {
      ngModule: PortalDirectiveModule,
    };
  }
}
