import { HttpParams } from '@angular/common/http';

import { createActionGroup, props } from '@ngrx/store';

export const DownloadActions = createActionGroup({
  source: 'Download',
  events: {
    download: props<{ url: string; mimeType: string; params: HttpParams }>(),
    downloadFailed: props<{ url: string; reason?: string }>(),
    downloadSuccess: props<{ url: string; uri?: string }>(),
  },
});
