
import { PagerServiceAbstract } from '../../tools/reducer-helper/pager-service';
import { DealLimitCreateModel } from '../models/deal-limit-create.model';
import { DealLimitDetailsModel } from '../models/deal-limit-details.model';
import { DealLimitEditModel } from '../models/deal-limit-edit.model';
import { DealLimitSearchModel } from '../models/deal-limit-search.model';

export abstract class DealLimitServiceAbstract extends PagerServiceAbstract<
  DealLimitDetailsModel,
  DealLimitSearchModel,
  DealLimitCreateModel,
  DealLimitEditModel
> {
  detailsModel = DealLimitDetailsModel;
  filterModel = DealLimitSearchModel;
  createModel = DealLimitCreateModel;
  editModel = DealLimitEditModel;
}
