import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { WebsocketService } from '../websocket/websocket.service';

@Injectable()
export class WebsocketHeaderInterceptorService implements HttpInterceptor {
  constructor(private websocketService: WebsocketService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers = req.headers;
    const wsToken = this.websocketService.getSocketId(headers.get('token'));
    if (wsToken) {
      let headers = req.headers;
      headers = headers.set('wsToken', wsToken);

      req = req.clone({ headers });
    }
    return next.handle(req);
  }
}
