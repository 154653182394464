import { ActionCreator, createReducer, on, ReducerTypes } from '@ngrx/store';
import { ClassConstructor } from 'class-transformer';

import { IHasPagerEntities } from './model/has-pager-entities';
import { IHasId } from './model/i-has-id';
import { PagerActions } from './model/pager-actions';
import {
  changeAllPagerReduce,
  changePagerReduce,
  deleteReduce,
  loadPageCompleteReduce,
  selectPageReduce,
  singleLoadCompletedReduce,
} from './state-modifier';
import { loadAllCompletedReduce } from './state-modifier/load-all-completed.reduce';
import { resetReduce } from './state-modifier/reset.reduce';

export const createPagerReducer = <
  K extends string,
  DetailsModel extends IHasId,
  FilterModel,
  Actions extends PagerActions<K, DetailsModel, FilterModel>,
  Pager extends IHasPagerEntities<DetailsModel, FilterModel>
>(
  params: {
    initialState: Pager;
    actions: Actions;
    source: K;
    details: ClassConstructor<DetailsModel>;
    filter: ClassConstructor<FilterModel>;
  },
  ...ons: ReducerTypes<Pager, readonly ActionCreator[]>[]
) => {
  const { initialState, actions } = params;
  return createReducer<Pager>(
    initialState,
    on(actions.selectPage, (state, action) =>
      selectPageReduce(state, action, state.initialPager)
    ),
    on(actions.loadPageCompleted, (state, action) =>
      loadPageCompleteReduce(state, action, state.initialPager)
    ),
    on(actions.loadAllCompleted, (state, action) =>
      loadAllCompletedReduce(state, action)
    ),
    on(actions.changePager, (state, action) =>
      changePagerReduce(state, action, state.initialPager)
    ),
    on(actions.changeAllPager, (state, action) =>
      changeAllPagerReduce(state, action, state.initialPager)
    ),
    on(actions.singleLoadCompleted, (state, action) =>
      singleLoadCompletedReduce(state, action)
    ),
    on(actions.deleteCompleted, (state, action) =>
      deleteReduce(state, action, state.initialPager)
    ),
    on(actions.resetState, (state) =>
      resetReduce(state, initialState as typeof state, state.initialPager)
    ),
    ...(ons ?? [])
  );
};
