import { Injectable, inject } from '@angular/core';

import { map } from 'rxjs/internal/operators/map';

import { createEffect, ofType } from '@ngrx/effects';

import { SessionActions } from '../../../session/store/actions/session.actions';
import { PagerEffects } from '../../../tools/reducer-helper/pager-effects';
import { SpotifyArtistDetailsModel } from '../../models/spotify-artist-details.model';
import { SpotifyArtistSearchModel } from '../../models/spotify-artist-search.model';
import { SpotifyArtistServiceAbstract } from '../../services/spotify-artist.service.abstract';
import { spotifyArtistAction } from '../action/spotify-artist.actions';
import { spotifyArtistSelector } from '../selector/spotify-artist.selector';

@Injectable()
export class SpotifyArtistEffects extends PagerEffects<
  'SpotifyArtist',
  SpotifyArtistDetailsModel,
  SpotifyArtistSearchModel,
  null,
  null
> {
  resetLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.logoutCompleted),
      map(() =>
        spotifyArtistAction.changeAllPager({
          matcher: () => true,
          payload: { maxCount: null },
        })
      )
    )
  );

  constructor() {
    super(
      spotifyArtistAction,
      spotifyArtistSelector,
      inject(SpotifyArtistServiceAbstract)
    );
  }
}
