import { Expose } from 'class-transformer';

import { UserType } from '../../user/models/user-type';
import { SocialType } from './social-type';

export class SocialCreateModel {
  @Expose()
  type: SocialType;
  @Expose({ name: 'code' })
  token: string;
  @Expose()
  userType: UserType;
  @Expose()
  version?: number;
  @Expose()
  platform?: 'web' | 'ios' | 'android';

  constructor(init?: Partial<SocialCreateModel>) {
    if (init) {
      Object.assign(this, init);
    }
  }
}
