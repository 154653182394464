import { Expose, Transform, Type } from 'class-transformer';

import { SocialModel } from '../../social/models/social.model';
import { TeamDetailsModel } from '../../team/models/team-details.model';
import { UserInitStatus } from './user-init-status';
import { UserPosition } from './user-position';
import { UserPublicModel } from './user-public.model';
import { UserType } from './user-type';
import { IHasId } from '../../tools/reducer-helper/model/i-has-id';
import { Identifier } from '../../tools/reducer-helper/model/identifier';

const i = 0;

export class UserDetailsModel implements IHasId, UserPublicModel {
  @Expose()
  id: Identifier;
  @Expose()
  photo: string;
  @Expose()
  lastName?: string;
  @Expose()
  firstName?: string;
  @Expose()
  @Transform(
    ({ value, obj }) => value ?? [obj.firstName, obj.lastName].join(' ')
  )
  name: string;
  @Expose()
  email: string;

  @Expose()
  contentCategoryIds: number[];

  @Expose()
  genreIds: number[];
  @Expose()
  subGenreIds: number[];
  @Expose()
  moodIds: number[];
  @Expose()
  languageIds: number[];
  @Expose()
  @Type(() => SocialModel)
  socials: SocialModel[];
  @Expose()
  // @Transform(() => UserType.Artist) // for tesing
  type: UserType;
  @Expose()
  @Transform(({ value, obj }) =>
    obj.type !== UserType.Manager && value === UserInitStatus.WaitingForCompany
      ? UserInitStatus.Completed
      : value
  )
  // @Transform(() => UserInitStatus.WaitingForSocials) // for tesing
  initStatus: UserInitStatus;
  @Expose()
  active: boolean;
  @Expose()
  disabled: boolean;

  @Expose()
  @Transform((opts) => (opts.value as boolean) ?? false)
  fromdb: boolean;

  @Expose()
  @Transform(({ value }) => BigInt((value || '0') + ''), { toClassOnly: true })
  @Transform((v) => v.value + '', { toPlainOnly: true })
  roles: bigint;

  @Expose()
  @Type(() => TeamDetailsModel)
  teams: TeamDetailsModel[];

  @Expose()
  teamId?: Identifier;

  @Expose()
  enabledUserTypes: UserType[];

  @Expose()
  position?: UserPosition;

  // @Expose()
  // @Type(() => UserWalletModel)
  // wallet: UserWalletModel;
}
