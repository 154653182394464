import { Injectable, inject } from '@angular/core';

import { map } from 'rxjs/internal/operators/map';

import { createEffect, ofType } from '@ngrx/effects';

import { hasCampaignV2InStore } from '../../../campaign-v2/guards/campaign-v2-exits.guard';
import { CampaignV2SearchModel } from '../../../campaign-v2/models/campaign-v2-search.model';
import { campaignV2Action } from '../../../campaign-v2/store/action';
import { SessionActions } from '../../../session/store/actions/session.actions';
import { hasSongInStore } from '../../../song/guards/song-exits.guard';
import { SongSearchModel } from '../../../song/models/song-search.model';
import { songAction } from '../../../song/store/action';
import { TicketSearchModel } from '../../../ticket/models/ticket-search.model';
import { hasTicketInStore } from '../../../ticket/services/guards/ticket-exits.guard';
import { ticketAction } from '../../../ticket/store/action';
import { PagerEffects } from '../../../tools/reducer-helper/pager-effects';
import { hasUserInStore } from '../../../user/guards/user-exist.guard';
import { UserSearchModel } from '../../../user/models/user-search.model';
import { userAction } from '../../../user/store/action';
import { DealCreateModel } from '../../models/deal-create.model';
import { DealDetailsModel } from '../../models/deal-details.model';
import { DealEditModel } from '../../models/deal-edit.model';
import { DealSearchModel } from '../../models/deal-search.model';
import { DealServiceAbstract } from '../../services/deal.service.abstract';
import { dealAction } from '../action/deal.actions';
import { dealSelector } from '../selector/deal.selector';

@Injectable()
export class DealEffects extends PagerEffects<
  'Deal',
  DealDetailsModel,
  DealSearchModel,
  DealEditModel,
  DealCreateModel
> {
  resetLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.logoutCompleted, SessionActions.selectUserSuccess),
      map(() =>
        dealAction.changeAllPager({
          matcher: () => true,
          payload: { maxCount: null },
        })
      )
    )
  );

  resolveSong$ = createEffect(
    () =>
      this.resolveEntities(['songId'], songAction, SongSearchModel, (id) =>
        hasSongInStore(id, this.store).pipe(map((exist) => ({ exist, id })))
      ),
    { dispatch: false }
  );

  resolveCampaign$ = createEffect(
    () =>
      this.resolveEntities(
        ['campaignId'],
        campaignV2Action,
        CampaignV2SearchModel,
        (id) =>
          hasCampaignV2InStore(id, this.store).pipe(
            map((exist) => ({ exist, id }))
          )
      ),
    { dispatch: false }
  );

  resolveTicket$ = createEffect(
    () =>
      this.resolveEntities(
        ['ticketId'],
        ticketAction,
        TicketSearchModel,
        (id) =>
          hasTicketInStore(id, this.store).pipe(map((exist) => ({ exist, id })))
      ),
    { dispatch: false }
  );

  resolveCreator$ = createEffect(
    () =>
      this.resolveEntities(
        ['creatorId', 'historyUserIds', 'artistId'],
        userAction,
        UserSearchModel,
        (id) =>
          hasUserInStore(id, this.store).pipe(map((exist) => ({ exist, id })))
      ),
    { dispatch: false }
  );

  constructor() {
    super(dealAction, dealSelector, inject(DealServiceAbstract));
  }
}
