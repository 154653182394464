import { Expose } from 'class-transformer';

import { DealStatus } from './deal-status';
import { DealStatusGroup } from './deal-status-group';

export class DealSearchModel {
  @Expose()
  search?: string;

  @Expose()
  status?: DealStatus | DealStatus[];

  @Expose()
  campaignId?: number;

  @Expose()
  statusGroup?: DealStatusGroup | '';

  constructor(ent: Partial<DealSearchModel>) {
    Object.assign(this, ent);
  }
}
