import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { Observable, combineLatest, map, switchMap, take } from 'rxjs';

import { Store } from '@ngrx/store';

import { SessionSelector } from '@songpush/core/session/store/selectors';
import { ENVIRONMENT } from '@songpush/core/common/tokens';



@Injectable()
export class TokenHttpInterceptorService implements HttpInterceptor {
  store = inject(Store);
  environment = inject(ENVIRONMENT);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = req.url;

    if (this.isSpUrl(url)) {
      const oldHeaders = req.headers ?? new HttpHeaders();
      let params = req.params || new HttpParams();
      const isManagerResource = params.has('managerResource');

      const token$ = combineLatest([
        this.store.select(SessionSelector.selectManagerToken),
        this.store.select(SessionSelector.selectToken),
      ]).pipe(map(([managerToken, token]) => (isManagerResource ? managerToken || token : token || managerToken)));

      if (isManagerResource) {
        params = params.delete('managerResource');
      }

      return token$.pipe(
        take(1),
        map((token) => {
          if (token && !oldHeaders.has('token')) {
            return oldHeaders.append('token', token);
          }
          return oldHeaders;
        }),
        switchMap((headers) => next.handle(req.clone({ headers, params })))
      );
    }
    return next.handle(req);
  }

  isSpUrl(url: string) {
    return (
      url.startsWith(this.environment.apiUrl) ||
      url.startsWith(this.environment.downloadUrl) ||
      url.startsWith(this.environment.uploadUrl) ||
      url.startsWith(this.environment.mediaUrl) ||
      url.startsWith(this.environment.shortlinkUrl)
    );
  }
}
