import { Observable } from 'rxjs';

import { ResponseContainer } from '../../common/models/ResponseContainer';
import { Identifier } from '../../tools/reducer-helper/model/identifier';
import { PagerServiceAbstract } from '../../tools/reducer-helper/pager-service';
import { DealCreateModel } from '../models/deal-create.model';
import { DealDetailsModel } from '../models/deal-details.model';
import { DealEditModel } from '../models/deal-edit.model';
import { DealSearchModel } from '../models/deal-search.model';

export abstract class DealServiceAbstract extends PagerServiceAbstract<
  DealDetailsModel,
  DealSearchModel,
  DealCreateModel,
  DealEditModel
> {
  detailsModel = DealDetailsModel;
  filterModel = DealSearchModel;
  createModel = DealCreateModel;
  editModel = DealEditModel;
  abstract delete(
    id: Identifier
  ): Observable<ResponseContainer<DealDetailsModel>>;
}
