import { Expose } from 'class-transformer';

import { IHasId } from '../../tools/reducer-helper/model/i-has-id';
import { Identifier } from '../../tools/reducer-helper/model/identifier';
import { BankAccountMandateModel } from './bank-account-mandate.model';

export class BankAccountDetailsModel implements IHasId {
  @Expose()
  id: Identifier;
  @Expose()
  iban: string;
  @Expose()
  bic: string;
  @Expose()
  countryId: number;
  @Expose()
  zip: string;
  @Expose()
  city: string;
  @Expose()
  address: string;
  @Expose()
  name?: string;

  @Expose()
  mandate: BankAccountMandateModel;
}
