import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';

export const sessionManagerUserSelectedResolver: ResolveFn<number | null> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  try {
    const userIdRegexp = new RegExp(/^\/(\d+)\//);
    const userId = parseInt(userIdRegexp.exec(state.url)[1], 10);
    return userId;
  } catch {
    return null;
  }
};
