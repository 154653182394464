import { PagerEntity } from '../../common/models/PagerEntity';
import { DealStatus } from '../../deal/models/deal-status';
import { createPagerReducer } from '../../tools/reducer-helper/create-pager-reducer.factory';
import { IHasPagerEntities } from '../../tools/reducer-helper/model/has-pager-entities';
import { ManagerDealDetailsModel } from '../models/manager-deal-details.model';
import { ManagerDealSearchModel } from '../models/manager-deal-search.model';
import { managerDealAction } from './manager-deal.action';

export type ManagerDealState = IHasPagerEntities<
  ManagerDealDetailsModel,
  ManagerDealSearchModel
>;
const initialPager = new PagerEntity<
  ManagerDealDetailsModel,
  ManagerDealSearchModel
>({
  id: 'default',
  pages: {},
  selectedPageNum: 1,
  maxCount: null,
  limit: 50,
  asc: false,
  orderBy: 'id',
  search: new ManagerDealSearchModel({ status: DealStatus.created }),
});

const managerDealInitialState: ManagerDealState = {
  entities: {},
  ids: [],
  initialPager: initialPager,
  pagers: {},
  pagerIds: [],
  selectedId: null,
};

export const managerDealReducer = createPagerReducer({
  details: ManagerDealDetailsModel,
  filter: ManagerDealSearchModel,
  initialState: managerDealInitialState,
  actions: managerDealAction,
  source: 'ManagerDeal',
});
