import { Expose, Transform, Type } from 'class-transformer';

import { TransactionType } from './transaction-type';
import { TransactionStatus } from './transaction-status';
import { TransactionNature } from './transaction-nature';
import { Identifier } from '../../tools/reducer-helper/model/identifier';
import { CurrencyV2Model } from '../../common/models/currency-v2.model';
import { InvoiceType } from '../../invoice/models';
import { TransactionSubjectModel } from './transaction-subject.model';

export class TransactionDetailsModel {
  @Expose()
  @Transform(({ obj }) => `${obj?.id}-${obj?.type}`, { toClassOnly: true })
  id: Identifier;
  @Expose()
  type: TransactionType;
  @Expose()
  status: TransactionStatus;
  @Expose()
  nature: TransactionNature;
  @Expose()
  refunded: boolean;
  @Expose()
  partialRefunded: boolean;
  @Expose()
  @Type(() => CurrencyV2Model)
  amount: CurrencyV2Model;
  @Expose()
  campaign?: TransactionSubjectModel;
  @Expose()
  deal?: TransactionSubjectModel;
  @Expose()
  inserted: string;
  @Expose()
  referenceTransaction: Identifier;
  @Expose()
  resultCode: string;
  @Expose()
  resultMessage: string;
  @Expose()
  walletId: Identifier;
  @Expose()
  invoiceId?: number;
  @Expose()
  invoiceType?: InvoiceType;
}
