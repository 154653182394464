import { Expose, Transform } from 'class-transformer';

export class InvoiceSearchModel {
  @Expose()
  search: string;

  @Expose()
  @Transform(({ value }) => (value ? parseInt(value, 10) : null))
  dealId?: number | '';

  @Expose()
  @Transform(({ value }) => (value ? parseInt(value, 10) : null))
  invoiceId?: number | '';

  constructor(ent: Partial<InvoiceSearchModel>) {
    Object.assign(this, ent);
  }
}
