import { HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { Observable } from 'rxjs';

import { getTiktokSoundUrlRegex } from '@songpush/validators/tools/tiktok-sound-url-regex';

import { ResponseContainer } from '../../common/models/ResponseContainer';
import { API_URL } from '../../common/tokens/api-url';
import { toResponse } from '../../tools/map';
import { Mappings } from '../../tools/mapping/mappings';
import { SongDetailsModel } from '../models/song-details.model';
import { SongServiceAbstract } from './song.service.abstract';

const getSongIdType = (id: number | string) => {
  const tiktokRegex = getTiktokSoundUrlRegex();
  if (`${id}`.match(tiktokRegex)) {
    return 'tiktok';
  } else if (isNaN(id as number)) {
    return 'spotify';
  }
  return 'internal';
};

@Injectable()
export class SongService extends SongServiceAbstract {
  public readonly url = `${inject(API_URL)}song`;

  private readonly songBySpotify = `${this.url}/spotify`;
  private readonly songByTiktokUrl = `${this.url}/tiktok`;

  constructor() {
    super();
  }

  getSongRequestById(id: number | string) {
    switch (getSongIdType(id)) {
      case 'internal':
        return this.http.get(`${this.getSingleUrl}/${id}`);
      case 'spotify':
        return this.http.get(`${this.songBySpotify}/${id}`);
      case 'tiktok':
        return this.http.get(`${this.songByTiktokUrl}`, {
          params: new HttpParams({
            fromObject: { url: Mappings.getBaseUrl(id as string) },
          }),
        });
      default:
        throw 'Song id is not supported';
    }
  }

  get(id: number | string): Observable<ResponseContainer<SongDetailsModel>> {
    return this.getSongRequestById(id).pipe(toResponse(this.detailsModel));
  }
}
