import { Injectable, inject } from '@angular/core';


import { SystemMessageEditModel } from '../models/system-message-edit.model';
import { SystemMessageServiceAbstract } from './system-message.service.abstract';
import { API_URL } from '../../common/tokens/api-url';
import { SystemMessageDetailsModel } from '../models/system-message-details.model';
import { SystemMessageSearchModel } from '../models/system-message-search.model';
import { SystemMessageCreateModel } from '../models/system-message-create.model';

@Injectable()
export class SystemMessageService extends SystemMessageServiceAbstract {
  url = `${inject(API_URL)}system-message`;

  detailsModel = SystemMessageDetailsModel;
  filterModel = SystemMessageSearchModel;
  createModel = SystemMessageCreateModel;
  editModel = SystemMessageEditModel;
}
