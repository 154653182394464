import { Observable } from 'rxjs';

import { ResponseContainer } from '../../common/models/ResponseContainer';
import { PagerServiceAbstract } from '../../tools/reducer-helper/pager-service';
import { SpotifyArtistSetResponseModel } from '../models/spotift-artist-set-response.model';
import { SpotifyArtistDetailsModel } from '../models/spotify-artist-details.model';
import { SpotifyArtistSearchModel } from '../models/spotify-artist-search.model';
import { SpotifyArtistSetModel } from '../models/spotify-artist-set.model';

export abstract class SpotifyArtistServiceAbstract extends PagerServiceAbstract<
  SpotifyArtistDetailsModel,
  SpotifyArtistSearchModel,
  null,
  null
> {
  detailsModel = SpotifyArtistDetailsModel;
  filterModel = SpotifyArtistSearchModel;
  createModel = null;
  editModel = null;

  abstract set(
    model: SpotifyArtistSetModel
  ): Observable<ResponseContainer<SpotifyArtistSetResponseModel>>;

  create(): Observable<ResponseContainer<SpotifyArtistDetailsModel>> {
    throw 'not supported';
  }
  edit(): Observable<ResponseContainer<SpotifyArtistDetailsModel>> {
    throw 'not supported';
  }
}
