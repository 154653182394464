import { makeEnvironmentProviders } from '@angular/core';

import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { InvoiceService } from '../services/invoice.service';
import { InvoiceServiceAbstract } from '../services/invoice.service.abstract';
import { InvoiceEffects } from '../store/effect/invoice.effects';
import { invoiceReducer } from '../store/reducer/invoice.reducer';

export const provideInvoiceState = () =>
  makeEnvironmentProviders([
    provideState('invoice', invoiceReducer),
    provideEffects([InvoiceEffects]),
    { provide: InvoiceServiceAbstract, useClass: InvoiceService },
  ]);
