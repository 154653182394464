import { createPagerActionGroup } from '../../tools/reducer-helper/create-pager-action-group.factory';
import { SettingsNotificationGroupDetailsModel } from '../models/settings-notification-group-details.model';
import { SettingsNotificationGroupSearchModel } from '../models/settings-notification-group-search.model';


export const settingsNotificationGroupAction = createPagerActionGroup<
  'SettingsNotificationGroup',
  SettingsNotificationGroupDetailsModel,
  SettingsNotificationGroupSearchModel
>({
  source: 'SettingsNotificationGroup',
});
