import { createPagerSelectors } from '../../../tools/reducer-helper/create-pager-selector.factory';
import { SpotifyArtistDetailsModel } from '../../models/spotify-artist-details.model';
import { SpotifyArtistSearchModel } from '../../models/spotify-artist-search.model';
import { SpotifyArtistState } from '../reducer/spotify-artist.reducer';

export const spotifyArtistSelector = createPagerSelectors<
  SpotifyArtistDetailsModel,
  SpotifyArtistSearchModel,
  SpotifyArtistState
>('spotifyArtist');
