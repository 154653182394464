import { Injectable, inject } from '@angular/core';

import { Observable } from 'rxjs';

import { instanceToPlain } from 'class-transformer';

import { ResponseContainer } from '../../common/models/ResponseContainer';
import { API_URL } from '../../common/tokens/api-url';
import { toResponse } from '../../tools/map/to-response';
import { SocialBlockAddMany } from '../models/social-block-add-many.model';
import { SocialBlockDetailsModel } from '../models/social-block-details.model';
import { SocialBlockServiceAbstract } from './social-block.service.abstract';

@Injectable()
export class SocialBlockService extends SocialBlockServiceAbstract {
  private readonly socialBlockAddManyUrl = `${inject(
    API_URL
  )}user/blacklist/all`;
  url = `${inject(API_URL)}user/blacklist`;

  protected isManagerResource = true;

  constructor() {
    super();
  }

  addMany(
    model: SocialBlockAddMany
  ): Observable<ResponseContainer<SocialBlockDetailsModel>> {
    const params = this.getParams();
    const domain = instanceToPlain(model);

    return this.http
      .post(this.socialBlockAddManyUrl, domain, { params })
      .pipe(toResponse(SocialBlockDetailsModel));
  }
}
