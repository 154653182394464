import { Injectable, inject } from '@angular/core';

import { API_URL } from '../../common/tokens/api-url';
import { CityServiceAbstract } from './city.service.abstract';

@Injectable()
export class CityService extends CityServiceAbstract {
  url = `${inject(API_URL)}city`;

  constructor() {
    super();
  }
}
