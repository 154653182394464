import { Expose } from 'class-transformer';

import { Identifier } from '../../tools/reducer-helper/model/identifier';



export class IdNameImgModel {
  @Expose()
  id: Identifier;
  @Expose()
  name: string;
  @Expose()
  img?: string;
}
