import { Injectable } from '@angular/core';
import { LiveChangeEffects } from '../../../tools/reducer-helper/live-change.effects';
import { WebsocketTarget } from '../../../websocket/models';
import { TicketDetailsModel } from '../../models/ticket-details.model';
import { TicketSearchModel } from '../../models/ticket-search.model';
import { ticketAction } from '../action';

@Injectable()
export class TicketLiveChangeEffects extends LiveChangeEffects<
  'Ticket',
  TicketDetailsModel,
  TicketSearchModel
> {
  protected actions = ticketAction;
  protected websocketTarget = WebsocketTarget.Ticket;
}
