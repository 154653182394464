import { PagerEntity } from '../../../common/models/PagerEntity';
import { Mappings } from '../../mapping/mappings';
import { IHasPagerEntities } from '../model/has-pager-entities';
import { IHasId } from '../model/i-has-id';

export const patchPager = <
  T extends IHasId,
  Z,
  U extends IHasPagerEntities<T, Z>
>(
  state: U,
  patch: Partial<PagerEntity<T, Z>>,
  initialPager: PagerEntity<T, Z>,
  id = 'default'
): U => {
  return Mappings.assign(state, {
    pagers: Mappings.assign(state.pagers, {
      [id]: Mappings.assign(state.pagers[id] || initialPager, { ...patch, id }),
    }),
  } as Partial<U>);
};
