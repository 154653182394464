import { Expose } from 'class-transformer';

import { Identifier } from '../../tools/reducer-helper/model/identifier';

export class ManagerInvitationSearchModel {
  @Expose({ name: '|' })
  teamIds: Identifier[];

  @Expose()
  search?: string;
  constructor(ent: Partial<ManagerInvitationSearchModel>) {
    Object.assign(this, ent);
  }
}
