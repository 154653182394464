import { Injectable, inject } from '@angular/core';

import { delayWhen, filter, switchMap, take, tap } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';

import { Storage } from '@ionic/storage';
import { createEffect, ofType } from '@ngrx/effects';
import { instanceToPlain, plainToInstance } from 'class-transformer';

import { PagerEntity } from '../../../common/models/PagerEntity';
import { SettingsActions } from '../../../settings/store/actions';
import { SettingsSelector } from '../../../settings/store/selectors';
import { storageAction } from '../../../storage/state';
import { filterEmpty } from '../../../tools/map/filter-empty';
import { PagerEffects } from '../../../tools/reducer-helper/pager-effects';
import { GenreModel } from '../../models/genre.model';
import { GenreServiceAbstract } from '../../services/genre.service.abstract';
import { genreAction } from '../action/genre.actions';
import { genreSelector } from '../selector/genre.selector';

@Injectable()
export class GenreEffects extends PagerEffects<
  'Genre',
  GenreModel,
  null,
  null,
  null
> {
  private readonly GENRE_KEY = 'genres';
  storage = inject(Storage);

  loadFromDb$ = createEffect(() =>
    this.actions$.pipe(
      ofType(storageAction.ready),
      switchMap(() => this.storage.get(this.GENRE_KEY)),
      filterEmpty(),
      map((plainState) =>
        plainState ? plainToInstance(GenreModel, plainState as any[]) : null
      ),
      map((genres) =>
        genres
          ? genreAction.loadAllCompleted({
              payload: genres,
              pagerId: 'default',
              pager: new PagerEntity({ maxCount: genres.length }),
            })
          : genreAction.loadAll({ pagerId: 'default' })
      )
    )
  );

  languageChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.languageChanged),
      delayWhen(() =>
        this.store.select(SettingsSelector.getLoaded).pipe(
          filter((loaded) => !!loaded),
          take(1)
        )
      ),
      map(() =>
        genreAction.loadAll({
          pagerId: 'default',
        })
      )
    )
  );

  saveToDb$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(genreAction.loadAllCompleted),
        tap(({ payload }) =>
          this.storage.set(this.GENRE_KEY, instanceToPlain(payload))
        )
      ),
    { dispatch: false }
  );

  constructor() {
    super(genreAction, genreSelector, inject(GenreServiceAbstract));
  }
}
