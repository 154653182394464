import { Expose } from 'class-transformer';

import { DealStatus } from './deal-status';

export class DealHistoryModel {
  @Expose()
  status: DealStatus;

  @Expose()
  inserted: string;

  @Expose()
  userId?: number;
}
