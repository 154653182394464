import { Expose } from 'class-transformer';

import { Identifier } from '../../tools/reducer-helper/model/identifier';

export class TransactionSubjectModel {
  @Expose()
  id: Identifier;
  @Expose()
  userId: Identifier;
  @Expose()
  userName: string;
  @Expose()
  managerId: Identifier;
  @Expose()
  managerName: string;
}
