import { Injectable, inject } from '@angular/core';


import { WalletEditModel } from '../models/wallet-edit.model';
import { WalletServiceAbstract } from './wallet.service.abstract';
import { API_URL } from '../../common/tokens/api-url';
import { WalletDetailsModel } from '../models/wallet-details.model';
import { WalletSearchModel } from '../models/wallet-search.model';
import { WalletCreateModel } from '../models/wallet-create.model';

@Injectable()
export class WalletService extends WalletServiceAbstract {
  url = `${inject(API_URL)}wallets`;

  detailsModel = WalletDetailsModel;
  filterModel = WalletSearchModel;
  createModel = WalletCreateModel;
  editModel = WalletEditModel;
  protected isManagerResource = true;
}
