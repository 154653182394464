import { createPagerActionGroup } from '../../../tools/reducer-helper/create-pager-action-group.factory';
import { CitySearchModel } from '../../models/city-search.model';
import { CityModel } from '../../models/city.model';

export const cityAction = createPagerActionGroup<
  'City',
  CityModel,
  CitySearchModel
>({
  source: 'City',
});
