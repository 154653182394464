import { Injectable, inject } from '@angular/core';

import { delayWhen, filter, switchMap, take, tap } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';

import { Storage } from '@ionic/storage';
import { createEffect, ofType } from '@ngrx/effects';
import { instanceToPlain, plainToInstance } from 'class-transformer';

import { PagerEntity } from '../../../common/models/PagerEntity';
import { SettingsActions } from '../../../settings/store/actions';
import { SettingsSelector } from '../../../settings/store/selectors';
import { storageAction } from '../../../storage/state';
import { filterEmpty } from '../../../tools/map/filter-empty';
import { PagerEffects } from '../../../tools/reducer-helper/pager-effects';
import { ContentCategoryModel } from '../../models/content-category.model';
import { ContentCategoryServiceAbstract } from '../../services/content-category.service.abstract';
import { contentCategoryAction } from '../action';
import { contentCategorySelector } from '../selector';

@Injectable()
export class ContentCategoryEffects extends PagerEffects<
  'ContentCategory',
  ContentCategoryModel,
  null,
  null,
  null
> {
  private readonly SUBGENRE_KEY = 'content-categorys';
  storage = inject(Storage);

  loadFromDb$ = createEffect(() =>
    this.actions$.pipe(
      ofType(storageAction.ready),
      switchMap(() => this.storage.get(this.SUBGENRE_KEY)),
      filterEmpty(),
      map((plainState) =>
        plainState ? plainToInstance(ContentCategoryModel, plainState as any[]) : null
      ),
      map((contentCategorys) =>
        contentCategorys
          ? contentCategoryAction.loadAllCompleted({
              payload: contentCategorys,
              pagerId: 'default',
              pager: new PagerEntity({ maxCount: contentCategorys.length }),
            })
          : contentCategoryAction.loadAll({ pagerId: 'default' })
      )
    )
  );

  languageChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.languageChanged),
      delayWhen(() =>
        this.store.select(SettingsSelector.getLoaded).pipe(
          filter((loaded) => !!loaded),
          take(1)
        )
      ),
      map(() =>
        contentCategoryAction.loadAll({
          pagerId: 'default',
        })
      )
    )
  );

  saveToDb$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(contentCategoryAction.loadAllCompleted),
        tap(({ payload }) =>
          this.storage.set(this.SUBGENRE_KEY, instanceToPlain(payload))
        )
      ),
    { dispatch: false }
  );

  constructor() {
    super(contentCategoryAction, contentCategorySelector, inject(ContentCategoryServiceAbstract));
  }
}
