import { Observable } from 'rxjs';

import { ResponseContainer } from '../../common/models/ResponseContainer';
import { PagerServiceAbstract } from '../../tools/reducer-helper/pager-service';
import { SocialBlockAddMany } from '../models/social-block-add-many.model';
import { SocialBlockCreateModel } from '../models/social-block-create.model';
import { SocialBlockDetailsModel } from '../models/social-block-details.model';
import { SocialBlockEditModel } from '../models/social-block-edit.model';
import { SocialBlockSearchModel } from '../models/social-block-search.model';

export abstract class SocialBlockServiceAbstract extends PagerServiceAbstract<
  SocialBlockDetailsModel,
  SocialBlockSearchModel,
  SocialBlockCreateModel,
  SocialBlockEditModel
> {
  detailsModel = SocialBlockDetailsModel;
  filterModel = SocialBlockSearchModel;
  createModel = SocialBlockCreateModel;
  editModel = SocialBlockEditModel;

  abstract addMany(
    model: SocialBlockAddMany
  ): Observable<ResponseContainer<SocialBlockDetailsModel>>;
}
