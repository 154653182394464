import { PagerServiceAbstract } from '../../tools/reducer-helper/pager-service';
import { SystemMessageCreateModel } from '../models/system-message-create.model';
import { SystemMessageDetailsModel } from '../models/system-message-details.model';
import { SystemMessageEditModel } from '../models/system-message-edit.model';
import { SystemMessageSearchModel } from '../models/system-message-search.model';

export abstract class SystemMessageServiceAbstract extends PagerServiceAbstract<
  SystemMessageDetailsModel,
  SystemMessageSearchModel,
  SystemMessageCreateModel,
  SystemMessageEditModel
> {}
