import { Directive, inject } from '@angular/core';

import { skip, takeUntil, tap } from 'rxjs/operators';

import { IonRouterOutlet } from '@ionic/angular/standalone';

import { LifecycleHookService } from '@songpush/core/common/services/lifecycle-hook';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'ion-router-outlet',
  providers: [IonRouterOutlet],
  standalone: true,
})
export class IonRouterOutletNotifierDirective {
  private readonly outlet = inject(IonRouterOutlet);
  private hooks = inject(LifecycleHookService, { optional: true });
  get lastChild() {
    return this.outlet.nativeEl.lastChild;
  }

  constructor() {
    this.hooks?.willEnter$
      .pipe(
        skip(1), // initial enter always happends
        tap(() => this.lastChild.dispatchEvent(new CustomEvent('ionViewWillEnter'))),
        takeUntil(this.hooks.destroy$)
      )
      .subscribe();
    this.hooks?.willLeave$
      .pipe(
        tap(() => this.lastChild.dispatchEvent(new CustomEvent('ionViewWillLeave'))),
        takeUntil(this.hooks.destroy$)
      )
      .subscribe();
  }
}
