import { Observable } from 'rxjs';

import { ResponseContainer } from '../../common/models/ResponseContainer';
import { TiktokSongMetadataModel } from '../models/tiktok-song-metadata.model';
import { TiktokVideoMetadataModel } from '../models/tiktok-video-metadata.model';

export abstract class TiktokServiceAbstract {
  abstract getSongMetadata(videoUrl: string): Observable<ResponseContainer<TiktokSongMetadataModel>>;
  abstract getVideoMetadata(videoUrl: string): Promise<TiktokVideoMetadataModel>;
  abstract getRealLink(url: string): Promise<ResponseContainer<{ url: string }>>;
}
