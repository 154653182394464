import { PagerEntity } from '../../../common/models/PagerEntity';
import { createPagerReducer } from '../../../tools/reducer-helper/create-pager-reducer.factory';
import { IHasPagerEntities } from '../../../tools/reducer-helper/model/has-pager-entities';
import { UserDetailsModel } from '../../models/user-details.model';
import { UserSearchModel } from '../../models/user-search.model';
import { userAction } from '../action/user.actions';

export type UserState = IHasPagerEntities<UserDetailsModel, UserSearchModel>;
const initialPager = new PagerEntity<UserDetailsModel, UserSearchModel>({
  id: 'default',
  pages: {},
  selectedPageNum: 1,
  maxCount: null,
  limit: 50,
  asc: false,
  orderBy: 'id',
  search: new UserSearchModel({
    search: '',
  }),
});

const userInitialState: UserState = {
  entities: {},
  ids: [],
  initialPager: initialPager,
  pagers: {},
  pagerIds: [],
  selectedId: null,
};

export const userReducer = createPagerReducer({
  details: UserDetailsModel,
  filter: UserSearchModel,
  initialState: userInitialState,
  actions: userAction,
  source: 'User',
});
