import { StatDetailsModel } from '../models/stat-details.model';
import { StatResolution } from '../models/stat-resolution';
import { StatType } from '../models/stat.type';

const SECOND_IN_MS = 1000;
const MINUTE_IN_MS = 60 * SECOND_IN_MS;
const HOUR_IN_MS = 60 * MINUTE_IN_MS;
const DAY_IN_MS = 24 * HOUR_IN_MS;
const MONTH_IN_MS = 31 * DAY_IN_MS;

const emptyArray = [];
const sumTypes: StatType[] = [
  StatType.CampaignV2DealBilled,
  StatType.CampaignV2DealNotReported,
  StatType.CampaignV2ProofUrls,
];

export const fulfillSource = (
  resolution: StatResolution,
  stats: StatDetailsModel[],
  endDate: Date,
  maxCount: number,
  type: StatType
) => {
  const requiredDistance = getDistanceByResolution(resolution);
  const sum = sumTypes.includes(type);
  if (stats.length === 0 || !endDate || !maxCount) {
    return emptyArray;
  }

  if (!requiredDistance && !sum) {
    return stats;
  }

  stats = [
    ...stats,
    new StatDetailsModel({
      id: 'last',
      name: endDate,
      value: sum ? 0 : stats[stats.length - 1].value,
    }),
  ];

  let newList: StatDetailsModel[] = [stats[0]];
  for (let i = 1; i < stats.length; i++) {
    const currentDistance = +stats[i].name - +stats[i - 1].name;

    if (requiredDistance && currentDistance > requiredDistance) {
      newList = [
        ...newList,
        ...generateItems(
          Math.ceil(currentDistance / requiredDistance - 1),
          requiredDistance,
          +newList[newList.length - 1].name,
          newList[newList.length - 1].value,
          type,
          resolution
        ),
      ];
    }

    newList = [
      ...newList,
      sum
        ? new StatDetailsModel({
            ...stats[i],
            value: newList[newList.length - 1].value + stats[i].value,
          })
        : stats[i],
    ];
  }

  return newList.slice(resolution === 'no-group' ? 0 : 1, -1);
};

const generateItems = (
  count: number,
  distance: number,
  date: number,
  value: number,
  type: StatType,
  resolution: StatResolution
) =>
  new Array(count).fill(null).map((v, i) => {
    const name = new Date(date + (i + 1) * distance);
    return new StatDetailsModel({
      id: `${name}-${resolution}-${type}`,
      name: new Date(date + (i + 1) * distance),
      value,
    });
  });

const getDistanceByResolution = (resolution: StatResolution) => {
  switch (resolution) {
    case StatResolution.noGroup:
      return null;
    case StatResolution.hour:
      return HOUR_IN_MS;
    case StatResolution.day:
      return DAY_IN_MS;
    case StatResolution.month:
      return MONTH_IN_MS;
  }
};
