import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable, map } from 'rxjs';

import { instanceToPlain, plainToClassFromExist } from 'class-transformer';


import { InstagramUserModel } from '../model/instagram-user.model';
import { InstagramUserSearchModel } from '../model/instagram-user-search.model';
import { API_URL } from '../../common/tokens/api-url';
import { ENVIRONMENT } from '../../common/tokens/environment';
import { toPagerResponse } from '../../tools/map/to-response';
import { InstagramSongMetadataModel } from '../model/instagram-song-metadata.model';
import { ResponseContainer } from '../../common/models/ResponseContainer';



@Injectable()
export class InstagramService {
  private readonly http = inject(HttpClient);
  private readonly apiUrl = inject(API_URL);
  private readonly environment = inject(ENVIRONMENT);
  private readonly metadataUrl = this.apiUrl + 'song/instagram';

  getPage(model: InstagramUserSearchModel) {
    const domain = instanceToPlain(model);

    return this.http.post(`${this.apiUrl}add-social/instagram`, domain).pipe(toPagerResponse(InstagramUserModel));
  }

  getSongMetadata(videoUrl: string): Observable<ResponseContainer<InstagramSongMetadataModel>> {
    const params = new HttpParams({ fromObject: { url: videoUrl } });
    return this.http
      .get(this.metadataUrl, { params })
      .pipe(map((resp) => plainToClassFromExist(new ResponseContainer(InstagramSongMetadataModel), resp)));
  }
}
