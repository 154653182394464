import { createPagerSelectors } from '../../tools/reducer-helper/create-pager-selector.factory';
import { SystemMessageDetailsModel } from '../models/system-message-details.model';
import { SystemMessageSearchModel } from '../models/system-message-search.model';
import { SystemMessageState } from './system-message.reducer';

export const systemMessageSelector = createPagerSelectors<
  SystemMessageDetailsModel,
  SystemMessageSearchModel,
  SystemMessageState
>('systemMessage');
