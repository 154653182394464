import { Expose } from 'class-transformer';

export class CampaignV2BriefingModel {
  @Expose()
  description?: string;
  @Expose()
  descriptionHtml?: string;
  @Expose()
  referenceUrl: string[];
  @Expose()
  mandatory: boolean;
}
