import { Expose } from 'class-transformer';

export class ViewsModel {
  @Expose()
  avg: number;
  @Expose()
  min: number;
  @Expose()
  max: number;
  @Expose()
  sum: number;
}
