import { inject } from '@angular/core';

import { map } from 'rxjs';

import { createEffect, ofType } from '@ngrx/effects';

import { SessionActions } from '../../session/store/actions/session.actions';
import { PagerEffects } from '../../tools/reducer-helper/pager-effects';
import { WalletCreateModel } from '../models/wallet-create.model';
import { WalletDetailsModel } from '../models/wallet-details.model';
import { WalletEditModel } from '../models/wallet-edit.model';
import { WalletSearchModel } from '../models/wallet-search.model';
import { WalletServiceAbstract } from '../providers/wallet.service.abstract';
import { walletAction } from './wallet.action';
import { walletSelector } from './wallet.selector';

export class WalletEffects extends PagerEffects<
  'Wallet',
  WalletDetailsModel,
  WalletSearchModel,
  WalletEditModel,
  WalletCreateModel
> {
  resetLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.logoutCompleted),
      map(() => walletAction.resetState())
    )
  );
  // refreshWallet$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(SessionActions.loadFromDbCompleted, SessionActions.login),
  //     switchMap(() => this.store.select(SessionSelector.selectSignedIn).pipe(take(1))),
  //     filter((signedIn) => signedIn),
  //     map(() => walletAction.loadAll({ pagerId: 'default', wipe: true }))
  //   )
  // );

  constructor() {
    super(walletAction, walletSelector, inject(WalletServiceAbstract));
  }
}
