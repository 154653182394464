import { createPagerActionGroup } from '../../../tools/reducer-helper/create-pager-action-group.factory';
import { DealDetailsModel } from '../../models/deal-details.model';
import { DealSearchModel } from '../../models/deal-search.model';

export const dealAction = createPagerActionGroup<
  'Deal',
  DealDetailsModel,
  DealSearchModel
>({
  source: 'Deal',
});
