import { createPagerActionGroup } from '../../../tools/reducer-helper/create-pager-action-group.factory';
import { SocialBlockDetailsModel } from '../../models/social-block-details.model';
import { SocialBlockSearchModel } from '../../models/social-block-search.model';

export const socialBlockAction = createPagerActionGroup<
  'SocialBlock',
  SocialBlockDetailsModel,
  SocialBlockSearchModel
>({
  source: 'SocialBlock',
});
