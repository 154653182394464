import { Expose } from 'class-transformer';

import { UserType } from '../../user/models/user-type';

export class CountrySearchModel {
  @Expose()
  search?: string;

  @Expose()
  userType?: UserType;

  @Expose()
  geoTargeting?: boolean;

  constructor(ent: Partial<CountrySearchModel>) {
    Object.assign(this, ent);
  }
}
