import { makeEnvironmentProviders } from '@angular/core';

import { provideEffects } from '@ngrx/effects';

import { windowManagerService } from '@songpush/core/common/services/window-manger';

import { WindowManagerService } from './service/window-manager.service';
import { DownloadEffects } from './state/download.effects';
import { GoogleAnalyticsEffects } from './state/google-analytics.effects';

export const provideCommonState = () =>
  makeEnvironmentProviders([
    provideEffects([DownloadEffects, GoogleAnalyticsEffects]),
    { provide: windowManagerService, useClass: WindowManagerService },
  ]);
