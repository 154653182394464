export enum WebsocketTarget {
  Ticket = 1,
  Transaction = 2,
  Song = 7,
  Session = 8,
  BankAccount = 14,
  CampaignV2 = 15,
  Deal = 16,
  TicketComment = 17,
  DealLimit = 18,
  Wallet = 19,
  Company = 20,
  Manager = 50,
  ManagedUser = 51,
  Team = 52,
  Invite = 53,
  ManagerDeal = 54,
  SystemMessage = 100,
}
