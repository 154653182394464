import { createPagerSelectors } from '../../../tools/reducer-helper/create-pager-selector.factory';
import { CitySearchModel } from '../../models/city-search.model';
import { CityModel } from '../../models/city.model';
import { CityState } from '../reducer/city.reducer';

export const citySelector = createPagerSelectors<
  CityModel,
  CitySearchModel,
  CityState
>('city');
