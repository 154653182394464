import { Expose, Transform } from 'class-transformer';

import { Mappings } from '../../tools/mapping/mappings';



export class DealEditModel {
  @Expose()
  @Transform(({ value }) => Mappings.getBaseUrl(value))
  proofUrl: string;
}
