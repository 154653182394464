import { Expose, Type } from 'class-transformer';

import { UserDetailsModel } from './user-details.model';

export class UserLoginResponse {
  @Expose()
  @Type(() => UserDetailsModel)
  user: UserDetailsModel;

  @Expose()
  token: string;
}
