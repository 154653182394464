import { makeEnvironmentProviders } from '@angular/core';

import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { CountryService } from '../providers/country.service';
import { CountryServiceAbstract } from '../providers/country.service.abstract';
import { CountryEffects } from '../store/effect/country.effects';
import { countryReducer } from '../store/reducer';

export const provideCountryState = () =>
  makeEnvironmentProviders([
    provideState('country', countryReducer),
    provideEffects([CountryEffects]),
    { provide: CountryServiceAbstract, useClass: CountryService },
  ]);
