import { Expose } from 'class-transformer';

import { Identifier } from '../../tools/reducer-helper/model/identifier';
import { TicketType } from './ticket-type';

export class TicketDealCreateModel {
  @Expose()
  description: string;
  @Expose()
  dealId: Identifier;
  @Expose()
  type: TicketType;
}
