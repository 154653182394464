import { inject } from '@angular/core';

import { take } from 'rxjs';

import { Store } from '@ngrx/store';

import { SessionSelector } from '../store/selectors/session.selector';



export const sessionManagerGuard = () => inject(Store).select(SessionSelector.selectIsManager).pipe(take(1));
