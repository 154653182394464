import { Injectable } from '@angular/core';

import { ToastController } from '@ionic/angular/standalone';
import { TranslateService } from '@ngx-translate/core';

import { FormValidatorServiceAbstract } from '@songpush/core/form/services';

@Injectable()
export class FormValidatorService extends FormValidatorServiceAbstract {
  constructor(private translateService: TranslateService,
    private toastCtrl: ToastController
  ) {
    super();
  }

  async showToast(message: string): Promise<void> {
    const toast = await this.toastCtrl.create({
      message,
      duration: 3000,
    });
    toast.present();
  }

  translate(key: string, opts): string {
    return this.translateService.instant(key, opts);
  }
}
