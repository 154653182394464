import { createPagerActionGroup } from '../../../tools/reducer-helper/create-pager-action-group.factory';
import { ContentCategoryModel } from '../../models/content-category.model';

export const contentCategoryAction = createPagerActionGroup<
  'ContentCategory',
  ContentCategoryModel,
  null
>({
  source: 'ContentCategory',
});
