import { createPagerSelectors } from '../../tools/reducer-helper/create-pager-selector.factory';
import { TeamDetailsModel } from '../models/team-details.model';
import { TeamSearchModel } from '../models/team-search.model';
import { TeamState } from './team.reducer';

export const teamSelector = createPagerSelectors<
  TeamDetailsModel,
  TeamSearchModel,
  TeamState
>('team');
