import { Exclude, Expose, Transform, Type } from 'class-transformer';

import { ResponseStatus } from './ResponseStatus';

export class PagerResponse<T> {
  @Expose()
  @Type((options) => (options.newObject as PagerResponse<T>).type)
  @Transform(({ value }) => value ?? [])
  results: T[];

  @Expose()
  @Transform(({ value }) => value ?? 0)
  maxCount: number;

  @Exclude()
  private type: new (...args: unknown[]) => T;

  constructor(testType: T | (new (...args: unknown[]) => T)) {
    if (testType instanceof Function) {
      this.type = testType;
    }
  }
}
export class PagerResponseContainer<T> {
  @Expose()
  @Type(() => ResponseStatus)
  status: ResponseStatus;

  @Expose()
  @Type(() => PagerResponse)
  @Transform(({ value }) => value ?? { results: [], maxCount: 0 })
  values: PagerResponse<T>;

  @Exclude()
  private type: new (...args: unknown[]) => T;

  constructor(testType: T | (new (...args: unknown[]) => T)) {
    if (testType instanceof Function) {
      this.type = testType;
      this.values = new PagerResponse<T>(testType);
    } else {
      // this.values = testType;
    }
  }
}
