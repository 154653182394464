import { Injectable, inject } from '@angular/core';

import { DealLimitServiceAbstract } from './deal-limit.service.abstract';
import { API_URL } from '../../common/tokens/api-url';


@Injectable()
export class DealLimitService extends DealLimitServiceAbstract {
  url = `${inject(API_URL)}deal/limit`;
  constructor() {
    super();
  }
}
