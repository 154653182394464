import { Injectable, inject } from '@angular/core';

import { Observable, map } from 'rxjs';

import { instanceToPlain, plainToClassFromExist } from 'class-transformer';

import { ManagerInvitationServiceAbstract } from './manager-invitation.service.abstract';
import { API_URL } from '../../common/tokens/api-url';
import { ManagerInvitationEditModel } from '../models/manager-invitation-edit.model';
import { ManagerInvitationDetailsModel } from '../models/manager-invitation-details.model';
import { ManagerInvitationSearchModel } from '../models/manager-invitation-search.model';
import { ManagerInvitationCreateModel } from '../models/manager-invitation-create.model';
import { ResponseContainer } from '../../common/models/ResponseContainer';
import { Identifier } from '../../tools/reducer-helper/model/identifier';

@Injectable()
export class ManagerInvitationService extends ManagerInvitationServiceAbstract {
  url = `${inject(API_URL)}management/manager/invite`;

  detailsModel = ManagerInvitationDetailsModel;
  filterModel = ManagerInvitationSearchModel;
  createModel = ManagerInvitationCreateModel;
  editModel = ManagerInvitationEditModel;

  protected isManagerResource = true;

  resend(id: Identifier): Observable<ResponseContainer<null>> {
    const params = this.getParams();
    return this.http
      .put(`${this.url}/${id}`, {}, { params })
      .pipe(map((resp) => plainToClassFromExist(new ResponseContainer(null), resp)));
  }

  override create(model: ManagerInvitationCreateModel) {
    const params = this.getParams();
    const body = instanceToPlain(model);
    return this.http
      .post(`${this.url}/${model.teamId}`, body, { params })
      .pipe(map((resp) => plainToClassFromExist(new ResponseContainer(null), resp)));
  }
}
