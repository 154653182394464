import { createPagerSelectors } from '../../../tools/reducer-helper/create-pager-selector.factory';
import { SpotifyTrackDetailsModel } from '../../models/spotify-track-details.model';
import { SpotifyTrackSearchModel } from '../../models/spotify-track-search.model';
import { SpotifyTrackState } from '../reducer/spotify-track.reducer';

export const spotifyTrackSelector = createPagerSelectors<
  SpotifyTrackDetailsModel,
  SpotifyTrackSearchModel,
  SpotifyTrackState
>('spotifyTrack');
