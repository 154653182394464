import { Injectable } from '@angular/core';

@Injectable()
export class YoutubeIframeApiService {
  prom: Promise<any>;

  get(): Promise<any> {
    if (!this.prom) {
      this.prom = new Promise((resolve) => {
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        document.body.appendChild(tag);
        (window as any).onYouTubeIframeAPIReady = () => resolve((window as any).YT);
      });
    }
    return this.prom;
  }
}
