import { makeEnvironmentProviders } from '@angular/core';

import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { SessionManagedFacade } from '../facade/session-managed.facade';
import { SessionLiveChangeEffects } from '../store/effects/session-livechange.effects';
import { SessionEffects } from '../store/effects/session.effects';
import { sessionReducer } from '../store/reducers/session.reducer';

export const provideSessionState = () =>
  makeEnvironmentProviders([
    provideState('session', sessionReducer),
    provideEffects([SessionEffects, SessionLiveChangeEffects]),
    SessionManagedFacade,
  ]);
