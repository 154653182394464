import { Expose } from 'class-transformer';

export class SpotifyArtistSearchModel {
  @Expose()
  search: string;

  constructor(init: Partial<SpotifyArtistSearchModel>) {
    Object.assign(this, init);
  }
}
