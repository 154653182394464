import { Exclude, Expose } from 'class-transformer';

import { StatType } from './stat.type';
import { StatResolution } from './stat-resolution';
import { Identifier } from '../../tools/reducer-helper/model/identifier';

// const dealKey = (k: keyof typeof StatType & `Deal${string}`) => StatType[k];
// const campaignKey = (k: keyof typeof StatType & `CampaignV2${string}`) => StatType[k];

export class StatSearchDealModel {
  @Expose()
  search?: string;

  @Exclude()
  type: StatType;

  @Expose({ name: 'group' })
  resolution: StatResolution;

  @Expose({ name: 'dateFrom' })
  fromDate: string;
  @Expose({ name: 'dateTo' })
  toDate: string;

  @Expose()
  dealId: Identifier;

  @Expose()
  fillFirstEntry: boolean;

  constructor(ent: Partial<StatSearchDealModel>) {
    Object.assign(this, ent);
  }
}

export class StatSearchCampaignModel {
  @Expose()
  search?: string;

  @Exclude()
  type: StatType;

  @Expose({ name: 'group' })
  resolution: StatResolution;

  @Expose({ name: 'dateFrom' })
  fromDate: string;
  @Expose({ name: 'dateTo' })
  toDate: string;

  @Expose()
  fillFirstEntry: boolean;

  @Expose()
  campaignId: Identifier;

  constructor(ent: Partial<StatSearchCampaignModel>) {
    Object.assign(this, ent);
  }
}

export class StatSearchModel {
  @Expose()
  search?: string;

  @Exclude()
  type: StatType;

  @Expose({ name: 'group' })
  resolution: StatResolution;

  @Expose({ name: 'dateFrom' })
  fromDate: string;
  @Expose({ name: 'dateTo' })
  toDate: string;

  @Expose()
  campaignId?: Identifier;

  @Expose()
  dealId?: Identifier;

  @Expose()
  fillFirstEntry?: boolean;

  constructor(ent: Partial<StatSearchModel>) {
    Object.assign(this, ent);
  }
}
