import { shareReplay } from 'rxjs';

import { Store } from '@ngrx/store';

import { memoize } from '../memoize/memoize';
import { keepScopeLoaded } from '../operators/keep-scope-loaded';
import { PagerSelector } from '../reducer-helper/create-pager-selector.factory';
import { IHasId } from '../reducer-helper/model/i-has-id';
import { PagerActions } from '../reducer-helper/model/pager-actions';

export const getLoadedSource = memoize(
  <DetailsModel extends IHasId, FilterModel>(
    getStore: () => Store,
    getSelector: () => PagerSelector<DetailsModel, FilterModel>,
    pagerId: string,
    stateKey: string, // for memoization reason
    getActions?: () => PagerActions<any, DetailsModel, FilterModel>,
    loadAll?: boolean
  ) => {
    let source$ = getStore().select(getSelector().selectScope(pagerId));
    if (getActions()) {
      if (loadAll) {
        source$ = source$.pipe(
          keepScopeLoaded<DetailsModel, FilterModel>({
            store: getStore(),
            updateAction: getActions().loadAll({ pagerId, wipe: true }),
          })
        );
      } else {
        source$ = source$.pipe(
          keepScopeLoaded<DetailsModel, FilterModel>({
            store: getStore(),
            updateAction: getActions().loadPage({
              pagerId,
              page: 1,
              wipe: true,
            }),
          })
        );
      }
    }

    return source$.pipe(
      shareReplay({
        refCount: false,
        bufferSize: 1,
      })
    );
  }
);
