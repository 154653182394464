import { Expose } from 'class-transformer';

import { SocialType } from '../../social/models/social-type';

export class DealLimitEditModel {
  @Expose()
  platform: SocialType;

  @Expose()
  limit: number;

  @Expose()
  used: number;
}
