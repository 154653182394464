import { createPagerActionGroup } from '../../../tools/reducer-helper/create-pager-action-group.factory';
import { UserDetailsModel } from '../../models/user-details.model';
import { UserSearchModel } from '../../models/user-search.model';

export const userAction = createPagerActionGroup<
  'User',
  UserDetailsModel,
  UserSearchModel
>({
  source: 'User',
});
