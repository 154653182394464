import { InjectionToken } from '@angular/core';

export interface IWindowManagerService {
  newWindow(url: string, fullScreen?: boolean, name?: string);
  customOAuth(url: string, tokenField: string, replaceHash?: boolean, targetWindow?: Window): Promise<any>;
  sendMessageAftrerReady(wd: Window, data: any, origin: string);
  createBlank(): Window;
}

export const windowManagerService = new InjectionToken<IWindowManagerService>('window manager');
