import { Mappings } from '../../mapping/mappings';
import { IHasPagerEntities } from '../model/has-pager-entities';
import { IHasId } from '../model/i-has-id';
import { ISingleLoadCompletedAction } from '../model/single-load-completed';
import { mergeIds } from './merge-ids';

export const singleLoadCompletedReduce = <T extends IHasId, Z, U extends IHasPagerEntities<T, Z>>(
  state: U,
  action: ISingleLoadCompletedAction<T>
): U =>
  Mappings.assign(state, {
    ids: mergeIds(state.ids, [action.payload.id]),
    entities: Mappings.assign(state.entities, { [action.payload.id]: action.payload }),
  } as Partial<U>);
