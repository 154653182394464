import { Injectable, inject } from '@angular/core';

import { API_URL } from '../../../common/tokens/api-url';
import { TicketServiceAbstract } from './ticket.service.abstract';

@Injectable()
export class TicketService extends TicketServiceAbstract {
  createUrl = `${inject(API_URL)}ticket-v2/deal`;

  url = `${inject(API_URL)}ticket`;

  get getCreateUrl() {
    return this.createUrl;
  }
  constructor() {
    super();
  }
}
