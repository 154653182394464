import { createPagerSelectors } from '../../tools/reducer-helper/create-pager-selector.factory';
import { ManagerDealDetailsModel } from '../models/manager-deal-details.model';
import { ManagerDealSearchModel } from '../models/manager-deal-search.model';
import { ManagerDealState } from './manager-deal.reducer';

export const managerDealSelector = createPagerSelectors<
  ManagerDealDetailsModel,
  ManagerDealSearchModel,
  ManagerDealState
>('managerDeal');
